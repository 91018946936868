import React from 'react';
import { Toaster } from 'react-hot-toast';
import backgroundImage1 from 'assets/background-register-1.jpg';
import { Image, WelcomeContainer } from './styled';

const RegisterLayout = ({ children }) => (
  <WelcomeContainer>
    <div
      style={{
        padding: '32px',
        display: 'grid',
        flexDirection: 'column',
        gap: '10px',
        gridTemplateRows: 'auto auto 1fr'
      }}>
      {children}
    </div>
    <Image src={backgroundImage1} alt='Background' />
    <Toaster
      toastOptions={{
        className: '',
        style: {
          marginTop: '30px'
        },
        error: {
          style: {
            display: 'none'
          }
        },
        success: {
          style: {
            display: 'none'
          }
        },
        loading: {
          style: {
            display: 'none'
          }
        }
      }}
    />
  </WelcomeContainer>
);

export default RegisterLayout;
