/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { MODALS } from 'views/Modals';
import { initialStateUiStore, useUiStore } from './uiStore';

type StoreState = {
  demoMode: boolean;
  maxParts: number;
  telemetryInitiated: boolean;
  updateFilesReady: boolean;
  updateStatus: number;
  setItemSettingsStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) => void;
  resetUpdate: () => void;
};

const initialState = {
  demoMode: true,
  maxParts: 0,
  telemetryInitiated: false,
  updateFilesReady: false,
  updateStatus: 0
};

const store = (set): StoreState => ({
  ...initialState,
  setItemSettingsStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) =>
    set({ [`${property}`]: value }),
  resetUpdate: () => {
    const { closeModal, setItemUiStore } = useUiStore.getState();
    closeModal(MODALS.firmware);
    setItemUiStore('firmwareUpdateState', initialStateUiStore.firmwareUpdateState);
    set({
      updateFilesReady: initialState.updateFilesReady,
      maxParts: initialState.maxParts,
      updateStatus: initialState.updateStatus
    });
  }
});

export const useSettingsStore = create<StoreState>()(
  // @ts-ignore
  devtools(
    persist(store, {
      name: 'settings',
      partialize: (state: any) => ({
        demoMode: state.demoMode
      })
    }),
    { name: 'Settings' }
  )
);
