/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useUiStore } from 'reducers/uiStore';
import ModalBase from './ModalBase';

const AuthorizeServiceModal = ({ handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { push } = useHistory();

  const setItemUiStore = useUiStore((state) => state.setItemUiStore);
  const onSubmit = async () => {
    setItemUiStore('isAuthorized', true);
    handleClose();
    push('/service-menu');
  };

  return (
    <ModalBase header='Authorization' handleClick={handleClose}>
      <DialogContent sx={{ width: '450px' }}>
        <form onSubmit={handleSubmit(() => onSubmit())} style={{ display: 'grid', gap: '16px' }}>
          <TextField
            id='user'
            label='User'
            fullWidth
            required
            {...register('user', {
              required: 'This field is required',
              validate: (val: string) => {
                if (val !== 'clinician') {
                  return 'User or password is incorrect';
                }
              }
            })}
            error={Boolean(errors?.user)}
            helperText={errors?.user?.message}
          />
          <TextField
            id='password'
            label='Password'
            fullWidth
            required
            {...register('password', {
              required: 'This field is required',
              validate: (val: string) => {
                if (val !== 'Zeus2021') {
                  return 'User or password is incorrect';
                }
              }
            })}
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
          />
          <Button fullWidth type='submit'>
            Enter
          </Button>
        </form>
      </DialogContent>
    </ModalBase>
  );
};

export default AuthorizeServiceModal;
