/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Typography, Alert, Button, Link } from '@mui/material';
import RegisterLayout from 'layouts/RegisterLayout/RegisterLayout';
import {
  CHECK_DEVICE_SERIAL_QUERY_KEY,
  useAddDeviceWithCode,
  useDevicesListInfinite
} from 'hooks/api/useDevice';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { LoadingButton } from '@mui/lab';
import { FETCHING_STATES } from 'consts/consts';
import {
  AlertParagraph,
  CodeWrapper,
  ErrorWrapper,
  FooterAction,
  HandImageWrapper,
  LoadingButtonAdpBlue,
  Logo,
  LogoLink,
  StepWrapper
} from 'views/Register/styled';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import BluetoothWebControllerLE from 'bluetooth-handler/bluetoothLE';
import { useUiStore } from 'reducers/uiStore';
import { useHistory } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import AetherLogo from 'assets/aether_new_black.png';
import HandImage from 'assets/precision-open-minimal.png';
import { useConfigStore } from 'reducers/configStore';
import { useAuthStore } from 'reducers/authStore';
import {
  AddDeviceErrorComponent,
  ConnectionErrors,
  ConnectionLoading,
  RegisterFooter
} from 'views/Register/Components/Components';
import { IconBackgroundWrapper } from 'components/atoms/Icons/Icons';
import { ReactComponent as HomeRegister } from 'assets/home-register.svg';
import CustomVerificationCode from 'components/molecules/CustomVerificationCode/CustomVerificationCode';
import { errorApiNotification, isNetworkError } from 'utils/notifications';
import { checkDeviceSerial } from 'api/device/device';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { ADD_DEVICE_ERRORS, MESSAGES } from '../utils';

const bluetoothLE = new BluetoothWebControllerLE();

const canShowActivationCode = (errorAddDevice) => {
  if (!errorAddDevice) return true;
  if (errorApiNotification(errorAddDevice) === ADD_DEVICE_ERRORS.ACTIVATION_CODE_INCORRECT)
    return true;

  return false;
};

const AccessError = ({ startConfiguration, errorAddDevice }) => (
  <ErrorWrapper>
    <Alert severity='error'>
      <h3>
        <b>{`You don't have access to the connected device.`}</b>
      </h3>
      <AlertParagraph>
        Please enter the activation code of the device or contact with us at{' '}
        <Link href='mailto:support@aetherbiomedical.com'>support@aetherbiomedical.com</Link>. If you
        want to connect to other device,{' '}
        <span style={{ color: '#721c24', textDecoration: 'underline' }}>
          <a
            onClick={startConfiguration}
            style={{ color: '#721c24', cursor: 'pointer', textDecoration: 'none' }}>
            <b>click here.</b>
          </a>
        </span>
      </AlertParagraph>
    </Alert>
    {errorAddDevice && <AddDeviceErrorComponent errorAddDevice={errorAddDevice} />}
  </ErrorWrapper>
);

const Code = ({
  handleChangeCode,
  valueCode,
  isActivationCodeWrong,
  verificationCodeValid,
  handleAddDevice,
  loading
}) => (
  <CodeWrapper>
    <Typography>{MESSAGES.PLEASE_ENTER_CODE}</Typography>
    <CustomVerificationCode
      onChange={handleChangeCode}
      value={valueCode}
      error={isActivationCodeWrong}
    />
    <LoadingButton
      disabled={!verificationCodeValid}
      onClick={handleAddDevice}
      loading={loading}
      sx={{ ...LoadingButtonAdpBlue }}>
      <span>Activate device</span>
    </LoadingButton>
  </CodeWrapper>
);

const Welcome = ({
  startConfiguration,
  redirectAdp,
  loading,
  deviceExistsInAdp,
  showAccessError,
  showConnectionError,
  connectionState,
  handleChangeCode,
  valueCode,
  handleAddDevice,
  verificationCodeValid,
  isActivationCodeWrong,
  errorAddDevice,
  checkSerialError
}) => {
  if (showAccessError && deviceExistsInAdp)
    return (
      <div style={{ display: 'grid' }}>
        <Typography variant='h5'>Device</Typography>
        <>
          <StepWrapper>
            <div style={{ textAlign: 'left', display: 'grid' }}>
              <AccessError
                startConfiguration={startConfiguration}
                errorAddDevice={errorAddDevice}
              />
              {canShowActivationCode(errorAddDevice) && (
                <Code
                  handleChangeCode={handleChangeCode}
                  valueCode={valueCode}
                  isActivationCodeWrong={isActivationCodeWrong}
                  verificationCodeValid={verificationCodeValid}
                  handleAddDevice={handleAddDevice}
                  loading={loading}
                />
              )}
            </div>
          </StepWrapper>
        </>
      </div>
    );

  if (connectionState === FETCHING_STATES.loading) {
    return (
      <div style={{ display: 'grid', gap: '16px' }}>
        <Typography variant='h5'>Device</Typography>
        <ConnectionLoading />
      </div>
    );
  }

  return (
    <div style={{ display: 'grid', gap: '16px' }}>
      {loading ? (
        <ConnectionLoading waitText='' showConnectionText={false} />
      ) : (
        <>
          <Typography variant='h5'>Log in successful!</Typography>
          <Typography variant='body2'>Where do you want to go?</Typography>
          <FooterAction>
            <HandImageWrapper src={HandImage} alt='Zeus hand' />
            <>
              <Typography>
                If you want to set the Zeus hand up and make settings changes.
              </Typography>
              <div style={{ textAlign: 'left' }}>
                <ConnectionErrors
                  connectionState={connectionState}
                  deviceExistsInAdp={deviceExistsInAdp}
                  showConnectionError={showConnectionError}
                  checkSerialError={checkSerialError}
                />
              </div>
              <Button onClick={startConfiguration} disabled={loading} className='adpBlue'>
                Start Zeus Configurator
              </Button>
            </>
          </FooterAction>
          <FooterAction>
            <IconBackgroundWrapper icon={<HomeRegister />} primary={false} />
            <Typography>
              If you want to go to ADP. This is where you can access advanced features such as
              remote configuration, device usage monitoring, data analytics and more.
            </Typography>
            <Button onClick={redirectAdp} color='info' className='adpBlue'>
              Go to the ADP
            </Button>
          </FooterAction>
        </>
      )}
    </div>
  );
};

const QuickConfiguration = () => {
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [showAccessError, setShowAccessError] = useState(false);
  const {
    deviceConnected,
    serial,
    connected,
    setItemDeviceInfo,
    deviceCode,
    setDeviceCode,
    setDeviceCodeClaimed
  } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    serial: state.serial,
    connected: state.connected,
    setItemDeviceInfo: state.setItemDeviceInfo,
    getDeviceInfoAPI: state.getDeviceInfoAPI,
    deviceCode: state.deviceCode,
    setDeviceCode: state.setDeviceCode,
    setDeviceCodeClaimed: state.setDeviceCodeClaimed
  }));
  const [loadingConnection, setLoadingConnection] = useState(false);
  const { token } = useAuthStore((state) => ({ token: state.token }));
  const history = useHistory();
  const connectionState = useUiStore((state) => state.connectionState);
  const { bluetoothConnectRegister } = useBluetooth();
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { me } = useUserData();
  const [checkSerialError, setCheckSerialError] = useState(undefined);
  const { result: devicesApi, isLoading: isLoadingDevices } = useDevicesListInfinite(
    { perPage: '200' },
    Boolean(me?.id)
  );
  const {
    mutateAsync: addDeviceWithCode,
    isError: isErrorAddDevice,
    error: errorAddDevice,
    isLoading: isLoadingAddDeviceWithCode
  } = useAddDeviceWithCode();
  const [deviceExists, setDeviceExists] = useState<Boolean | undefined>(undefined);
  const waitForDevices = isLoadingDevices || !me?.id;

  const connectDeviceConfigurator = async (deviceId: number) => {
    setItemDeviceInfo('deviceId', deviceId);
    await getInitialConfigAPI();
    history.push(`/device?deviceId=${deviceId}&connect=1`);
  };
  const queryClient = useQueryClient();

  const startConfiguration = async () => {
    try {
      setLoadingConnection(true);

      setCheckSerialError(undefined);
      if (connected) await disconnectDevice();
      bluetoothLE.forgetDevice();
      setShowConnectionError(false);
      setShowAccessError(false);

      const deviceData = await bluetoothConnectRegister();

      if (!deviceData) {
        setShowConnectionError(true);
        return;
      }

      const serialStatus = await queryClient.fetchQuery([CHECK_DEVICE_SERIAL_QUERY_KEY], () =>
        checkDeviceSerial(deviceData?.serialNumber)
      );

      const deviceExistsInAdp = serialStatus?.status;

      setDeviceExists(deviceExistsInAdp);

      const deviceAccess = devicesApi.find(
        (deviceApi) => deviceApi.serial === deviceData?.serialNumber
      );

      if (deviceAccess) {
        connectDeviceConfigurator(deviceAccess.id);
        return;
      }
      setShowAccessError(true);
    } catch (e: any) {
      if (isNetworkError(e)) {
        setCheckSerialError(e);
        return;
      }
      setShowConnectionError(true);
    } finally {
      setLoadingConnection(false);
    }
  };

  const redirectAdp = () => {
    window.location.href = `${process.env.REACT_APP_ADMIN_PANEL_URL}/dashboard`;
  };

  const handleChangeCode = (e: string) => {
    setDeviceCode(e.toUpperCase());
  };

  const handleAddDevice = async () => {
    if (!deviceCode.code || !serial) return;
    const response = await addDeviceWithCode({ code: deviceCode.code, serial });
    setDeviceCodeClaimed(true);
    setDeviceCode(null);
    setShowAccessError(false);
    connectDeviceConfigurator(response.id);
  };

  const verificationCodeValid = deviceCode.code?.length === 6;

  return (
    <RegisterLayout>
      <Logo>
        <LogoLink href={`${process.env.REACT_APP_ADMIN_PANEL_URL}`}>
          <img src={AetherLogo} alt='Aether logo' />
        </LogoLink>
      </Logo>
      <Welcome
        startConfiguration={startConfiguration}
        loading={
          waitForDevices ||
          connectionState === FETCHING_STATES.loading ||
          isLoadingAddDeviceWithCode ||
          loadingConnection
        }
        redirectAdp={redirectAdp}
        showConnectionError={showConnectionError}
        deviceExistsInAdp={deviceExists}
        showAccessError={showAccessError}
        connectionState={connectionState}
        handleChangeCode={handleChangeCode}
        valueCode={deviceCode.code}
        handleAddDevice={handleAddDevice}
        verificationCodeValid={verificationCodeValid}
        isActivationCodeWrong={
          isErrorAddDevice &&
          errorApiNotification(errorAddDevice) === 'Activation code is incorrect'
        }
        errorAddDevice={errorAddDevice}
        checkSerialError={checkSerialError}
      />
      <RegisterFooter token={token} loading={connectionState === FETCHING_STATES.loading} />
    </RegisterLayout>
  );
};

export default QuickConfiguration;
