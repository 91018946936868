/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useEffect } from 'react';
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { ViewModes, useMeetingStore } from 'reducers/meetingStore';

const bluetooth = new BluetoothWebController();

const useTelemetry = (delayInit: boolean = false) => {
  const { bluetoothMode, localConfigFetched } = useConfigStore((state) => ({
    bluetoothMode: state.bluetoothMode,
    localConfigFetched: state.localConfigFetched
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const viewMode = useMeetingStore((state) => state.viewMode);

  const telemetryOn = async () => {
    await delay(500);
    if (!TelemetryController.telemetryEnabled) {
      TelemetryController.telemetryOn(bluetoothMode);
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (deviceConnected && bluetooth.connected && localConfigFetched) {
      telemetryOn();
    }

    return async function clean() {
      if (
        deviceConnected &&
        bluetooth.connected &&
        localConfigFetched &&
        viewMode !== ViewModes.minimalEMG
      ) {
        await TelemetryController.telemetryOff(bluetoothMode);
      }
    };
  }, [localConfigFetched]);
};

export default useTelemetry;
