export const listenAblyReply = async (channelAbly, event) => {
  const ablyResponse = new Promise((resolve) => {
    channelAbly.subscribe(event, (message) => {
      if (message?.data) {
        resolve(message.data);
      }
    });
  });
  const response = await ablyResponse;
  channelAbly.unsubscribe(event);
  return response;
};
