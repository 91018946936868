import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 50px;
  background-color: #808080;
  font-size: 10px;
  color: #fff;
`;

const Snack = ({ title }) => <Wrapper>{title}</Wrapper>;

export default Snack;
