/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { FETCHING_STATES, LANGUAGES } from 'consts/consts';

type StoreState = {
  shownGlobalModal: string | null;
  isAuthorized: boolean;
  isCalendarHistory: boolean;
  isRecordReplay: boolean;
  language: LANGUAGES;
  switchOnRemoteSession: boolean;
  initialConfigApiState: FETCHING_STATES;
  initialConfigState: FETCHING_STATES;
  bluetoothState: FETCHING_STATES;
  firmwareState: FETCHING_STATES;
  getPcbState: FETCHING_STATES;
  procedureState: FETCHING_STATES;
  connectionState: FETCHING_STATES;
  synchronizingState: FETCHING_STATES;
  disconnectingState: FETCHING_STATES;
  firmwareUpdateState: FETCHING_STATES;
  videoSessionState: FETCHING_STATES;
  modals: { id: string }[];
};

type StoreStateActions = {
  setItemUiStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) => void;
  openModal: (modalId: string, args?: any) => void;
  closeModal: (modalId: string) => void;
  isModalOpen: (modalId: string) => boolean;
  additionalModalArgs: (modalId: string) => { open: boolean; args };
};

export const initialStateUiStore: StoreState = {
  shownGlobalModal: null,
  isAuthorized: false,
  isCalendarHistory: true,
  isRecordReplay: true,
  language: LANGUAGES.english,
  switchOnRemoteSession: false,
  initialConfigApiState: FETCHING_STATES.idle,
  initialConfigState: FETCHING_STATES.idle,
  bluetoothState: FETCHING_STATES.idle,
  firmwareState: FETCHING_STATES.idle,
  getPcbState: FETCHING_STATES.idle,
  procedureState: FETCHING_STATES.idle,
  connectionState: FETCHING_STATES.idle,
  synchronizingState: FETCHING_STATES.idle,
  disconnectingState: FETCHING_STATES.idle,
  firmwareUpdateState: FETCHING_STATES.idle,
  videoSessionState: FETCHING_STATES.idle,
  modals: []
};

export const useUiStore = create<StoreState & StoreStateActions>()(
  devtools(
    (set, get) => ({
      ...initialStateUiStore,
      setItemUiStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) =>
        set({ [`${property}`]: value }),
      openModal: (modalId: string, args = null) =>
        set((state) => ({ modals: { ...state.modals, [modalId]: { open: true, args } } })),
      closeModal: (modalId: string) =>
        set((state) => ({ modals: { ...state.modals, [modalId]: false } })),
      isModalOpen: (modalId: string) => {
        const { modals } = get();
        return Boolean(modals?.[modalId]?.open);
      },
      additionalModalArgs: (modalId: string) => {
        const { modals } = get();
        return modals?.[modalId];
      }
    }),
    { name: 'UI' }
  )
);
