import styled from 'styled-components';

export const WelcomeContainer = styled.div`
  display: grid;
  grid-template-columns: 490px 1fr;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
`;
