/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { useServicingParts, useServicingRepair } from 'hooks/api/useServicing';
import { MAIN_PCB_ORDER } from 'consts/consts';
import ErrorLabel from 'components/atoms/Error/ErrorLabel';
import { useAuthStore } from 'reducers/authStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 20px;
`;

type PcbReplacementInitializationProps = {
  handleClose: any;
  handleNext: Function;
};

const PcbReplacementInitialization = ({
  handleClose,
  handleNext
}: PcbReplacementInitializationProps) => {
  const { mutateAsync: createServicingRepair, isSuccess, isLoading } = useServicingRepair();
  const { result: servicingParts } = useServicingParts({
    perpage: 1000
  });
  const deviceId = useDeviceInfoStore((state) => state.deviceId);
  const [pcbPart, setPcbPart] = useState<number | boolean>();
  const me = useAuthStore((state) => state.me);

  const reportRepair = async () => {
    try {
      const formData = new FormData();
      formData.append('device_id', String(deviceId)!);
      formData.append('user_id', String(me!.id));
      formData.append(`parts[0][part_id]`, String(pcbPart));
      formData.append(`parts[0][reason]`, 'PCB replacement');
      await createServicingRepair(formData);
    } catch (e) {
      console.log('error', e);
      toast.error(String(e));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleNext();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (servicingParts) {
      const mainPcbPart = servicingParts.find((part) => part.name === MAIN_PCB_ORDER);
      setPcbPart(mainPcbPart ? mainPcbPart.id : false);
    }
  }, [servicingParts]);

  return (
    <DialogContent>
      <p>Do you want to report a new repair, and start PCB replacement procedure?</p>
      {pcbPart === false && (
        <ErrorLabel>PCB part does not exist, contact Aether support.</ErrorLabel>
      )}
      <ButtonsWrapper>
        <Button onClick={handleClose} variant='outlined' type='button'>
          Cancel
        </Button>
        {pcbPart && (
          <LoadingButton type='button' onClick={reportRepair} loading={isLoading}>
            <span>Report a new repair</span>
          </LoadingButton>
        )}
      </ButtonsWrapper>
    </DialogContent>
  );
};

export default PcbReplacementInitialization;
