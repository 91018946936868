import { useEffect } from 'react';
import { keys, pickBy } from 'lodash';
import { useUpdateDeviceConfig } from 'hooks/api/useDevice';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useConfigStore } from 'reducers/configStore';

const featureIsInactive = (val) => !val;
const countDisabledFeatures = (config) => keys(pickBy(config, featureIsInactive)).length;

const filteredConfigAPI = (configAPI) =>
  Object.fromEntries(Object.entries(configAPI).filter(([, value]) => value !== false));

const useSynchronizeConfigProperties = () => {
  const { firstConnection, localConfigFetched, config, configConflict, getInitialConfigAPI } =
    useConfigStore((state) => ({
      firstConnection: state.firstConnection,
      localConfigFetched: state.localConfigFetched,
      configConflict: state.configConflict,
      config: state.config,
      getInitialConfigAPI: state.getInitialConfigAPI
    }));
  const { updateConfig } = useUpdateDeviceConfig();
  const deviceId = useDeviceInfoStore((state) => Number(state.deviceId));

  useEffect(() => {
    if (localConfigFetched && !firstConnection && !configConflict) {
      const updateConfigProperties = async () => {
        const configs = [
          [config.common.config, config.common.configAPI],
          ...config.modes.map((mode) => [mode.config, mode.configAPI])
        ];
        let needsUpdate = false;
        for (let i = 0; i < configs.length; i += 1) {
          if (countDisabledFeatures(configs[i][1]) > 0) {
            needsUpdate = true;
            break;
          }
        }
        if (needsUpdate) {
          const configPayload = {
            deviceId,
            data: {
              common: JSON.stringify({
                ...config.common.config,
                ...filteredConfigAPI(config.common.configAPI)
              }),
              modes: config.modes.map((mode) => ({
                id: mode.id!,
                config: JSON.stringify({
                  ...mode.config,
                  ...filteredConfigAPI(mode.configAPI)
                })
              }))
            }
          };
          await updateConfig(configPayload);
          await getInitialConfigAPI();
        }
      };
      updateConfigProperties();
    }
  }, [localConfigFetched, configConflict]);
};

export default useSynchronizeConfigProperties;
