import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import Snack from 'components/atoms/Snack/Snack';
import AddNote, { NoteLabels } from 'components/molecules/Modals/AddNoteModal';
import { useModal } from 'hooks/useModal';
import ModalPortal from 'utils/Modal/ModalPortal';
import { ReactComponent as Trash } from 'assets/trash.svg';

const NotesListWrapper = styled.ul`
  list-style: none;

  li {
    border-top: 1px solid #e9ecef;
    font-size: 14px;
    margin-top: 16px;
  }

  p {
    padding: 16px 0;
  }
`;

const NoteDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const NoteSnackWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 16px;
`;

const NoteDate = styled.span`
  color: #808080;
  font-size: 12px;
`;

const StyledTrash = styled(Trash)`
  cursor: pointer;
  width: 100%;
`;

const NoteParagraph = styled.p`
  word-break: break-word;
`;

type RequiredNotesListProps = {
  notes: any[];
  handleAdd: Function;
  handleDelete: Function;
  showTypes?: boolean;
  isLoadingAddNote: boolean;
};

type OptionalNotesListProps = {
  notes: any[];
  handleAdd?: never;
  handleDelete: Function;
  showTypes?: boolean;
  isLoadingAddNote?: never;
};

const NotesList = ({
  notes,
  handleAdd,
  handleDelete,
  showTypes = true,
  isLoadingAddNote
}: RequiredNotesListProps | OptionalNotesListProps) => {
  const { handleClose, handleOpen, isOpen } = useModal();

  return (
    <>
      {isOpen && handleAdd && (
        <ModalPortal>
          <AddNote
            handleClose={handleClose}
            handleSubmitNote={handleAdd}
            showTypes={showTypes}
            isLoading={isLoadingAddNote}
          />
        </ModalPortal>
      )}
      {handleAdd && (
        <Button fullWidth onClick={handleOpen}>
          Add note
        </Button>
      )}
      <NotesListWrapper>
        {notes &&
          notes.map((note) => (
            <li>
              <NoteParagraph>{note?.note}</NoteParagraph>
              <NoteDetailsWrapper>
                <NoteSnackWrapper>
                  <StyledTrash onClick={() => handleDelete({ noteId: note?.id })} />
                  {showTypes && (
                    <Snack
                      title={NoteLabels.find((noteType) => noteType.id === note?.type)?.name}
                    />
                  )}
                </NoteSnackWrapper>
                <NoteDate>{dayjs(note?.updated_at).format('MM/DD/YYYY HH:mm')}</NoteDate>
              </NoteDetailsWrapper>
            </li>
          ))}
      </NotesListWrapper>
    </>
  );
};

export default NotesList;
