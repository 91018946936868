import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ReactComponent as Check } from "assets/check-solid.svg";
import { TextS } from "../Typography/Typography";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  ${TextS};
  margin-left: 20px;
`;

const HiddenRadioButton = styled.input.attrs({
  type: "radio",
})`
  height: 15px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 25px;
    width: 25px;
  }
`;

const RadioButton = styled.span`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  pointer-events: none;
  background-color: ${({ theme, isChecked }) =>
    isChecked ? theme.colorPrimary : "#f2f2f2"};
  transition: background-color 0.3s;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 30px;
    height: 30px;
  }
`;

const PressedIcon = styled(Check)`
  position: absolute;
  left: 5px;
  top: 4px;
  width: 9px;
  color: #fff;
  display: ${({ isChecked }) => (isChecked ? "block" : "none")};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    left: 8px;
    top: 7px;
    width: 13px;
  }
`;

const RadioInput = ({ label, id, name, checked, ...props }) => (
  <Wrapper {...props}>
    <HiddenRadioButton id={id} name={name} />
    <RadioButton isChecked={checked ? 1 : 0}>
      <PressedIcon isChecked={checked ? 1 : 0} />
    </RadioButton>
    <Label>{label}</Label>
  </Wrapper>
);

RadioInput.defaultProps = {
  checked: false,
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default RadioInput;
