/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { setApiAuthToken } from 'api/utils/apiClient';
import ConfirmationLoader from 'layouts/ConfirmationLoader/ConfirmationLoader';
import useRefreshToken from 'hooks/api/useRefreshToken';
import useWindowDimensions from 'hooks/useWindowDimensions';
import TopBar from 'components/molecules/TopBar/TopBar';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useMeetingStore, ViewModes } from 'reducers/meetingStore';
import useUnsaved from 'hooks/useUnsaved';
import Meeting from 'components/organisms/MeetingComponents/Meeting';
import LayoutSwitcher from 'hoc/LayoutHoc';
import { useUiStore } from 'reducers/uiStore';
import BootloaderController from 'bluetooth-handler/bootloaderController';
import { useAuthStore } from 'reducers/authStore';
import LiveConfigurator from 'components/organisms/LiveConfigurator/LiveConfigurator';
import { useReplayStore } from 'reducers/replayStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useConfigStore } from 'reducers/configStore';
import useModes from 'hooks/useModes';
import { HISTORY_EVENTS } from 'consts/consts';
import TelemetryController from 'bluetooth-handler/telemetryController';
import useSidebar from 'hooks/useSidebar';
import ChooseGripsComponent from './ChooseGrips/ChooseGripsComponent';
import ApplicationSettings from './ApplicationSettings';
import ServiceMenu from './ServiceMenu';
import TokenRefresh from './Auth/TokenRefresh/TokenRefresh';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import SessionHistory from './DeviceConfig/SessionHistory/SessionHistory';
import Modals, { MODALS, MODALS_ARGS } from './Modals';
import ConfigTemplateDetailsComponent from './Templates/ConfigTemplateDetails/ConfigTemplateDetailsComponent';
import ServicingForm from './Servicing/ServicingForm';
import MainViews from './MainViews';
import SupportTicket from './SupportTicket/SupportTicket';
import useUserData from '../hooks/useUserData';
import authProvider from '../providers/authProvider';
import WelcomePage from './WelcomePage';
import ProsthesisSettingsComponent from './ProsthesisSettings/ProsthesisSettingsComponent';
import GripsConfigurationComponent from './GripsConfiguration/GripsConfigurationComponent';
import GraphComponent from './Graph/GraphComponent';
import DeviceConfigComponent from './DeviceConfig/DeviceConfig/DeviceConfigComponent';
import DeviceHistoryComponent from './DeviceConfig/DeviceHistory/DeviceHistoryComponent';
import ConfigTemplatesComponent from './Templates/ConfigTemplates/ConfigTemplatesComponent';
import ErrorBoundary from '../layouts/ErrorBoundary';
import useRemoteSession from '../hooks/useRemoteSession';
import Register from './Register/Register/Register';
import QuickConfiguration from './Register/QuickConfiguration/QuickConfiguration';

const OuterWrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const ConfiguratorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const playSoundElement = () => {
  const audio_file1 = document.getElementById('audioID');
  audio_file1.volume = 0.2;
  audio_file1.currentTime = 0;
  audio_file1.play();
};

export const pauseSoundElement = () => {
  const audio_file1 = document.getElementById('audioID');
  audio_file1.pause();
};

const Bootloader = new BootloaderController();
const SentryRoute = Sentry.withSentryRouting(Route);

const BarLayout = ({ children, hideUtilityBar }) => {
  const [utilityBarHeight, setUtilityBarHeight] = useState(0);
  const { height } = useWindowDimensions();
  const { meetingStatus, viewMode } = useMeetingStore((state) => ({
    meetingStatus: state.meetingStatus,
    viewMode: state.viewMode
  }));

  useEffect(() => {
    const barHeight = document.querySelector('#utility-bar').getBoundingClientRect().height;
    setUtilityBarHeight(barHeight);
  }, [height]);

  return (
    <OuterWrapper>
      <ConfiguratorWrapper>
        <TopBar hidden={hideUtilityBar} id='utility-bar' />
        <div
          style={{
            height: `calc(100vh - ${utilityBarHeight}px)`
          }}>
          {children}
        </div>
      </ConfiguratorWrapper>
      {(meetingStatus ||
        viewMode === ViewModes.minimalEMG ||
        viewMode === ViewModes.standardEMG) && <Meeting />}
    </OuterWrapper>
  );
};

const Root = () => {
  const { t } = useTranslation();
  const { data: userData } = useUserData();
  const { refreshToken } = useRefreshToken();
  const [show, setShow] = useState(false);
  const { bluetoothDisconnect } = useBluetooth();
  const { isUnsaved } = useUnsaved();
  const { viewMode } = useMeetingStore((state) => ({
    viewMode: state.viewMode
  }));
  const location = useLocation();
  const { ticketCanBeSent } = useSidebar();
  const { shownGlobalModal, isModalOpen } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    shownGlobalModal: state.shownGlobalModal,
    isModalOpen: state.isModalOpen
  }));
  const { deviceConnected } = useDeviceInfoStore((state) => ({ deviceConnected: state.connected }));
  const token = useAuthStore((state) => state.token);
  const remoteSessionState = useLiveConfiguratorStore((state) => state);
  const { remoteSessionEnabled } = remoteSessionState;
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const consumeHistory = useConfigStore((state) => state.consumeHistory);
  const { handleOpenSave } = useModes();

  const hideUtilityBar = !token;

  useEffect(() => {
    Bootloader.listenBootloaderStatus();
    TelemetryController.initiateTelemetry();

    return function clearConnection() {
      if (deviceConnected) {
        bluetoothDisconnect();
      }
    };
  }, []);

  useEffect(() => {
    authProvider.getAcl().then(() => {
      refreshToken().finally(() => setShow(true));
    });
  }, [refreshToken]);

  useEffect(() => {
    setApiAuthToken(token);
  }, [token]);

  useEffect(() => {
    if (userData) {
      Sentry.setUser(userData);
    }
  }, [userData]);

  const preventBrowserWarningModalIsOpen =
    isModalOpen(MODALS.firmware) ||
    isModalOpen(MODALS.confirmSave) ||
    isModalOpen(MODALS.closeSession) ||
    isModalOpen(MODALS.disruptiveDisconnect);
  useEffect(() => {
    function handleLeave(event) {
      if (
        isUnsaved &&
        !preventBrowserWarningModalIsOpen &&
        !remoteSessionEnabled &&
        ticketCanBeSent &&
        token
      ) {
        playSoundElement();
        handleOpenSave(isUnsaved, {
          action: () => false,
          args: MODALS_ARGS.unsavedChangesDisconnect
        });
        event.returnValue = 'There is pending work. Sure you want to leave?';
      }
    }

    window.addEventListener('beforeunload', handleLeave);

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [
    isUnsaved,
    shownGlobalModal,
    remoteSessionEnabled,
    ticketCanBeSent,
    preventBrowserWarningModalIsOpen,
    token
  ]);

  return (
    <>
      <LiveConfigurator remoteSessionState={{ ...remoteSessionState, isUnsaved }}>
        <Modals />
        <div id='modal-root' />
        <audio
          id='audioID'
          src='https://aetherbiomedical-images.s3.amazonaws.com/soundEffect.wav'
          preload='auto'
        />
        <ErrorBoundary>
          <Switch>
            <SentryRoute exact path='/device' component={DeviceInfo} />
            <SentryRoute exact path='/support-ticket' component={SupportTicket} />
            <SentryRoute path='*' component={TokenRefresh} />
            <SentryRoute exact path='/register' component={Register} />
          </Switch>
        </ErrorBoundary>
        {token && show && (
          <ErrorBoundary>
            <Switch>
              <SentryRoute exact path='/register' component={Register} />
              <SentryRoute exact path='/configuration' component={QuickConfiguration} />
              <BarLayout hideUtilityBar={hideUtilityBar}>
                <MainViews>
                  <SentryRoute exact path='/'>
                    <Redirect to='/choose-grips' />
                  </SentryRoute>
                  <SentryRoute exact path='/device' component={DeviceInfo} />
                  <SentryRoute
                    exact
                    path='/choose-grips'
                    render={() => (
                      <LayoutSwitcher
                        header={t('views.choose_grips')}
                        handleUndo={() => consumeHistory(HISTORY_EVENTS.chooseGrips)}
                        isUnsaved={isUnsaved}
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 260px 0'
                        paddingMinimal='0 0 260px 0'
                        headerPadding='0 0 20px 0'
                        component={ChooseGripsComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/prosthesis-settings'
                    render={() => (
                      <LayoutSwitcher
                        header='Prosthesis settings'
                        handleUndo={() => consumeHistory(HISTORY_EVENTS.prosthesisSettings)}
                        isUnsaved={isUnsaved}
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        headerPadding='0 0 20px 0'
                        component={ProsthesisSettingsComponent}
                      />
                    )}
                  />
                  <SentryRoute exact path='/application-settings' component={ApplicationSettings} />
                  <SentryRoute
                    exact
                    path='/grips-configuration'
                    render={() => (
                      <LayoutSwitcher
                        header={t('views.grips_configuration')}
                        handleUndo={() => consumeHistory(HISTORY_EVENTS.gripsConfiguration)}
                        isUnsaved={isUnsaved}
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        headerPadding='0 0 20px 0'
                        component={GripsConfigurationComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/graph'
                    render={() => (
                      <LayoutSwitcher
                        header={replayIsEnabled ? t('views.emg_playback') : t('views.emg_settings')}
                        handleUndo={() => consumeHistory(HISTORY_EVENTS.emgSettings)}
                        isUnsaved={isUnsaved}
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        headerPadding='0 0 20px 0'
                        component={GraphComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/service-menu'
                    render={() => (
                      <LayoutSwitcher
                        header='Service menu'
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        headerPadding='0 0 20px 0'
                        component={ServiceMenu}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/device-history/:configId'
                    render={() => (
                      <LayoutSwitcher
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 260px 0'
                        component={DeviceConfigComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/session-history'
                    render={() => (
                      <LayoutSwitcher
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        component={SessionHistory}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/device-history'
                    render={() => (
                      <LayoutSwitcher
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 260px 0'
                        component={DeviceHistoryComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/servicing'
                    render={() => (
                      <LayoutSwitcher
                        header='Servicing form'
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 40px 0'
                        component={ServicingForm}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/config-templates/:templateId'
                    render={() => (
                      <LayoutSwitcher
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 260px 0'
                        component={ConfigTemplateDetailsComponent}
                      />
                    )}
                  />
                  <SentryRoute
                    exact
                    path='/config-templates'
                    render={() => (
                      <LayoutSwitcher
                        isStandard={
                          viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG
                        }
                        padding='0 0 40px 0'
                        component={ConfigTemplatesComponent}
                      />
                    )}
                  />
                </MainViews>
              </BarLayout>
            </Switch>
          </ErrorBoundary>
        )}
        {!token && show && (
          <Switch>
            <SentryRoute exact path='/register' component={Register} />
            <SentryRoute exact path='/' component={WelcomePage} />
            <SentryRoute path='*'>
              <Redirect to='/' />
            </SentryRoute>
          </Switch>
        )}
        {!show && <ConfirmationLoader />}
      </LiveConfigurator>
    </>
  );
};

export default Sentry.withProfiler(Root);
