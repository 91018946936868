/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'reducers/helpers/initialState';
import {
  userFeedbackTypeMap,
  userFeedbackTypeReversedMap,
  userFeedbackTypePcbV4Map,
  userFeedbackTypePcbV4ReversedMap
} from 'utils/definesLocal';
import { OptionsWithSlider } from '../Sliders';

const BuzzingVolumeSettings = ({
  addHistoryProsthesis,
  buzzingVolumeSettings,
  userFeedbackType,
  replayIsEnabled,
  setConfigProperty,
  supportNewFeedbackType
}) => {
  const [buzzingVolumeLocal, setBuzzingVolumeLocal] = useState<any>(
    initialState.config.buzzingVolumeSettings[0]
  );

  const handleBuzzingVolumeSlider = (value) => {
    addHistoryProsthesis(setConfigProperty('buzzingVolumeSettings', [value]));
  };

  const handleUserFeedbackType = (value) => {
    addHistoryProsthesis(setConfigProperty('userFeedbackType', [value]));
  };

  useEffect(() => {
    if (buzzingVolumeSettings) {
      setBuzzingVolumeLocal(buzzingVolumeSettings[0]);
    }
  }, [buzzingVolumeSettings]);

  const calculatePercentageValue = (value: number) => Math.round((value / 255) * 100);

  const BuzzingVolumeSettingsNewVersion = useMemo(
    () => (
      <OptionsWithSlider
        header='Buzzing settings'
        toggleOptions={Array.from(userFeedbackTypePcbV4Map, ([, value]) => value)}
        disabled={replayIsEnabled}
        toggleValue={userFeedbackTypePcbV4Map.get(userFeedbackType[0])}
        sliderValue={buzzingVolumeLocal}
        handleSliderChange={(e, value) => setBuzzingVolumeLocal(value)}
        sliderLimits={{ min: 0, max: 255 }}
        sliderLabel={`Value: ${calculatePercentageValue(buzzingVolumeLocal)} %`}
        handleToggleChange={(value) =>
          handleUserFeedbackType(userFeedbackTypePcbV4ReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args) => handleBuzzingVolumeSlider(args[1])}
        description='Set type of user feedback indicator and volume of the buzzer'
      />
    ),
    [userFeedbackType, buzzingVolumeLocal, buzzingVolumeSettings]
  );

  const BuzzingVolumeSettings = useMemo(
    () => (
      <OptionsWithSlider
        header='Buzzing settings'
        toggleOptions={Array.from(userFeedbackTypeMap, ([, value]) => value)}
        disabled={replayIsEnabled}
        toggleValue={userFeedbackTypeMap.get(userFeedbackType[0])}
        sliderValue={buzzingVolumeLocal}
        handleSliderChange={(e, value) => setBuzzingVolumeLocal(value)}
        sliderLimits={{ min: 0, max: 255 }}
        sliderLabel={`Value: ${calculatePercentageValue(buzzingVolumeLocal)} %`}
        handleToggleChange={(value) =>
          handleUserFeedbackType(userFeedbackTypeReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args) => handleBuzzingVolumeSlider(args[1])}
        description='Set type of user feedback indicator and volume of the buzzer'
      />
    ),
    [userFeedbackType, buzzingVolumeLocal, buzzingVolumeSettings]
  );

  return supportNewFeedbackType ? BuzzingVolumeSettingsNewVersion : BuzzingVolumeSettings;
};

export default BuzzingVolumeSettings;
