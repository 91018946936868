import { pick } from 'lodash';
import { defaultConfig } from 'consts/deviceConfig/deviceConfig';
import { DeviceInfoState } from 'reducers/deviceInfoStore';
import { numericalFirmwareVersion } from 'utils/funcs';
import { ConfigStoreState } from '../configStore';

export const getCurrentConfigSelector = (state: ConfigStoreState, forceSlot?: number) => {
  let slotUsed = state.slotSelected;
  if (forceSlot !== undefined) slotUsed = forceSlot;

  if (
    state.config.common.config &&
    state.config.modes.find((mode) => mode.slot === slotUsed)?.config
  ) {
    return {
      ...state.config.common.config,
      ...state.config.modes.find((mode) => mode.slot === slotUsed)?.config
    };
  }
  return defaultConfig;
};

export const getCurrentConfigApiSelector = (state: ConfigStoreState, forceSlot?: number) => {
  let slotUsed = state.slotSelected;
  if (forceSlot !== undefined) slotUsed = forceSlot;

  if (
    state.config.common.configAPI &&
    state.config.modes.find((mode) => mode.slot === slotUsed)?.configAPI
  ) {
    return {
      ...state.config.common.configAPI,
      ...state.config.modes.find((mode) => mode.slot === slotUsed)?.configAPI
    };
  }
  return defaultConfig;
};

export const getModeBySlotSelector = (state, slot) =>
  state.config.modes.find((mode) => mode.slot === slot);
export const getFwVersionSelector = (state: DeviceInfoState) => {
  const fwExists = state.versions?.numerical ?? numericalFirmwareVersion(state.firmware?.name);

  if (!fwExists) return -1;

  return state.versions?.numerical ?? numericalFirmwareVersion(state.firmware?.name);
};

export const getSomeConfigSelector = (config, keys) => pick(config, keys);
