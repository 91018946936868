/* eslint-disable dot-notation */
import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from "@sentry/react";

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const withCredentials = {
  withCredentials: true
};

export const withCredentialsAndMultipartForm = {
  ...withCredentials,
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export function setApiAuthToken(token: string | null) {
  if (token) {
    // @ts-ignore
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    // @ts-ignore
    delete instance.defaults.headers['Authorization'];
  }
}

export default instance;
