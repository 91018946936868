import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as Logo } from 'assets/logo_new.svg';
import Navbar from 'components/molecules/Navbar/Navbar';
import { ReactComponent as Background } from 'assets/Vector1.svg';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useUiStore } from 'reducers/uiStore';
import { FETCHING_STATES } from 'consts/consts';
import { Button } from '@mui/material';
import useLeaveConfigurator from 'hooks/useLeaveConfigurator';

const Wrapper = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${({ theme }) => theme.colorPrimary};
  width: ${({ collapsed }) => (collapsed ? '50px' : '160px')};
  padding: 0px 0 20px 0;
  overflow: auto;
  white-space: nowrap;
  gap: 30px;

  a {
    z-index: 1;
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${({ collapsed }) => (collapsed ? '50px' : '210px')};
    padding: 0px 0 40px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: ${({ collapsed }) => (collapsed ? '50px' : '260px')};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  display: block;
  padding: 15% 0;
  width: ${({ collapsed }) => (collapsed ? '40px' : '120px')};
  color: ${({ theme }) => theme.typography.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${({ collapsed }) => (collapsed ? '40px' : '160px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: ${({ collapsed }) => (collapsed ? '40px' : '200px')};
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 15px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 20px;
    padding: 0 30px;
  }
`;

const BackgroundWrapper = styled(Background)`
  display: none;
  position: absolute;
  top: 0;
  left: -5px;
  height: 93%;
  width: 109%;
`;

const StyledAnimatedButton = styled.div`
  width: 100%;
  z-index: 1;
`;

const Sidebar = memo(() => {
  const { t } = useTranslation();
  const { bluetoothConnect } = useBluetooth();
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const liveSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const { connectionState } = useUiStore((state) => ({
    connectionState: state.connectionState
  }));
  const { handleLogout, handleDisconnect } = useLeaveConfigurator();

  return (
    <Wrapper collapsed={false} data-tour-general='sidebar'>
      <InnerWrapper>
        <NavLink to='/choose-grips'>
          <StyledLogo collapsed={false} />
        </NavLink>
        <Navbar collapsed={false} />
        <StyledAnimatedButton>
          <>
            <ButtonWrapper>
              {!liveSessionEnabled && (
                <LoadingButton
                  disableElevation
                  fullWidth
                  onClick={deviceConnected ? () => handleDisconnect() : () => bluetoothConnect()}
                  loading={connectionState === FETCHING_STATES.loading}>
                  <span>{deviceConnected ? t('disconnect') : t('connect')}</span>
                </LoadingButton>
              )}
              <Button onClick={handleLogout}>Logout</Button>
            </ButtonWrapper>
          </>
          )
        </StyledAnimatedButton>
      </InnerWrapper>
      <BackgroundWrapper />
    </Wrapper>
  );
});

export default Sidebar;
