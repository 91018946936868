export const TRANSLATIONS_PL = {
  navbar: {
    choose_grips: 'Wybór chwytów',
    emg_settings: 'Ustawienia EMG',
    emg_playback: 'Nagranie EMG',
    grips_configuration: 'Konfig. chywtów',
    prosthesis_settings: 'Ustawienia protezy',
    update_firmware: 'Aktualizacja oprog.',
    service_menu: 'Menu serwisowe',
    logout: 'Wyloguj',
    login: 'Login'
  },
  views: {
    choose_grips: 'Wybór chwytów',
    emg_settings: 'Ustawienia EMG',
    emg_playback: 'Nagranie EMG',
    grips_configuration: 'Konfiguracja chwytów',
    prosthesis_settings: 'Ustawienia protezy',
    update_firmware: 'Aktualizacja oprogramowania',
    service_menu: 'Menu serwisowe'
  },
  utils: {
    send: 'Wyślij do protezy',
    send_unsaved: '(niezapisane zmiany)',
    versions: 'Wersje',
    restore: 'Przywróć domyślne',
    undo: 'Cofnij',
    help: 'Help - Tour',
    language: 'Język',
    disconnected: 'Urządzenie rozłączone',
    connected: 'Urządzenie połączone'
  },
  emg: {
    combined: 'Podwójny',
    separate: 'Oddzielny',
    proportional: 'Proporcjonalny'
  },
  disconnect: 'Rozłącz',
  connect: 'Połącz',
  settings: {
    self: 'Ustawienia',
    app_settings: 'Ustawienia aplikacji',
    bluetooth_mode: 'Tryb bluetooth'
  },
  prosthesis_settings: {
    opposed: 'Kciuk przeciwstawny',
    non_opposed: 'Kciuk zgodny',
    pairing_mode: 'Pary chwytów',
    sequential_mode: 'Tryb sekwencyjny',
    freeze_mode: 'Freeze Mode',
    grip: 'Chwyt',
    input_options: {
      self: 'Czujniki sterujące',
      emg: 'EMG'
    },
    input_sites: {
      self: 'Konfiguracja elektrod',
      single: 'Pojedyncza',
      dual_direct: 'Podwójne',
      dual_inverted: 'Podwójne odwrócone'
    },
    control_strategy: {
      self: 'Control strategy',
      threshold: 'Wartość progowa',
      proportional: 'Proporcjonaly'
    },
    grip_switching_modes: {
      self: 'Tryb przełączania uchwytów',
      co_contraction: 'Ko-kontrakcja',
      open_open: 'Otwórz-otwórz',
      single_electrode: 'Pojedyncza elektroda',
      hold_open: 'Trzymaj-otwórz'
    },
    soft_grip: {
      self: 'Soft-grip',
      finger_strength: 'Siła palca',
      calibration: 'Procedura kalibracji'
    },
    emg_spikes: {
      self: 'Ignore EMG spikes'
    },
    autograsp: {
      self: 'Autograsp',
      force: 'Force'
    },
    hold_open: {
      self: 'Hold-open grip switching mode settings',
      primary: 'Primary hold open time',
      secondary: 'Secondary hold open time'
    },
    interval: {
      self: 'Interwał'
    }
  },
  generic_switch: {
    on: 'Włącz',
    off: 'Wyłącz'
  },
  seconds: 'sekundy',
  session: {
    in_progress: 'W trakcie sesji',
    restore_point: 'Zapisz jako punkt przywracania',
    history: 'Hisoria',
    stop: 'Zatrzymaj sesję'
  }
};
