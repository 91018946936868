import { useUiStore } from 'reducers/uiStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import useConnect from 'hooks/bluetooth/useConnect';
import { MODALS, MODALS_ARGS } from 'views/Modals';
import useUnsaved from './useUnsaved';
import useLogout from './api/useLogout';
import useModes from './useModes';
import useSidebar from './useSidebar';

const useLeaveConfigurator = () => {
  const { openModal } = useUiStore((state) => ({
    openModal: state.openModal
  }));
  const { logout } = useLogout();
  const { isUnsaved } = useUnsaved();
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore((state) => ({
    enabled: state.enabled
  }));
  const { handleOpenSave } = useModes();
  const { bluetoothDisconnect } = useConnect();
  const { ticketCanBeSent } = useSidebar();

  const redirectAdp = () => {
    window.location.href = `${process.env.REACT_APP_ADMIN_PANEL_URL}/dashboard`;
  };

  const handleLogout = () => {
    if (isUnsaved) {
      if (remoteSessionEnabled) {
        openModal(MODALS.closeSession, { action: logout, args: MODALS_ARGS.unsavedChanges });
        return;
      }

      if (ticketCanBeSent) {
        handleOpenSave(isUnsaved, { action: logout, args: MODALS_ARGS.unsavedChanges });
        return;
      }
    }
    logout();
  };

  const handleRedirectAdp = () => {
    if (isUnsaved) {
      if (remoteSessionEnabled) {
        openModal(MODALS.closeSession, { action: redirectAdp, args: MODALS_ARGS.unsavedChanges });
        return;
      }
      if (ticketCanBeSent) {
        handleOpenSave(isUnsaved, { action: redirectAdp, args: MODALS_ARGS.unsavedChanges });
        return;
      }
    }
    redirectAdp();
  };

  const handleDisconnect = () => {
    if (isUnsaved) {
      if (ticketCanBeSent) {
        handleOpenSave(isUnsaved, {
          action: () => false,
          args: MODALS_ARGS.unsavedChangesDisconnect
        });
        return;
      }
    }
    bluetoothDisconnect();
  };

  return { handleLogout, handleRedirectAdp, handleDisconnect };
};

export default useLeaveConfigurator;
