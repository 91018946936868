/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable no-await-in-loop */
import {
  timeoutCommand,
  timeoutCommandCustom,
  timeoutCommandFirmware,
  timeoutCommandLite,
  timeoutCommandLiteCustom
} from 'utils/funcs';
import {
  autoGraspEntry,
  batteryBeepEntry,
  buzzingVolumeSettingsEntry,
  coContractionTimingsEntry,
  controlConfigEntry,
  DeviceConfigTemplate,
  emgGainsEntry,
  emgSpikeEntry,
  emgThresholdsEntry,
  fingerStrengthEntry,
  freezeModeEmgEntry,
  generalHandSettingsEntry,
  gripPairsConfigEntry,
  gripSequentialConfigEntry,
  gripsPositionsEntry,
  holdOpenEntry,
  intervalEntry,
  pulseTimingsEntry,
  rtcDate,
  singleElectrodeModeEntry,
  singleElectrodeModeSettingsEntry,
  softGripEntry,
  strengthIndexEntry,
  userFeedbackType,
  freezeModeEmgSettingsEntry,
  inputDevicesEntry,
  followingGripEntry,
  emergencyBatterySettingsEntry
} from 'consts/deviceConfig/deviceConfig.types';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { CALIBRATION_PROCEDURE_TIMEOUT } from 'consts/consts';
import BluetoothWebControllerLE from './bluetoothLE';
import BluetoothWebController from './bluetoothWeb';
import {
  ControlModes,
  GripSwitchingModes,
  InputDevices,
  InputSites,
  SpeedControlStrategies
} from '../bluetooth/Bluetooth/Control';
import { BootloaderStates, Commands, QueryCommands } from '../bluetooth/Bluetooth/Defines';
import { Grips } from '../bluetooth/Bluetooth/Grips';

const bluetooth = new BluetoothWebController();
const bluetoothLE = new BluetoothWebControllerLE();

const errors = {
  badConnection: 'Connection is corrupted, restart the prosthesis and try connecting again',
  badFingersConfig: 'Fingers config could not be sent, check prosthesis connection',
  badInitialConfig: 'Initial config could not be loaded, try again',
  badGripConfig: 'Grip config could not be sent, check prosthesis connection',
  badControlConfig: 'Control config could not be sent, check prosthesis connection',
  bootloaderNotResponding: 'Connection failed, bootloader not responding'
};

export enum bluetoothMode {
  classic = 'classic',
  ble = 'ble'
}

export type statusTypeFreeze = [0] | [1];

export const getBootloaderStatusTimed = async (mode: bluetoothMode): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommand(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus,
        mode
      ),
    errors.bootloaderNotResponding
  )) || [{ payload: false }];
  return bootloaderStatus;
};

export const getBootloaderStatus = async (mode: bluetoothMode): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus,
        mode
      ),
    300,
    1
  )) || [{ payload: null }];
  return bootloaderStatus?.[0];
};

export const getEmgThresholds = async (mode: bluetoothMode) => {
  const [{ payload: emgThresholds }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryEmgThresholds,
      [],
      Commands.kSetEmgThresholds,
      mode
    )
  )) || [{ payload: false }];
  return emgThresholds;
};

export const getGripsPairsConfig = async (mode: bluetoothMode) => {
  const [{ payload: gripPairsConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryGripPairs, [], Commands.kSetGripPairs, mode)
  )) || [[{ payload: false }]];
  return gripPairsConfig;
};

export const getGripsSequentialConfig = async (mode: bluetoothMode) => {
  const [{ payload: gripSequentialConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryGripsSequence,
      [],
      Commands.kSetGripsSequence,
      mode
    )
  )) || [{ payload: false }];
  return gripSequentialConfig;
};

export const getGripPositions = async (grip: Grips, mode: bluetoothMode): Promise<number[]> => {
  const [{ payload: gripPositions }]: [{ payload: number[] }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryInitialGripPositions,
        [grip],
        Commands.kInitialGripPositions,
        mode
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripPositions;
};

export const getGripLimitPositions = async (
  grip: Grips,
  mode: bluetoothMode
): Promise<number[]> => {
  const [{ payload: gripLimitPositions }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryFingerLimits,
        [grip],
        Commands.kSetFingerLimits,
        mode
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripLimitPositions;
};

export const getControlConfig = async (mode: bluetoothMode) => {
  const [{ payload: controlConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryControlConfig, [], Commands.kControlConfig, mode)
  )) || [{ payload: false }];
  return controlConfig;
};

export const getAutoGrasp = async (mode: bluetoothMode) => {
  const [{ payload: autoGrasp }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryAutograspSettings,
      [],
      Commands.kAutoGraspSettings,
      mode
    )
  )) || [{ payload: false }];
  return autoGrasp;
};

export const getHoldOpen = async (mode: bluetoothMode) => {
  const [{ payload: holdOpen }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryHoldOpenGripSwitchingSettings,
      [],
      Commands.kSetHoldOpenGripSwitchingSettings,
      mode
    )
  )) || [{ payload: false }];
  return holdOpen;
};

export const getSoftGrip = async (mode: bluetoothMode) => {
  const [{ payload: softGrip }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCurrentControlStrategy,
      [],
      Commands.kSetCurrentControlStrategy,
      mode
    )
  )) || [{ payload: false }];
  return softGrip;
};

export const getUserFeedbackType = async (mode: bluetoothMode) => {
  const [{ payload: userFeedbackType }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryUserFeedbackType,
      [],
      Commands.kSetUserFeedbackType,
      mode
    )
  )) || [{ payload: false }];
  return userFeedbackType;
};

export const getBatteryBeep = async (mode: bluetoothMode) => {
  const [{ payload: batteryBeep }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLowBatteryCheckerSettings, 0],
      Commands.kSetLowBatterySettings,
      mode
    )
  )) || [{ payload: false }];
  return batteryBeep;
};

export const getSingleElectrodeMode = async (mode: bluetoothMode) => {
  const [{ payload: singleElectrodeMode }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQuerySingleElectrodeSelectedMode, 0],
      Commands.kSingleElectrodeSelectedMode,
      mode
    )
  )) || [{ payload: false }];
  return singleElectrodeMode;
};

export const getBuzzingVolumeSettings = async (mode: bluetoothMode) => {
  const [{ payload: buzzingVolumeSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryBuzzingVolumeSettings, 0],
      Commands.kBuzzingVolumeSettings,
      mode
    )
  )) || [{ payload: false }];
  return buzzingVolumeSettings;
};

export const getSingleElectrodeModeSettings = async (mode: bluetoothMode) => {
  const [{ payload: singleElectrodeModeSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQuerySingleElectrodeModeFastOpenSlowCloseSettings, 0],
      Commands.kSingleElectrodeModeFastOpenSlowCloseSettings,
      mode
    )
  )) || [{ payload: false }];

  return singleElectrodeModeSettings;
};

export const getEmgSpike = async (mode: bluetoothMode) => {
  const [{ payload: emgSpike }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryEMGSpikesCancelling,
      [],
      Commands.kSetEMGSpikesCancellingSettings,
      mode
    )
  )) || [{ payload: false }];
  return emgSpike;
};

export const getFingerStrength = async (mode: bluetoothMode) => {
  const [{ payload: fingerStrength }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFingerCurrentThreshold,
      [1],
      Commands.kFrameTypeFingerCurrentThreshold,
      mode
    )
  )) || [{ payload: false }];
  return fingerStrength;
};

const getTelemetryData = async (mode: bluetoothMode) => {
  const data = await bluetooth.queryResponseCommand(
    Commands.kStartOrStopTransmittingTelemetryData,
    [1],
    Commands.kTelemetryData,
    mode
  );
  return data;
};

export const queryTelemetryOnce = async (mode: bluetoothMode) => {
  if (bluetooth.connected || bluetoothLE.connected) {
    const data = await getTelemetryData(mode);
    const telemetryObject = {
      fingers: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {}
      },
      isThumbOpposed: !(data[0].payload[22] & 0x02)
    };
    for (let i = 0; i < 5; i += 1) {
      telemetryObject.fingers[i].encoderTicks = data[0].payload[i * 4];
    }
    await telemetryEnabled(false, mode);
    return telemetryObject;
  }
  return false;
};

export const telemetryEnabled = async (telemetryStatus: boolean, mode: bluetoothMode) => {
  let telemetryStatusNum: number = 0;
  if (telemetryStatus === true) {
    telemetryStatusNum = 1;
  }

  await bluetooth.writeWeb(
    Commands.kStartOrStopTransmittingTelemetryData,
    [telemetryStatusNum],
    mode
  );
};

export const enterBootloaderMode = async (
  bootloaderMode: BootloaderStates,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kEnterBootloaderMode, [bootloaderMode], mode);
};

export const getSerialNumber = async (mode: bluetoothMode) => {
  const [{ payload: serialNumber }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQuerySerialNumber,
      [],
      Commands.kSerialNumberReply,
      mode
    )
  )) || [{ payload: false }];
  return serialNumber;
};

export const getBootloaderVersion = async (mode: bluetoothMode) => {
  const [{ payload: bootloaderVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryBootloaderVersion,
      [],
      Commands.kBootloaderVersion,
      mode
    )
  )) || [{ payload: false }];
  return bootloaderVersion;
};

export const getInterval = async (mode: bluetoothMode) => {
  const [{ payload: interval }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryLongCoContractionTime,
      [],
      Commands.kSetIntervalBetweenCocontractionPulses,
      mode
    )
  )) || [{ payload: false }];
  return interval;
};

export const getFirmwareVersion = async (mode: bluetoothMode) => {
  const [{ payload: firmwareVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryFirmwareAndAppVersions,
      [],
      Commands.kFirmwareAndAppVersions,
      mode
    )
  )) || [{ payload: false }];
  return firmwareVersion;
};

export const getPcbVersion = async (mode: bluetoothMode) => {
  const [{ payload: pcbVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryDevicesInfo, 0],
      Commands.kReplyDevicesInfo,
      mode
    )
  )) || [{ payload: false }];
  return pcbVersion;
};

export const getFreezeMode = async (mode: bluetoothMode) => {
  const [{ payload: freezeMode }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFreezeMode,
      [],
      Commands.kFrameTypeFreezeMode,
      mode
    )
  )) || [{ payload: false }];
  return freezeMode;
};

export const waitRequestAction = async (mode: bluetoothMode) => {
  const [{ payload: action }] = (await timeoutCommandCustom(
    () => bluetooth.queryResponseCommand(null, null, Commands.kRequestAction, mode),
    10000
  )) || [{ payload: false }];
  return action;
};

export const getEmgGains = async (mode: bluetoothMode) => {
  const [{ payload: emgGains }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQuerySignalGains, [], Commands.kSetSignalGains, mode)
  )) || [{ payload: false }];
  return emgGains;
};

export const getPulseTimings = async (mode: bluetoothMode) => {
  const [{ payload: pulseTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryPulseTimings, [], Commands.kPulseTimings, mode)
  )) || [{ payload: false }];
  return pulseTimings;
};

export const getCoContractionTimings = async (mode: bluetoothMode) => {
  const [{ payload: coContractionTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCoContractionTimings,
      [],
      Commands.kCoContractionTimings,
      mode
    )
  )) || [{ payload: false }];
  return coContractionTimings;
};

export const getFreezeModeEmg = async (mode: bluetoothMode) => {
  const [{ payload: freezeModeEmg }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryFreezeSignalDetectorSettings, 0],
      Commands.kFreezeSignalDetectorSettings,
      mode
    )
  )) || [{ payload: false }];
  return freezeModeEmg;
};

export const getGeneralHandSettings = async (mode: bluetoothMode) => {
  const [{ payload: generalHandSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryGeneralHandSettings, 0],
      Commands.kGeneralHandSettings,
      mode
    )
  )) || [{ payload: false }];
  return generalHandSettings ? generalHandSettings.slice(0, 3) : generalHandSettings;
};

export const getFreezeModeEmgSettings = async (mode: bluetoothMode) => {
  const [{ payload: freezeModeEmgSettingsClose }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLongDoubleStageHoldSettings, 0],
      Commands.kLongDoubleStageHoldSettings,
      mode
    )
  )) || [{ payload: false }];
  await delay(100);
  await bluetooth.queryResponseCommand(
    Commands.kQueryForwarder,
    [QueryCommands.kQueryLongDoubleStageHoldSettings, 1],
    Commands.kLongDoubleStageHoldSettings,
    mode
  );
  await delay(100);
  const [{ payload: freezeModeEmgSettingsOpen }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLongDoubleStageHoldSettings, 1],
      Commands.kLongDoubleStageHoldSettings,
      mode
    )
  )) || [{ payload: false }];
  if (freezeModeEmgSettingsOpen) {
    const thresholdClosing = freezeModeEmgSettingsClose[1];
    const thresholdOpening = freezeModeEmgSettingsOpen[1];
    const stage1HoldOpenTime = freezeModeEmgSettingsClose[2];
    const stage2HoldOpenTime = freezeModeEmgSettingsClose[3];
    return [thresholdOpening, thresholdClosing, stage1HoldOpenTime, stage2HoldOpenTime];
  }
  return false;
};

export const getFollowingGrip = async (mode: bluetoothMode) => {
  const [{ payload: followingGrip }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryGripFollowing,
      [],
      Commands.kSetGripFollowing,
      mode
    )
  )) || [{ payload: false }];
  return followingGrip;
};

export const getEmergencyBatterySettings = async (mode: bluetoothMode) => {
  const [{ payload: emergencyBatterySettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryUsageModeLowBatteryEmergencySettings, 0],
      Commands.kLowBatteryEmergencySettings,
      mode
    )
  )) || [{ payload: false }];
  return emergencyBatterySettings;
};

export const getEmergencyBatteryModeStatus = async (mode: bluetoothMode) => {
  const [{ payload: emergencyBatteryModeState }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLowBatteryEmergencyState, 0],
      Commands.kLowBatteryEmergencyState,
      mode
    )
  )) || [{ payload: false }];
  return emergencyBatteryModeState;
};

export const sendFwPartWithResponse = async (fwPart, mode: bluetoothMode) => {
  const [{ payload: fwPartStatus }] = (await timeoutCommandFirmware(() =>
    bluetooth.queryResponseCommand(Commands.kPartOfFWImage, fwPart, Commands.kFwPartStatus, mode)
  )) || [{ payload: false }];
  return fwPartStatus;
};

export const runProcedure = async (
  procedureNumber: ProcedureTypes,
  input,
  mode: bluetoothMode
): Promise<number[]> => {
  const [{ payload: procedureReply }] = (await timeoutCommandCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kFrameTypeRunProcedure,
        [procedureNumber, ...input],
        Commands.kFrameTypeProcedureReply,
        mode
      ),
    CALIBRATION_PROCEDURE_TIMEOUT
  )) || [{ payload: false }];
  return procedureReply;
};

export const postCurrentGrip = async (grip: Grips, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripMode, [grip], mode);
};

export const postControlConfig = async (controlConfig: controlConfigEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kControlConfig, controlConfig, mode);
};

export const postGripPairs = async (gripPairsConfig: gripPairsConfigEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripPairs, gripPairsConfig, mode);
};

export const postGripSpeed = async (gripSpeed: number, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripSpeed, [gripSpeed], mode);
};

export const postRtcTime = async (rtcDate: rtcDate, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetRtcTime, rtcDate, mode);
};

export const postGripSequentialConfig = async (
  gripSequentialConfig: gripSequentialConfigEntry,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kSetGripsSequence, gripSequentialConfig, mode);
};

export const postInitialGripPositions = async (
  grip: Grips,
  initialGripPositions,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kInitialGripPositions, [grip, ...initialGripPositions], mode);
};

export const postFingerLimits = async (grip: Grips, fingerLimits, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetFingerLimits, [grip, ...fingerLimits], mode);
};

export const postInputSite = async (inputSite: InputSites[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kInputSite, inputSite, mode);
};

export const postInputOption = async (inputDevice: InputDevices[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kInputOption, inputDevice, mode);
};

export const postControlMode = async (controlMode: ControlModes[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetControlMode, controlMode, mode);
};

export const postSpeedControlStrategy = async (
  speedControlStrategy: SpeedControlStrategies[],
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kSetSpeedControlStrategy, speedControlStrategy, mode);
};

export const postGripSwitchingModes = async (
  gripSwitchingMode: GripSwitchingModes[],
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kSetGripSwitchingMode, gripSwitchingMode, mode);
};

export const postEmgSpikeCancelling = async (emgSpike: emgSpikeEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetEMGSpikesCancellingSettings, emgSpike, mode);
};

export const postAutoGrasp = async (autoGrasp: autoGraspEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kAutoGraspSettings, autoGrasp, mode);
};

export const postBatteryBeep = async (batteryBeep: batteryBeepEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetLowBatterySettings, batteryBeep, mode);
};

export const postHoldOpen = async (holdOpen: holdOpenEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetHoldOpenGripSwitchingSettings, holdOpen, mode);
};

export const postSoftGrip = async (softGrip: softGripEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetCurrentControlStrategy, softGrip, mode);
};

export const postEmgThresholds = async (emgThresholds: emgThresholdsEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetEmgThresholds, emgThresholds, mode);
};

export const postInterval = async (interval: intervalEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetIntervalBetweenCocontractionPulses, interval, mode);
};

export const postFingerStrength = async (
  fingerStrength: fingerStrengthEntry,
  mode: bluetoothMode
) => {
  const strengthIndex: strengthIndexEntry = fingerStrength[1];
  const thumbStrengthMap: any = new Map([
    [50, 100],
    [100, 150],
    [150, 200],
    [300, 350],
    [500, 550],
    [700, 750]
  ]);
  await bluetooth.writeWeb(
    Commands.kFrameTypeFingerCurrentThreshold,
    [0, thumbStrengthMap.get(strengthIndex)],
    mode
  );
  await bluetooth.writeWeb(Commands.kFrameTypeFingerCurrentThreshold, [1, strengthIndex], mode);
};

export const postJointTargetPosition = async (finger, position, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetJointTargetPosition, [finger, position], mode);
};

export const postFreezeMode = async (status: statusTypeFreeze, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kFrameTypeFreezeMode, status, mode);
};

export const postEmgGains = async (emgGains: number[], mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetSignalGains, emgGains, mode);
};

export const postGripFollowing = async (gripFollowing: followingGripEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetGripFollowing, gripFollowing, mode);
};

export const postAppReceivedProcedure = async (receivedProcedureNumber, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kAppReceivedFrameCallback, [receivedProcedureNumber], mode);
};

export const postPulseTimings = async (pulseTimings: pulseTimingsEntry, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kPulseTimings, pulseTimings, mode);
};

export const postSingleElectrodeMode = async (
  electrodeMode: singleElectrodeModeEntry,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kSingleElectrodeSelectedMode, electrodeMode, mode);
};

export const postSingleElectrodeSettings = async (
  singleElectrodeSettings: singleElectrodeModeSettingsEntry,
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(
    Commands.kSingleElectrodeModeFastOpenSlowCloseSettings,
    singleElectrodeSettings,
    mode
  );
};

export const postCoContractionTimings = async (
  coContractionTimings: number[],
  mode: bluetoothMode
) => {
  await bluetooth.writeWeb(Commands.kCoContractionTimings, coContractionTimings, mode);
};

export const postGeneralHandSettings = async (
  generalHandSettings: generalHandSettingsEntry,
  mode: bluetoothMode
) => {
  // Fill 23 unused bytes
  const fill = new Array(23).fill(0);
  const generalHandSettingsPayload = [...generalHandSettings, ...fill];
  await bluetooth.writeWeb(Commands.kGeneralHandSettings, generalHandSettingsPayload, mode);
};

export const postFreezeModeEmg = async (
  freezeModeEmg: number[],
  fwVersion,
  inputSite: InputSites,
  mode: bluetoothMode
) => {
  let newFreezeModeEmg: number[] = [...freezeModeEmg];

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  if (fwVersion?.[1] >= 2 && inputSite === InputSites.kSingleElectrode && freezeModeEmg[2] !== 0) {
    newFreezeModeEmg = [freezeModeEmg[0], freezeModeEmg[1], 3, 3];
  }

  if (fwVersion?.[1] < 2 && freezeModeEmg[2] !== 0) {
    newFreezeModeEmg = [freezeModeEmg[0], freezeModeEmg[1], 1, 2];
  }

  // Fill 7 unused bytes
  const freezeModeSettings = [...newFreezeModeEmg, 0, 0, 0, 0, 0, 0, 0];
  await bluetooth.writeWeb(Commands.kFreezeSignalDetectorSettings, freezeModeSettings, mode);
};

export const postActiveMode = async (activeMode: number, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSelectActiveMode, [activeMode], mode);
};

export const postCommunicateMode = async (communicateMode: number, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSelectUsageMode, [communicateMode], mode);
};

export const postSaveSettings = async (mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSaveSettings, [], mode);
};

export const postBuzzingVolumeSettings = async (
  buzzingSettings: buzzingVolumeSettingsEntry,
  mode: bluetoothMode
) => {
  // Fill 10 unused bytes
  const buzzingVolume = [...buzzingSettings, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  await bluetooth.writeWeb(Commands.kBuzzingVolumeSettings, buzzingVolume, mode);
};

export const postUserFeedbackType = async (type: userFeedbackType, mode: bluetoothMode) => {
  await bluetooth.writeWeb(Commands.kSetUserFeedbackType, type, mode);
};

export const postFreezeModeEmgSettings = async (
  freezeModeEmgSettings: freezeModeEmgSettingsEntry,
  mode: bluetoothMode
) => {
  const thresholdOpening = freezeModeEmgSettings[0];
  const thresholdClosing = freezeModeEmgSettings[1];
  const stage1HoldOpenTime = freezeModeEmgSettings[2];
  const stage2HoldOpenTime = freezeModeEmgSettings[3];

  const filler = [0, 0, 0, 0, 0, 0];

  await bluetooth.writeWeb(
    Commands.kLongDoubleStageHoldSettings,
    [1, thresholdOpening, stage1HoldOpenTime, stage2HoldOpenTime, ...filler],
    mode
  );
  await delay(100);
  await bluetooth.writeWeb(
    Commands.kLongDoubleStageHoldSettings,
    [0, thresholdClosing, stage1HoldOpenTime, stage2HoldOpenTime, ...filler],
    mode
  );
  await delay(100);
  await bluetooth.writeWeb(
    Commands.kLongDoubleStageHoldSettings,
    [2, thresholdOpening, stage1HoldOpenTime, stage2HoldOpenTime, ...filler],
    mode
  );
};

export const postEmergencyBatterySettings = async (
  emergencyBatterySettings: emergencyBatterySettingsEntry,
  mode: bluetoothMode
) => {
  // Fill 10 unused bytes
  const emergencyBatterySettingsFilled = [...emergencyBatterySettings, 0, 0, 0, 0, 0, 0, 0];
  await bluetooth.writeWeb(
    Commands.kLowBatteryEmergencySettings,
    emergencyBatterySettingsFilled,
    mode
  );
};

export const sendFingersConfigHelper = async (
  grip,
  valuesInitial,
  valuesLimit,
  bluetoothMode: bluetoothMode
) => {
  await postInitialGripPositions(grip, valuesInitial, bluetoothMode);
  await postFingerLimits(grip, valuesLimit, bluetoothMode);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (
  gripsPositions: gripsPositionsEntry,
  bluetoothMode: bluetoothMode
) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(
        grip,
        gripPositions.initial,
        gripPositions.limit,
        bluetoothMode
      );
    }
  }
};

const sanitizeGripsSequential = (gripSequentialConfigToSend) => {
  const sanitizedGripPairConfig = [
    ...gripSequentialConfigToSend.slice(0, 5),
    255,
    ...gripSequentialConfigToSend.slice(6, 11),
    255
  ];
  return sanitizedGripPairConfig;
};

const gripsToDownload = [
  Grips.kGripPower,
  Grips.kGripHook,
  Grips.kGripFingerPoint,
  Grips.kGripMouse,
  Grips.kGripKey,
  Grips.kGripTrigger,
  Grips.kGripTripodClosed,
  Grips.kGripPrecisionOpen,
  Grips.kGripCamera,
  Grips.kGripRestOpp,
  Grips.kGripRestNopp,
  Grips.kGripPrecisionClosed,
  Grips.kGripTripodOpen,
  Grips.kGripFingerPointOpen
];

const getGripsLimits = async (mode) => {
  const gripsPositionsObject = {};
  let iterator = 0;
  for (const grip of gripsToDownload) {
    const gripPositions = await getGripPositions(grip, mode);
    const gripLimitPositions = await getGripLimitPositions(grip, mode);

    if (gripPositions && gripLimitPositions) {
      gripsPositionsObject[gripPositions[0]] = {
        initial: [
          gripPositions[1],
          gripPositions[2],
          gripPositions[3],
          gripPositions[4],
          gripPositions[5]
        ],
        limit: [
          gripLimitPositions[1],
          gripLimitPositions[2],
          gripLimitPositions[3],
          gripLimitPositions[4],
          gripLimitPositions[5]
        ]
      };
    }
    iterator += 1;
  }
  return gripsPositionsObject;
};

const ConfigToReceiveFunctionMapping = {
  gripSwitchingMode: async (...args: [bluetoothMode, ...any]) => {
    const controlConfig = await getControlConfig(args[0]);
    return [controlConfig[4]];
  },
  speedControlStrategy: async (...args: [bluetoothMode, ...any]) => {
    const controlConfig = await getControlConfig(args[0]);
    return [controlConfig[3]];
  },
  controlMode: async (...args: [bluetoothMode, ...any]) => {
    const controlConfig = await getControlConfig(args[0]);
    return [controlConfig[2]];
  },
  inputSite: async (...args: [bluetoothMode, ...any]) => {
    const controlConfig = await getControlConfig(args[0]);
    return [controlConfig[1]];
  },
  inputDevice: async (...args: [bluetoothMode, ...any]) => {
    const controlConfig = await getControlConfig(args[0]);
    return [controlConfig[0]];
  },
  gripsPositions: (...args: [bluetoothMode, ...any]) => getGripsLimits(args[0]),
  fingerStrength: (...args: [bluetoothMode, ...any]) => getFingerStrength(args[0]),
  generalHandSettings: (...args: [bluetoothMode, ...any]) => getGeneralHandSettings(args[0]),
  batteryBeep: (...args: [bluetoothMode, ...any]) => getBatteryBeep(args[0]),
  gripPairsConfig: (...args: [bluetoothMode, ...any]) => getGripsPairsConfig(args[0]),
  gripSequentialConfig: async (...args: [bluetoothMode, ...any]) => {
    const gripSequentialConfig = await getGripsSequentialConfig(args[0]);
    let gripSequentialConfigSanitized;
    if (gripSequentialConfig) {
      gripSequentialConfigSanitized = [
        ...gripSequentialConfig.slice(0, 5),
        255,
        ...gripSequentialConfig.slice(6, 11),
        255
      ];
    }
    return gripSequentialConfigSanitized;
  },
  emgThresholds: (...args: [bluetoothMode, ...any]) => getEmgThresholds(args[0]),
  autoGrasp: async (...args: [bluetoothMode, ...any]) => {
    const autoGrasp = await getAutoGrasp(args[0]);
    if (autoGrasp?.[1] > 100) autoGrasp[1] = 100;
    return autoGrasp;
  },
  emgSpike: (...args: [bluetoothMode, ...any]) => getEmgSpike(args[0]),
  holdOpen: (...args: [bluetoothMode, ...any]) => getHoldOpen(args[0]),
  softGrip: (...args: [bluetoothMode, ...any]) => getSoftGrip(args[0]),
  emgGains: (...args: [bluetoothMode, ...any]) => getEmgGains(args[0]),
  pulseTimings: (...args: [bluetoothMode, ...any]) => getPulseTimings(args[0]),
  singleElectrodeMode: (...args: [bluetoothMode, ...any]) => getSingleElectrodeMode(args[0]),
  singleElectrodeModeSettings: (...args: [bluetoothMode, ...any]) =>
    getSingleElectrodeModeSettings(args[0]),
  coContractionTimings: (...args: [bluetoothMode, ...any]) => getCoContractionTimings(args[0]),
  freezeModeEmg: (...args: [bluetoothMode, ...any]) => getFreezeModeEmg(args[0]),
  buzzingVolumeSettings: (...args: [bluetoothMode, ...any]) => getBuzzingVolumeSettings(args[0]),
  userFeedbackType: (...args: [bluetoothMode, ...any]) => getUserFeedbackType(args[0]),
  freezeModeEmgSettings: (...args: [bluetoothMode, ...any]) => getFreezeModeEmgSettings(args[0]),
  followingGrip: (...args: [bluetoothMode, ...any]) => getFollowingGrip(args[0]),
  emergencyBatterySettings: (...args: [bluetoothMode, ...any]) =>
    getEmergencyBatterySettings(args[0])
};

interface configurationType {
  name: keyof DeviceConfigTemplate;
  arguments: [bluetoothMode, ...any];
}

export const getDeviceConfigurations = async (
  configurations: Array<configurationType>
): Promise<{ [key in keyof DeviceConfigTemplate]: any }> => {
  const config: any = {};
  for (let index = 0; index < configurations.length; index += 1) {
    const propertyName = configurations[index].name;
    const propertyFunctionArguments = configurations[index].arguments;
    const configPropertyFunction = ConfigToReceiveFunctionMapping[propertyName];
    config[propertyName] = await configPropertyFunction(...propertyFunctionArguments);
  }
  return config;
};

export const ConfigToSendFunctionMapping = {
  gripPairsConfig: (gripPairsConfig: gripPairsConfigEntry, mode: bluetoothMode) =>
    postGripPairs(gripPairsConfig, mode),
  inputSite: (inputSite, mode: bluetoothMode) => postInputSite(inputSite, mode),
  inputDevice: (inputDevice: inputDevicesEntry, mode: bluetoothMode) =>
    postInputOption(inputDevice, mode),
  controlMode: (controlMode, mode: bluetoothMode) => postControlMode(controlMode, mode),
  speedControlStrategy: (speedControlStrategy, mode: bluetoothMode) =>
    postSpeedControlStrategy(speedControlStrategy, mode),
  gripSwitchingMode: (gripSwitchingMode, mode: bluetoothMode) =>
    postGripSwitchingModes(gripSwitchingMode, mode),
  gripSequentialConfig: (gripSequentialConfig: gripSequentialConfigEntry, mode: bluetoothMode) =>
    postGripSequentialConfig(
      // @ts-ignore
      sanitizeGripsSequential(gripSequentialConfig),
      mode
    ),
  emgThresholds: (emgThresholds: emgThresholdsEntry, mode: bluetoothMode) =>
    postEmgThresholds(emgThresholds, mode),
  gripsPositions: (gripsPositions: gripsPositionsEntry, mode: bluetoothMode) =>
    sendAllFingersHelper(gripsPositions, mode),
  interval: (interval: intervalEntry, mode: bluetoothMode) => postInterval(interval, mode),
  fingerStrength: (fingerStrength: fingerStrengthEntry, mode: bluetoothMode) =>
    postFingerStrength(fingerStrength, mode),
  autoGrasp: (autoGrasp: autoGraspEntry, mode: bluetoothMode) => postAutoGrasp(autoGrasp, mode),
  batteryBeep: (batteryBeep: batteryBeepEntry, mode: bluetoothMode) =>
    postBatteryBeep(batteryBeep, mode),
  emgSpike: (emgSpike: emgSpikeEntry, mode: bluetoothMode) =>
    postEmgSpikeCancelling(emgSpike, mode),
  holdOpen: (holdOpen: holdOpenEntry, mode: bluetoothMode) => postHoldOpen(holdOpen, mode),
  softGrip: (softGrip: softGripEntry, mode: bluetoothMode) => postSoftGrip(softGrip, mode),
  emgGains: (emgGains: emgGainsEntry, mode: bluetoothMode) => postEmgGains(emgGains, mode),
  pulseTimings: (pulseTimings: pulseTimingsEntry, mode: bluetoothMode) =>
    postPulseTimings(pulseTimings, mode),
  singleElectrodeMode: (electrodeMode: singleElectrodeModeEntry, mode: bluetoothMode) =>
    postSingleElectrodeMode(electrodeMode, mode),
  singleElectrodeModeSettings: (
    singleElectrodeSettings: singleElectrodeModeSettingsEntry,
    mode: bluetoothMode
  ) => postSingleElectrodeSettings(singleElectrodeSettings, mode),
  coContractionTimings: (coContractionTimings: coContractionTimingsEntry, mode: bluetoothMode) =>
    postCoContractionTimings(coContractionTimings, mode),
  generalHandSettings: (generalHandSettings: generalHandSettingsEntry, mode: bluetoothMode) =>
    postGeneralHandSettings(generalHandSettings, mode),
  freezeModeEmg: (
    freezeModeEmg: freezeModeEmgEntry,
    fwVersion: number,
    inputSite: InputSites,
    mode: bluetoothMode
  ) => postFreezeModeEmg(freezeModeEmg, fwVersion, inputSite, mode),
  buzzingVolumeSettings: (settings: buzzingVolumeSettingsEntry, mode: bluetoothMode) =>
    postBuzzingVolumeSettings(settings, mode),
  userFeedbackType: (type: userFeedbackType, mode: bluetoothMode) =>
    postUserFeedbackType(type, mode),
  freezeModeEmgSettings: (type: freezeModeEmgSettingsEntry, mode: bluetoothMode) =>
    postFreezeModeEmgSettings(type, mode),
  followingGrip: (type: followingGripEntry, mode: bluetoothMode) => postGripFollowing(type, mode),
  emergencyBatterySettings: (
    emergencyBatterySettings: emergencyBatterySettingsEntry,
    mode: bluetoothMode
  ) => postEmergencyBatterySettings(emergencyBatterySettings, mode)
};
