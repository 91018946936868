/* eslint-disable no-return-assign */
import Ably from 'ably';

let ably: any = null;
let ablyRest: any = null;

export function ablyClient(clinicianUUID: string | null = null) {
  return (
    ably ||
    (ably = new Ably.Realtime.Promise({
      key: process.env.REACT_APP_ABLY_API_KEY || '',
      clientId: clinicianUUID ?? 'clinicianGuess',
      transportParams: { remainPresentFor: 1000 },
      recover(_, cb) {
        cb(true);
      }
    }))
  );
}

export function ablyRestClient(clinicianUUID: string | null = null) {
  return (
    ablyRest ||
    (ablyRest = new Ably.Rest.Promise({
      key: process.env.REACT_APP_ABLY_API_KEY || '',
      clientId: clinicianUUID ?? 'clinicianGuess'
    }))
  );
}
