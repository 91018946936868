/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { Commands } from 'bluetooth/Bluetooth/Defines';
import TelemetryParser from 'utils/TelemetryParser';
import BluetoothController from './bluetoothInterface';
import BluetoothWebControllerLE from './bluetoothLE';
import BluetoothWebControllerSerial from './bluetoothSerial';
import TelemetryController from './telemetryController';

const bluetoothLE = new BluetoothWebControllerLE();
const bluetoothSerial = new BluetoothWebControllerSerial();

class BluetoothWebController implements BluetoothController {
  static _instance: any;
  connected: boolean = false;
  telemetryEnabled: boolean = false;
  device: any;
  controller: any;

  constructor() {
    if (BluetoothWebController._instance) {
      return BluetoothWebController._instance;
    }
    BluetoothWebController._instance = this;
    this.connected = false;
  }

  async initiateBluetooth(mode = 'classic', bluetoothId: string | null = null): Promise<boolean> {
    let result;
    if (mode === 'classic') {
      result = await bluetoothSerial.initiateBluetooth();
    } else {
      const resultble = await bluetoothLE.initiateBluetooth(bluetoothId);
      result = resultble.status;
      this.device = resultble.device;
    }
    if (result) {
      this.connected = true;
    }
    this.controller = new AbortController();
    return result;
  }

  async disconnectBluetooth(mode = 'classic') {
    TelemetryController.telemetryOff(mode);
    let status = false;
    if (this.controller) {
      this.controller.abort();
    }
    if (mode === 'classic') {
      status = await bluetoothSerial.disconnectBluetooth();
    } else {
      status = await bluetoothLE.disconnectBluetooth();
    }
    this.connected = false;
    return status;
  }

  async readWeb(mode = 'classic') {
    // @ts-ignore
    if (mode === 'classic') {
      bluetoothSerial.readWeb();
    } else {
      bluetoothLE.readWeb();
    }
    return true;
  }

  async writeWeb(command, commandData: number[], mode = 'classic') {
    let status;
    if (mode === 'classic') {
      status = await bluetoothSerial.writeWeb(command, commandData);
    } else {
      status = await bluetoothLE.writeWeb(command, commandData);
    }
    return status;
  }

  async queryResponseCommand(command, commandData, commandAwaited, mode = 'classic'): Promise<any> {
    let response;
    if (mode === 'classic') {
      response = await bluetoothSerial.queryResponseCommand(command, commandData, commandAwaited);
    } else {
      response = await bluetoothLE.queryResponseCommand(command, commandData, commandAwaited);
    }
    return response;
  }
}

export default BluetoothWebController;
