/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'reducers/helpers/initialState';
import { singleElectrodeModeSettingsEntry } from 'consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'reducers/configStore';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../Sliders';

const SingleElectrodeModeSettings = ({
  addHistoryProsthesis,
  singleElectrodeModeSettings,
  disable,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  singleElectrodeModeSettings: singleElectrodeModeSettingsEntry;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [singleElectrodeSettingsLocal, setSingleElectrodeModeSettingsLocal] = useState<any>(
    initialState.config.singleElectrodeModeSettings
  );

  const handleFastRisingSliders = (value, sliderNumber) => {
    let type: 'startPointSignalThreshold' | 'windowTime' | 'holdOpenTimeShort' | 'holdOpenTimeLong';
    let payload;
    switch (sliderNumber) {
      case 0:
        type = 'startPointSignalThreshold';
        payload = [
          value,
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
        break;
      case 1:
        type = 'windowTime';
        payload = [
          singleElectrodeModeSettings[0],
          value,
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
        break;
      case 2:
        type = 'holdOpenTimeShort';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          value,
          singleElectrodeModeSettings[3]
        ];
        break;
      case 3:
        type = 'holdOpenTimeLong';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          value
        ];
        break;
      default:
        type = 'holdOpenTimeLong';
        payload = [
          singleElectrodeModeSettings[0],
          singleElectrodeModeSettings[1],
          singleElectrodeModeSettings[2],
          singleElectrodeModeSettings[3]
        ];
    }
    addHistoryProsthesis(setConfigProperty('singleElectrodeModeSettings', payload));
  };

  useEffect(() => {
    if (singleElectrodeModeSettings) {
      setSingleElectrodeModeSettingsLocal(singleElectrodeModeSettings);
    }
  }, [singleElectrodeModeSettings]);

  const SingleElectrodeModeSettings = useMemo(
    () => (
      <MultiSlider
        header='Slope options'
        sliderValues={singleElectrodeSettingsLocal}
        disabled={disable}
        limits={[
          { min: 1, max: 100 },
          { min: 50, max: 1000 },
          { min: 250, max: 5000 },
          { min: 300, max: 5000 }
        ]}
        handleChange={(...args) =>
          setSingleElectrodeModeSettingsLocal((prev) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>Start point signal threshold: {singleElectrodeSettingsLocal?.[0]}</p>,
          <p>Window time: {parseNumber(singleElectrodeSettingsLocal?.[1])} s</p>,
          <p>Primary hold open time: {parseNumber(singleElectrodeSettingsLocal?.[2])} s</p>,
          <p>Secondary hold open time: {parseNumber(singleElectrodeSettingsLocal?.[3])} s</p>
        ]}
        handleOnAfterChange={(...args) => handleFastRisingSliders(args[1], args[2])}
        description='Sets slope settings'
      />
    ),
    [singleElectrodeModeSettings, singleElectrodeSettingsLocal, disable]
  );

  return SingleElectrodeModeSettings;
};

export default SingleElectrodeModeSettings;
