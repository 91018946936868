/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent } from '@mui/material';
import platform from 'platform';
import useCompatibilities from 'hooks/useCompatibilities';
import Divider from 'components/atoms/Divider/Divider';
import BootloaderController from 'bluetooth-handler/bootloaderController';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceUpdate } from 'hooks/api/useDevice';
import { useSettingsStore } from 'reducers/settingsStore';
import { ReactComponent as Check } from 'assets/check-solid.svg';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { HeaderM, TextS } from 'components/atoms/Typography/Typography';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { ALLOW_MANUAL_UPDATE, CURRENT_BOOTLOADER_VERSION, FETCHING_STATES } from 'consts/consts';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useUiStore } from 'reducers/uiStore';
import ModalBase from './ModalBase';

const Header1 = styled.h3`
  ${HeaderM};
`;

const Paragraph = styled.p`
  ${TextS}
`;

const Regular = styled.span`
  font-weight: 400;
`;

const StyledIcon = css`
  width: 20px;
  margin-right: 5px;

  @media (max-height: 924px) {
    width: 15px;
  }
`;

export const StyledCheck = styled(Check)`
  color: #16c60c;
  ${StyledIcon};
`;

export const StyledTimes = styled(Times)`
  color: #ee0000;
  ${StyledIcon};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-right: 5px;

  &::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colorPrimary};
    border-color: ${({ theme }) => theme.colorPrimary} transparent
      ${({ theme }) => theme.colorPrimary} transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

const WarningMessage = styled.ul`
  font-size: 16px;
  font-weight: 700;
  color: red;
  list-style: none;
`;

const Bootloader = new BootloaderController();

const UpdateStates = {
  notInititated: 0,
  fetchSuccesful: 1,
  failedToFetch: 2,
  inProgress: 3,
  succesful: 4,
  failed: 5,
  inProgressFix: 6,
  fixSuccessful: 7,
  fixFailed: 8
};

const FirmwareModal = ({ handleClose }) => {
  const [message, setMessage] = useState(null);
  const [currentPart, setCurrentPart] = useState(0);
  const { updateStatus, maxParts, setItemSettingsStore } = useSettingsStore((state) => ({
    updateStatus: state.updateStatus,
    maxParts: state.maxParts,
    setItemSettingsStore: state.setItemSettingsStore
  }));
  const { bluetoothConnect } = useBluetooth();
  const { connectionState, firmwareUpdateState, setItemUiStore } = useUiStore((state) => ({
    connectionState: state.connectionState,
    firmwareUpdateState: state.firmwareUpdateState,
    setItemUiStore: state.setItemUiStore
  }));
  const { deviceId, deviceConnected, supported, versions, pcbVersion } = useDeviceInfoStore(
    (state) => ({
      deviceId: state.deviceId,
      deviceConnected: state.connected,
      supported: state.supported,
      versions: state.versions,
      pcbVersion: state.pcbVersion
    })
  );
  const {
    availableFirmwares,
    isFirmwareUpdateAvailable,
    isFirmwareUpdateNeeded,
    deviceFirmwareUnknown
  } = useCompatibilities();
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { disconnectDevice, bluetoothMode, getInitialConfigAPI } = useConfigStore((state) => ({
    disconnectDevice: state.disconnectDevice,
    bluetoothMode: state.bluetoothMode,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const recentFirmware = `${availableFirmwares?.[0]?.[`file_firmware_v${pcbVersion}`]}`;
  const recentBootloader = `${availableFirmwares?.[0]?.[`file_bootloader_v${pcbVersion}`]}`;
  const isMacOs = platform.os.family === 'OS X';

  const updateFirmwareWeb = async (firmwareURL, duringFix = false) => {
    try {
      const request = await fetch(firmwareURL);
      if (request.ok) {
        const bytes = await request.arrayBuffer();
        Bootloader.updateBootloaderParts(bytes);
        const max = Bootloader.getMaxParts();
        setCurrentPart(0);
        setItemSettingsStore('maxParts', max);
        return true;
      }
      throw new Error('Bad response status');
    } catch (err) {
      setItemSettingsStore('updateStatus', UpdateStates.failedToFetch);
      return false;
    }
  };

  const update = async () => {
    setItemSettingsStore('updateStatus', UpdateStates.inProgress);
    const status = await Bootloader.updateFirmwareNew(bluetoothMode);
    setItemSettingsStore(
      'updateStatus',
      status === 2 || status === false ? UpdateStates.succesful : UpdateStates.failed
    );

    await disconnectDevice();

    // If update is successful, set isAfterUpdate and copy current settings
    if (status === 2 || status === false) {
      await updateDevice({ deviceId, data: { firmware_version_id: availableFirmwares[0].id } });
      await getInitialConfigAPI();
      toast(`After update perform all calibration procedures`, {
        duration: 90000,
        icon: '⚠️'
      });
    }

    if (isMacOs) return;

    // Auto-reconnect
    await delay(5000);
    bluetoothConnect();
  };

  const updateWithFix = async (firmwareFileUrl, bootloaderFileUrl) => {
    setItemUiStore('firmwareUpdateState', FETCHING_STATES.loading);
    try {
      await getInitialConfigAPI();
      const fetchStatus = await updateFirmwareWeb(firmwareFileUrl);
      if (fetchStatus) {
        let statusRecent;
        setItemSettingsStore('updateStatus', UpdateStates.inProgress);
        const bootloaderVersion = await Bootloader.checkBootloaderVersion(bluetoothMode);
        if (bootloaderVersion < CURRENT_BOOTLOADER_VERSION) {
          setItemSettingsStore('updateStatus', UpdateStates.inProgressFix);
          await updateFirmwareWeb(bootloaderFileUrl, true);
          const statusFix = await Bootloader.updateFirmware(bluetoothMode);
          setItemSettingsStore(
            'updateStatus',
            statusFix === 2 ? UpdateStates.fixSuccessful : UpdateStates.fixFailed
          );
        }
        if (updateStatus !== UpdateStates.fixFailed) {
          await delay(2000);
          await updateFirmwareWeb(firmwareFileUrl);
          statusRecent = await Bootloader.updateFirmwareNew(bluetoothMode);
          setItemSettingsStore(
            'updateStatus',
            statusRecent === 2 || statusRecent === false
              ? UpdateStates.succesful
              : UpdateStates.failed
          );
        }

        await disconnectDevice();

        // If update is successful, set isAfterUpdate and copy current settings
        if (statusRecent === 2 || statusRecent === false) {
          await updateDevice({ deviceId, data: { firmware_version_id: availableFirmwares[0].id } });
          await getInitialConfigAPI();
          toast(`After update perform all calibration procedures`, {
            duration: 90000,
            icon: '⚠️'
          });
        }
        setItemUiStore('firmwareUpdateState', FETCHING_STATES.successful);

        if (isMacOs) return;

        // Auto-reconnect
        await delay(5000);
        bluetoothConnect();
        return;
      }
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
    } catch (e) {
      console.log(e);
      setItemUiStore('firmwareUpdateState', FETCHING_STATES.failed);
    }
  };

  const loadFile = async () => {
    const firmware = document.querySelector('#firmwareInput').files[0];
    await updateFirmwareWeb(window.URL.createObjectURL(firmware));
    update();
  };

  useEffect(() => {
    switch (updateStatus) {
      case UpdateStates.notInititated:
        setMessage(null);
        break;
      case UpdateStates.fetchSuccesful:
        setMessage('Files ready, click update to proceed');
        break;
      case UpdateStates.failedToFetch:
        setMessage('Failed to download files');
        break;
      case UpdateStates.inProgress:
        setMessage('Update in progress');
        break;
      case UpdateStates.succesful:
        setMessage(`Update successful, please ${isMacOs ? 'reconnect' : 'wait for reconnection'}`);
        break;
      case UpdateStates.failed:
        setMessage('Update failed, please wait for reconnection');
        break;
      case UpdateStates.inProgressFix:
        setMessage('Bootloader update in progress');
        break;
      case UpdateStates.fixSuccessful:
        setMessage('Bootloader updated successfully, wait for the firmware update to finish');
        break;
      case UpdateStates.fixFailed:
        setMessage('Bootloader update failed');
        break;
      default:
        break;
    }
  }, [updateStatus]);

  const updateCurrentPart = (data) => {
    setCurrentPart(data.detail);
  };

  function handleLeave(event) {
    event.returnValue = 'Sure you want to leave?';
  }

  useEffect(() => {
    history.push('/choose-grips');

    window.addEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
    window.addEventListener('beforeunload', handleLeave);

    return function clean() {
      window.removeEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
      window.removeEventListener(`beforeunload`, handleLeave);
    };
  }, []);

  const isInProgress =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.succesful ||
    updateStatus === UpdateStates.failed ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const isInProgressStrict =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const updateStatesSucessfull =
    updateStatus === UpdateStates.fetchSuccesful || updateStatus === UpdateStates.succesful;

  const updateStatesFailed =
    updateStatus === UpdateStates.failedToFetch || updateStatus === UpdateStates.failed;

  const firmwareVersionCurrentParsed =
    versions?.current?.[1] !== undefined &&
    versions?.current?.[4] !== undefined &&
    versions?.current?.[7] !== undefined
      ? `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`
      : '';
  const inBootloaderMode = deviceConnected && !versions?.current?.[1];

  const allowUpdate =
    isFirmwareUpdateAvailable ||
    isFirmwareUpdateNeeded ||
    inBootloaderMode ||
    deviceFirmwareUnknown;

  return (
    <ModalBase
      header='Update firmware'
      hideCloseButton={isInProgress || !supported || inBootloaderMode}
      handleClick={isInProgressStrict || !supported ? () => true : handleClose}
      allowBackDropClose={false}>
      <DialogContent sx={{ width: '450px' }}>
        {!inBootloaderMode && (
          <Header1>
            Device version: <Regular>{firmwareVersionCurrentParsed}</Regular>
          </Header1>
        )}
        <>
          <Header1>
            {allowUpdate ? (
              <>
                Newest version: <Regular>{availableFirmwares?.[0]?.name}</Regular>
              </>
            ) : (
              <div style={{ color: 'green' }}>Your firmware is up to date</div>
            )}
          </Header1>
          <Divider margin='20px' />
        </>
        {message && (
          <>
            <InfoWrapper>
              {updateStatesSucessfull && <StyledCheck />}
              {updateStatus === UpdateStates.inProgress && <Loader />}
              {updateStatesFailed && <StyledTimes />}
              <Paragraph>{message}</Paragraph>
            </InfoWrapper>
          </>
        )}
        {(updateStatus === UpdateStates.notInititated ||
          updateStatus === UpdateStates.failedToFetch) && (
          <>
            {allowUpdate &&
              (pcbVersion ? (
                <>
                  <Divider margin='10px' />
                  <LoadingButton
                    onClick={() => updateWithFix(recentFirmware, recentBootloader)}
                    fullWidth
                    loading={firmwareUpdateState === FETCHING_STATES.loading}>
                    <span>Update</span>
                  </LoadingButton>
                </>
              ) : (
                <span style={{ color: 'red' }}>
                  PCB version can&rsquo;t be determined. Firmware update can&rsquo;t be performed.
                  Please contact Aether support.
                </span>
              ))}
            {(ALLOW_MANUAL_UPDATE || inBootloaderMode) && (
              <>
                <Divider margin='10px' />
                <input
                  type='file'
                  id='firmwareInput'
                  accept='.bin'
                  onChange={loadFile}
                  style={{ display: 'none' }}
                />
                <Button onClick={() => document.querySelector('#firmwareInput').click()} fullWidth>
                  Update from file
                </Button>
              </>
            )}
          </>
        )}
        {!supported && (
          <>
            <Divider margin='10px' />
            <span style={{ color: 'red' }}>
              Version 1.8.0 is needed to use ADP, please update firmware.
            </span>
          </>
        )}
        {isInProgress && (
          <>
            <Divider margin='10px' />
            <ProgressBar
              completed={maxParts ? Math.ceil((currentPart / maxParts) * 100) : 0}
              bgColor='#3788B3'
              transitionDuration='0.3s'
            />
            <Divider margin='10px' />
            {isInProgressStrict && (
              <span style={{ color: 'red' }}>
                Do not turn off the device, or close the application!
              </span>
            )}
          </>
        )}
        {!deviceConnected && isMacOs && (
          <>
            <Divider margin='10px' />
            <LoadingButton
              loading={connectionState === FETCHING_STATES.loading}
              fullWidth
              onClick={bluetoothConnect}>
              <span>Reconnect</span>
            </LoadingButton>
          </>
        )}
      </DialogContent>
    </ModalBase>
  );
};

FirmwareModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default FirmwareModal;
