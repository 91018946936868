/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type StoreState = {
  ticket: any;
  configUrl: string | null;
  replayData: any;
  enabled: boolean;
  setItemReplayStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) => void;
  setReplaySettings: ({ ticket, configUrl }) => void;
  setReplayData: (data) => void;
  resetToDefault: () => void;
};

const initialState = {
  ticket: null,
  configUrl: null,
  replayData: null,
  enabled: false
};

const store = (set) => ({
  ...initialState,
  setItemReplayStore: <T extends keyof StoreState>(property: T, value: StoreState[T]) =>
    set({ [`${property}`]: value }),
  setReplaySettings: ({ ticket, configUrl }) => set({ ticket, configUrl }),
  setReplayData: ({ data }) => set({ replayData: data }),
  resetToDefault: () => set({ ticket: null, configUrl: null, replayData: null, enabled: false })
});

export const useReplayStore = create<StoreState>()(
  // @ts-ignore
  devtools(persist(store, { name: 'replay' }), { name: 'Replay' })
);
