import * as Sentry from '@sentry/react';
import React from 'react';
import { AxiosError } from 'axios';
import { map } from 'lodash';
import { ToastOptions } from 'react-hot-toast';
import {
  renderNotification,
  ApiErrorNotification
} from 'components/atoms/ErrorNotification/ApiErrorNotification';

type ErrorKey = {
  [prop: string]: string[];
};

interface ApiError {
  message: string;
  errors: ErrorKey[];
  accumulatedErrors: string[];
}

export const handleStatusErrors = (error: any) => {
  const code = error?.request?.status;
  const defaultErrorMessage = `Something went wrong. Please refresh the page or try again later.`;
  let message = defaultErrorMessage;

  if (code >= 500 && code <= 599) {
    Sentry.captureException(error);

    // eslint-disable-next-line default-case
    switch (code) {
      case 503:
        message =
          "Connection cannot be established. This may be due to a firewall block on your computer. Please contact your organization's IT department.";
        break;
    }
  }

  return message;
};

export const parseApiError = (error: AxiosError): ApiError => {
  const getAllMessagesFromError = (): string[] => {
    const errors: string[] = [];
    map(error.response?.data?.errors || {}, (value: string[]) => {
      Array.from(value).forEach((item: string) => {
        errors.push(item);
      });
    });
    return errors;
  };
  return {
    message: error.response?.data?.message || handleStatusErrors(error),
    errors: error.response?.data,
    accumulatedErrors: getAllMessagesFromError()
  };
};
export function errorNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification()(title, description, options, children);
}
export function errorApiNotification(error: any, fallbackErrorMessage: string | null = null) {
  if (!error?.isAxiosError && fallbackErrorMessage) {
    errorNotification(fallbackErrorMessage);
  }
  const parsedError = parseApiError(error);
  if (parsedError.accumulatedErrors) {
    return errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
  }
  return errorNotification(parsedError.message, '', { id: parsedError.message });
}

export function errorApiNotificationParse(error: any) {
  if (!error) return { message: 'Something went wrong', errors: null };

  const errors = parseApiError(error);
  return { message: errors.message, errors: errors.accumulatedErrors };
}

export const isNetworkError = (error: any) =>
  error.request?.status && (error?.request?.status < 200 || error?.request?.status >= 300);
