/* eslint-disable no-unused-vars */
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import {
  closeVideoSession,
  getVideoSession,
  initVideoSession,
  refreshMeetingToken
} from 'api/liveSession/liveSession';
import { VideoMeetingEntry } from 'api/liveSession/liveSession.types';
import { FETCHING_STATES } from 'consts/consts';
import { useUiStore } from './uiStore';

export enum ViewModes {
  standard,
  standardEMG,
  minimal,
  minimalEMG
}

export const viewModesStandard = ViewModes.standard || ViewModes.standardEMG;

type MeetingState = {
  meetingStatus: boolean;
  meetingJwt: string | null;
  meetingJwtPatient: string | null;
  viewMode: ViewModes;
  roomId: string | null;
  videoSessionId: number | null;
  setItemMeeting: <T extends keyof MeetingState>(property: T, value: MeetingState[T]) => void;
  initVideoMeeting: ({
    amputeeId
  }: {
    amputeeId: number;
  }) => Promise<VideoMeetingEntry> | Promise<false>;
  checkVideoMeeting: ({
    amputeeId
  }: {
    amputeeId: number;
  }) => Promise<VideoMeetingEntry> | Promise<false>;
  resetVideoMeeting: () => void;
};

const initialState = {
  meetingStatus: false,
  viewMode: ViewModes.standard,
  roomId: null,
  meetingJwt: null,
  meetingJwtPatient: null,
  videoSessionId: null
};

const store = (set, get) => ({
  ...initialState,
  setItemMeeting: <T extends keyof MeetingState>(property: T, value: MeetingState[T]) =>
    set({ [`${property}`]: value }),
  initVideoMeeting: async ({ amputeeId }: { amputeeId: number }) => {
    try {
      useUiStore.setState({ videoSessionState: FETCHING_STATES.loading });
      const payload: VideoMeetingEntry = await initVideoSession({ amputeeId });

      set({
        meetingStatus: true,
        roomId: payload.room_name,
        meetingJwt: payload.jwt_moderator,
        meetingJwtPatient: payload.jwt_guest,
        videoSessionId: payload.id
      });
      useUiStore.setState({ videoSessionState: FETCHING_STATES.successful });
      return payload;
    } catch (err: any) {
      set({
        meetingStatus: false
      });
      useUiStore.setState({ videoSessionState: FETCHING_STATES.failed });
      toast.error("Couldn't start a video meeting");

      console.log(err);
      return false;
    }
  },
  checkVideoMeeting: async ({ amputeeId }: { amputeeId: number }) => {
    const { resetVideoMeeting } = get();
    try {
      useUiStore.setState({ videoSessionState: FETCHING_STATES.loading });
      const payload: VideoMeetingEntry[] = await getVideoSession({ amputeeId });

      if (payload.length === 0) {
        resetVideoMeeting();
        return false;
      }

      let videoMeetingPayload = payload[0];
      const jwt = jwt_decode(videoMeetingPayload.jwt_moderator) as JwtPayload;
      const jwtTime = Number(jwt.exp) * 1000; // Exp time in milliseconds

      // 5 minutes
      if (jwtTime - Date.now() < 60 * 5 * 1000) {
        videoMeetingPayload = await refreshMeetingToken({ sessionId: videoMeetingPayload.id });
      }

      set({
        meetingStatus: true,
        roomId: videoMeetingPayload.room_name,
        meetingJwt: videoMeetingPayload.jwt_moderator,
        meetingJwtPatient: videoMeetingPayload.jwt_guest,
        videoSessionId: videoMeetingPayload.id
      });
      useUiStore.setState({ videoSessionState: FETCHING_STATES.successful });
      return videoMeetingPayload;
    } catch (err: any) {
      resetVideoMeeting();
      useUiStore.setState({ videoSessionState: FETCHING_STATES.failed });
      return false;
    }
  },
  resetVideoMeeting: () => {
    set({
      meetingStatus: initialState.meetingStatus,
      roomId: initialState.roomId,
      meetingJwt: initialState.meetingJwt,
      meetingJwtPatient: initialState.meetingJwtPatient,
      videoSessionId: initialState.videoSessionId
    });
  }
});

export const useMeetingStore = create<MeetingState>()(
  // @ts-ignore
  devtools(store, { name: 'Meeting' })
);
