/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { PCBVersionEntry } from 'api/versions/versions.types';
import { MODALS } from 'views/Modals';
import { useUiStore } from 'reducers/uiStore';
import { useConfigStore } from 'reducers/configStore';
import PcbReplacementBluetooth from './PcbReplacementBluetooth';
import PcbReplacementConnection from './PcbReplacementConnection';
import PcbReplacementInitialization from './PcbReplacementInitialization';
import PcbReplacementInstruction from './PcbReplacementInstructions';
import ModalBase from '../ModalBase';
import PcbReplacementFinish from './PcbReplacementFinish';

enum FormStep {
  Initialization,
  Instruction,
  BluetoothId,
  Connection,
  Finish
}

type PcbReplacementModalProps = {
  handleClose: Function;
};

const PcbReplacementModal = ({ handleClose }: PcbReplacementModalProps) => {
  const [step, setStep] = useState<FormStep>(FormStep.Initialization);
  const [bluetoothId, setBluetoothId] = useState<string>();
  const [selectedPcb, setSelectedPcb] = useState<PCBVersionEntry>();
  const openModal = useUiStore((state) => state.openModal);
  const { disconnectDevice } = useConfigStore();

  const handleFinish = async () => {
    await disconnectDevice();
  };

  const handleFirmware = () => {
    openModal(MODALS.firmware);
  };

  switch (step) {
    case FormStep.Initialization:
      return (
        <ModalBase handleClick={handleClose} header='Replacement' allowBackDropClose={false}>
          <PcbReplacementInitialization
            handleClose={handleClose}
            handleNext={() => setStep((prev) => prev + 1)}
          />
        </ModalBase>
      );
    case FormStep.BluetoothId:
      return (
        <ModalBase hideCloseButton header='Replacement' allowBackDropClose={false}>
          <PcbReplacementBluetooth
            handleNext={() => setStep((prev) => prev + 1)}
            handlePrevious={() => setStep((prev) => prev - 1)}
            handleBluetooth={(blId) => setBluetoothId(blId)}
            handlePcb={(pcb) => setSelectedPcb(pcb)}
            bluetoothId={bluetoothId}
            pcb={selectedPcb}
          />
        </ModalBase>
      );
    case FormStep.Instruction:
      return (
        <ModalBase hideCloseButton header='Replacement' allowBackDropClose={false}>
          <PcbReplacementInstruction handleNext={() => setStep((prev) => prev + 1)} />
        </ModalBase>
      );
    case FormStep.Connection:
      return (
        <ModalBase hideCloseButton header='Replacement' allowBackDropClose={false}>
          {bluetoothId && (
            <PcbReplacementConnection
              handlePrevious={() => setStep((prev) => prev - 1)}
              handleNext={() => setStep((prev) => prev + 1)}
              bluetoothId={bluetoothId}
              pcb={selectedPcb!}
            />
          )}
        </ModalBase>
      );
    case FormStep.Finish:
      return (
        <ModalBase hideCloseButton header='Replacement' allowBackDropClose={false}>
          <PcbReplacementFinish handleFinish={handleFinish} handleFirmware={handleFirmware} />
        </ModalBase>
      );
    default:
      return null;
  }
};

export default PcbReplacementModal;
