/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { toast } from 'react-hot-toast';
import produce from 'immer';
import { P2PSession } from 'api/liveSession/liveSession.types';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { getP2PSession } from 'api/liveSession/liveSession';
import { useAuthStore } from 'reducers/authStore';
import { REMOTE_SESSION_DEBUG } from 'consts/consts';

type LiveConfiguratorState = {
  enabled: boolean;
  device: number | null;
  configFetched: boolean;
  channel: {
    id: number | null;
    name: string;
    connected: boolean;
  };
  sessionApi: P2PSession | null;
  sessionRestorePoints: any[];
  setItemLiveConfigurator: <T extends keyof LiveConfiguratorState>(
    property: T,
    value: LiveConfiguratorState[T]
  ) => void;
  addSessionRestorePoint: (payload: {
    common: any;
    modes: { config: any; slot: number }[];
  }) => void;
  getLiveSessionApi: () => Promise<any>;
  setLiveSessionClosed: () => void;
};

const initialStateDebug = {
  enabled: true,
  device: null,
  configFetched: false,
  channel: {
    id: 1,
    name: 'test',
    connected: false
  },
  sessionApi: { clinician_uuid: '123' },
  sessionRestorePoints: []
};

const initialState = {
  enabled: false,
  device: null,
  configFetched: false,
  channel: {
    id: null,
    name: '',
    connected: false
  },
  sessionApi: null,
  sessionRestorePoints: []
};

const store = (set) => ({
  ...(REMOTE_SESSION_DEBUG ? initialStateDebug : initialState),
  setItemLiveConfigurator: <T extends keyof LiveConfiguratorState>(
    property: T,
    value: LiveConfiguratorState[T]
  ) => set({ [`${property}`]: value }),
  addSessionRestorePoint: (payload: { common: any; modes: { config: any; slot: number }[] }) =>
    set((state) => ({
      sessionRestorePoints: [
        ...state.sessionRestorePoints,
        { id: state.sessionRestorePoints?.length ?? 0, ...payload }
      ]
    })),
  getLiveSessionApi: async () => {
    try {
      const { amputeeId } = useDeviceInfoStore.getState();
      const { me } = useAuthStore.getState();

      if (!amputeeId || !me) {
        toast.error('Patient or clinician id missing');
        return;
      }
      const payload = await getP2PSession(Number(amputeeId), me.id);
      set({ sessionApi: payload });
    } catch (err: any) {
      console.log(err);
      return false;
    }
  },
  setLiveSessionClosed: () =>
    set(
      produce((state: LiveConfiguratorState) => {
        state.channel.name = initialState.channel.name;
        state.channel.connected = initialState.channel.connected;
        state.configFetched = initialState.configFetched;
        state.enabled = initialState.enabled;
      })
    )
});

export const useLiveConfiguratorStore = create<LiveConfiguratorState>()(
  // @ts-ignore
  devtools(store, { name: 'Live configurator' })
);
