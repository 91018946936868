import React, { useMemo } from 'react';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { startOnStartupMap, startOnStartupReversedMap } from 'utils/definesLocal';

const CsBeforeStartup = ({
  addHistoryProsthesis,
  generalHandSettings,
  startupSignalPermission,
  replayIsEnabled,
  setConfigProperty
}) => {
  const handleStartupSignal = (type, value) => {
    addHistoryProsthesis(
      setConfigProperty('generalHandSettings', [
        generalHandSettings[0],
        generalHandSettings[1],
        // @ts-ignore
        startOnStartupReversedMap.get(value)
      ])
    );
  };

  const CsBeforeStartup = useMemo(
    () => (
      <InputOptions
        header='Wait for CS before startup'
        data-tour='startup-signal'
        options={['On', 'Off']}
        id='startup-signal'
        onChange={handleStartupSignal}
        disable={replayIsEnabled || !startupSignalPermission}
        // @ts-ignore
        value={startOnStartupMap.get(generalHandSettings[2])}
        description='If selected, device will await a CS signal before startup'
      />
    ),
    [generalHandSettings, startupSignalPermission]
  );

  return CsBeforeStartup;
};

export default CsBeforeStartup;
