import React, { useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Save } from 'assets/sign-in-alt-solid.svg';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Restore } from 'assets/restore.svg';
import IconDescribed from 'components/atoms/IconDescribed/IconDescribed';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getScrollbarWidth } from 'utils/funcs';
import useSidebar from 'hooks/useSidebar';

const IconsContainer = styled.div`
  position: fixed;
  right: 0;
  background-color: white;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  padding: 1.3%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  z-index: 99;
  display: grid;
  grid-template-rows: repeat(auto-fill, 20px);
  grid-gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-rows: repeat(auto-fill, 30px);
    grid-gap: 15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-rows: repeat(auto-fill, 40px);
    grid-gap: 20px;
  }
`;

const SavedContainer = styled.div`
  position: relative;
`;

const pulseAnimation = keyframes`
  to {
    color: #4DC1FF;
  }
`;

const StyledSaved = styled(Save)`
  ${({ unsaved }) =>
    unsaved &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    `}
`;

type IconsSidebarProps = {
  undoFunc?: Function | boolean;
  unsaved?: boolean;
};

const IconsSidebar = ({ undoFunc = () => true, unsaved = false }: IconsSidebarProps) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const { width, height } = useWindowDimensions();
  const { handleSave, handleRestoreRecent, ticketCanBeSent, canShowSend } = useSidebar();

  useEffect(() => {
    const viewPort = document.querySelector('body')!;
    // eslint-disable-next-line no-unused-vars
    const viewPortHeight = viewPort.getBoundingClientRect().height;

    const refHeight = ref.current.getBoundingClientRect().height;

    ref.current.style.top = `${viewPortHeight / 2 - refHeight / 2}px`;
    ref.current.style.right = `${getScrollbarWidth() - 1}px`;
  }, [width, height]);

  return (
    <IconsContainer data-tour-general='icons-sidebar' ref={ref}>
      {canShowSend && ticketCanBeSent && (
        <>
          <ReactTooltip id='save' data-tour-general='save' place='left' type='info' effect='solid'>
            <span>{t('utils.send')}</span>
          </ReactTooltip>
          <SavedContainer data-tip data-for='save'>
            <IconDescribed description='Send to prosthesis' onClick={() => handleSave(unsaved)}>
              <StyledSaved unsaved={unsaved} />
            </IconDescribed>
          </SavedContainer>
        </>
      )}
      <>
        <ReactTooltip id='restore' place='left' type='info' effect='solid'>
          <span>Restore recent config</span>
        </ReactTooltip>
        <IconDescribed
          onClick={handleRestoreRecent}
          description='Restore recent config'
          disabled
          data-tip
          data-for='restore'>
          <Restore />
        </IconDescribed>
      </>
      {/* {device.connected && (
        <>
          <ReactTooltip id='hard-restore' place='left' type='info' effect='solid'>
            <span>Hard restore from device</span>
          </ReactTooltip>
          <IconDescribed
            onClick={restoreConfigDevice}
            description='Hard restore from device'
            data-tip
            data-for='hard-restore'>
            <Restore />
          </IconDescribed>
        </>
      )} */}
      {undoFunc && (
        <>
          <ReactTooltip id='undo' place='left' type='info' effect='solid'>
            <span>{t('utils.undo')}</span>
          </ReactTooltip>
          <IconDescribed onClick={undoFunc} description='Undo' data-tip data-for='undo'>
            <Undo />
          </IconDescribed>
        </>
      )}
    </IconsContainer>
  );
};

export default IconsSidebar;
