import React from 'react';
import useModes from 'hooks/useModes';
import ConfirmSave from 'components/molecules/Modals/ConfirmSave';
import RestoreRecentConfigModal from 'components/molecules/Modals/RestoreRecentConfigModal';
import PreventInputModal from 'components/molecules/Modals/PreventInputModal';
import AuthorizeServiceModal from 'components/molecules/Modals/AuthorizeServiceModal';
import DisruptiveDisconnectModal from 'components/molecules/Modals/DisruptiveDisconnectModal';
import { useUiStore } from 'reducers/uiStore';
import { FETCHING_STATES } from 'consts/consts';
import BootloaderModeModal from 'components/molecules/Modals/BootloaderModeModal';
import useUnsaved from 'hooks/useUnsaved';
import PcbReplacementModal from 'components/molecules/Modals/PcbReplacementModal.tsx/PcbReplacementModal';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import CloseSessionModal from '../components/molecules/Modals/CloseSessionModal';
import FirmwareModal from '../components/molecules/Modals/FirmwareModal';
import HandDetails from '../components/molecules/Modals/HandDetails';
import ResetAllChangesModal from '../components/molecules/Modals/ResetAllChangesModal';
import SaveAsRestorePointModal from '../components/molecules/Modals/SaveAsRestorePointModal';
import SessionWasEndedServicing from '../components/molecules/Modals/SessionWasEndedServicing';

export const MODALS = {
  servicingModal: 'servicingModal',
  authorize: 'authorize',
  firmware: 'firmware',
  closeSession: 'closeSession',
  resetAllChanges: 'resetAllChanges',
  restorePoint: 'restorePoint',
  info: 'info',
  bootloaderMode: 'bootloaderMode',
  restoreRecent: 'restoreRecent',
  disruptiveDisconnect: 'disruptiveDisconnect',
  repair: 'repair',
  confirmSave: 'confirmSave'
};

export const MODALS_ARGS = {
  unsavedChanges: 'unsavedChanges',
  unsavedChangesDisconnect: 'unsavedChangesDisconnect'
};

const Modals = () => {
  const {
    shownGlobalModal,
    synchronizingState,
    initialConfigApiState,
    videoSessionState,
    isModalOpen,
    closeModal,
    procedureState
  } = useUiStore((state) => ({
    shownGlobalModal: state.shownGlobalModal,
    synchronizingState: state.synchronizingState,
    initialConfigApiState: state.initialConfigApiState,
    isModalOpen: state.isModalOpen,
    closeModal: state.closeModal,
    procedureState: state.procedureState,
    videoSessionState: state.videoSessionState
  }));
  const { amputeeId } = useDeviceInfoStore((state) => ({ amputeeId: state.amputeeId }));
  const { handleSaveMode, isLoadingConfigSave, handleOpenSave } = useModes();
  const { isUnsaved } = useUnsaved();

  return (
    <>
      {isModalOpen(MODALS.authorize) && (
        <AuthorizeServiceModal handleClose={() => closeModal(MODALS.authorize)} />
      )}
      {isModalOpen(MODALS.firmware) && (
        <FirmwareModal handleClose={() => closeModal(MODALS.firmware)} />
      )}
      {isModalOpen(MODALS.closeSession) && (
        <CloseSessionModal handleClose={() => closeModal(MODALS.closeSession)} />
      )}
      {isModalOpen(MODALS.servicingModal) && (
        <SessionWasEndedServicing handleClose={() => closeModal(MODALS.servicingModal)} />
      )}
      {isModalOpen(MODALS.resetAllChanges) && (
        <ResetAllChangesModal handleClose={() => closeModal(MODALS.resetAllChanges)} />
      )}
      {isModalOpen(MODALS.restorePoint) && (
        <SaveAsRestorePointModal handleClose={() => closeModal(MODALS.restorePoint)} />
      )}
      {isModalOpen(MODALS.info) && <HandDetails handleClose={() => closeModal(MODALS.info)} />}
      {isModalOpen(MODALS.bootloaderMode) && (
        <BootloaderModeModal handleClose={() => closeModal(MODALS.bootloaderMode)} />
      )}
      {isModalOpen(MODALS.restoreRecent) && (
        <RestoreRecentConfigModal handleClose={() => closeModal(MODALS.restoreRecent)} />
      )}
      {isModalOpen(MODALS.disruptiveDisconnect) && (
        <DisruptiveDisconnectModal
          handleClose={() => closeModal(MODALS.disruptiveDisconnect)}
          handleAccept={() =>
            handleOpenSave(isUnsaved, { action: () => false, args: MODALS_ARGS.unsavedChanges })
          }
        />
      )}
      {isModalOpen(MODALS.confirmSave) && (
        <ConfirmSave
          handleClose={() => closeModal(MODALS.confirmSave)}
          handleAccept={handleSaveMode}
          isLoading={isLoadingConfigSave || synchronizingState === FETCHING_STATES.loading}
          localMode={!amputeeId}
        />
      )}
      {isModalOpen(MODALS.repair) && (
        <PcbReplacementModal handleClose={() => closeModal(MODALS.repair)} />
      )}
      {(shownGlobalModal === 'prevent' ||
        synchronizingState === FETCHING_STATES.loading ||
        initialConfigApiState === FETCHING_STATES.loading ||
        procedureState === FETCHING_STATES.loading ||
        videoSessionState === FETCHING_STATES.loading) && <PreventInputModal />}
    </>
  );
};

export default Modals;
