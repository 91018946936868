/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';

const useWatch = (target, keys) => {
  const [__, updateChangeId] = useState(0);

  return useMemo(() => {
    const descriptor = keys.reduce((acc, key) => {
      const internalKey = `@@__${key}__`;

      acc[key] = {
        enumerable: true,
        configurable: true,
        get() {
          return target[internalKey];
        },
        set(value) {
          if (target[internalKey] !== value) {
            target[internalKey] = value;
            updateChangeId((id) => id + 1);
          }
        }
      };
      return acc;
    }, {});

    return Object.defineProperties(target, descriptor);
  }, [target, ...keys]);
};

export default useWatch;
