/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import { Commands } from 'bluetooth/Bluetooth/Defines';
import TelemetryParser from 'utils/TelemetryParser';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import BluetoothWebControllerLE from './bluetoothLE';
import BluetoothWebControllerSerial from './bluetoothSerial';

const bluetoothLE = new BluetoothWebControllerLE();
const bluetoothSerial = new BluetoothWebControllerSerial();
const telemetryParser = new TelemetryParser();

const TelemetryController = {
  gripInTransition: false,
  telemetryEnabled: false,
  telemetryListening: false,
  telemetryReplayListening: false,
  telemetryData: <any>[],
  eventListenerController: new AbortController(),
  eventListenerReplayController: new AbortController(),
  prosthesisGrip: null,
  prosthesisVoltage: null,
  parseTelemetry(telemetryData: any) {
    const [{ payload: telemetry }] = telemetryData.detail;
    const newData = telemetryParser.parseTelemetry(telemetryData.detail, 500);
    const binaryStates = telemetry[22];
    const newGrip = telemetry[23];
    const voltage = telemetry[24];
    const newGripInTransition = ((binaryStates >> 3) & 1) === 1;
    if (newGrip !== this.prosthesisGrip) {
      this.prosthesisGrip = newGrip;
    }
    this.gripInTransition = newGripInTransition;
    // @ts-ignore
    this.telemetryData = newData;
    this.prosthesisVoltage = voltage;
  },
  parseReplayTelemetry(telemetryData: any) {
    const { reset, data } = telemetryData.detail;
    if (reset) telemetryParser.clear();
    const newData = telemetryParser.parseTelemetry(data, 500);
    this.telemetryData = newData;
  },
  abortReplay() {
    this.eventListenerReplayController.abort();
    this.telemetryReplayListening = false;
  },
  enableReplay() {
    this.eventListenerReplayController = new AbortController();
    if (!this.telemetryReplayListening) {
      window.addEventListener(`replay`, this.parseReplayTelemetry.bind(this), {
        signal: this.eventListenerReplayController.signal
      });
      this.telemetryReplayListening = true;
    }
  },
  async checkVoltage() {
    if (!this.telemetryEnabled) {
      await this.telemetryOn();
      await delay(400);
      await this.telemetryOff();
    }
    return this.prosthesisVoltage;
  },
  initiateTelemetry() {
    this.eventListenerController = new AbortController();
    if (!this.telemetryListening) {
      window.addEventListener(
        `received${Commands.kTelemetryData}`,
        this.parseTelemetry.bind(this),
        { signal: this.eventListenerController.signal }
      );
      this.telemetryListening = true;
    }
  },
  abortTelemetry() {
    this.eventListenerController.abort();
    this.telemetryListening = false;
  },
  async telemetryOn(mode = 'ble') {
    this.telemetryEnabled = true;
    if (mode === 'classic') {
      await bluetoothSerial.telemetryOn();
    } else {
      await bluetoothLE.telemetryOn();
    }
  },
  async telemetryOff(mode = 'ble') {
    this.telemetryEnabled = false;
    if (mode === 'classic') {
      await bluetoothSerial.telemetryOff();
    } else {
      await bluetoothLE.telemetryOff();
    }
  }
};

export default TelemetryController;
