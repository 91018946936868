import React from 'react';
import { IconButton, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { commonFormStyles } from './commonStyles';

const CustomPasswordField = ({ id, label, control }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <div>
          <InputLabel
            shrink={false}
            htmlFor={id}
            sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
            <Typography variant='subtitle1'>{label}</Typography>
          </InputLabel>
          <TextField
            {...field}
            id={id}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            type={showPassword ? 'text' : 'password'}
          />
        </div>
      )}
    />
  );
};

export default CustomPasswordField;
