/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import uniqid from 'uniqid';
import RadioInput from 'components/atoms/RadioInput/RadioInput';
import HorizontalGraphSlider from 'components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';

import {
  Header2,
  OptionsList,
  OptionsSliderDescription,
  OptionsWrapper,
  OptionsWrapperHoldOpen
} from './styled';

interface OptionsWithSliderType {
  toggleValue: any;
  toggleOptions: any[];
  sliderValue: number;
  header: any;
  sliderLabel: any;
  sliderLimits: { min: number; max: number };
  handleSliderChange: Function;
  handleToggleChange: Function;
  handleSliderOnAfterChange: Function;
  description: string | React.ReactElement;
  disableSlider?: boolean;
  disabled?: boolean;
  scale?: (x: number) => number;
  step?: number;
  hideInput?: boolean;
}

export const OptionsWithSlider = ({
  toggleValue,
  toggleOptions,
  sliderValue,
  header,
  sliderLabel,
  sliderLimits,
  handleSliderChange,
  handleToggleChange,
  handleSliderOnAfterChange,
  description,
  disableSlider = false,
  disabled = false,
  scale,
  step = 1,
  hideInput = false,
  ...props
}: OptionsWithSliderType) => (
  <div {...props}>
    <Header2>{header}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    {!hideInput && (
      <OptionsWrapper>
        <OptionsList disable={disabled}>
          {toggleOptions.map((option) => (
            <RadioInput
              key={uniqid()}
              // eslint-disable-next-line eqeqeq
              checked={toggleValue == option}
              as='li'
              disabled
              onClick={(...args) => handleToggleChange(option, ...args)}
              label={option}
              id={option}
              name={option}
            />
          ))}
        </OptionsList>
        <HorizontalGraphSlider
          value={sliderValue}
          limits={sliderLimits}
          label={sliderLabel}
          disableSliders={disabled || disableSlider}
          handleChange={handleSliderChange}
          handleOnAfterChange={handleSliderOnAfterChange}
          scale={scale}
          step={step}
        />
      </OptionsWrapper>
    )}
  </div>
);

interface MultiSliderType {
  header: string;
  sliderValues: any;
  labelSliders: any;
  limits: Array<{ min: number; max: number }>;
  description: string | React.ReactElement;
  handleChange: Function;
  handleOnAfterChange: any;
  disabled?: boolean;
}

export const MultiSlider = ({
  header,
  sliderValues,
  labelSliders,
  limits,
  handleChange,
  handleOnAfterChange,
  description,
  disabled = false,
  ...props
}: MultiSliderType) => (
  <div {...props}>
    <Header2>{header}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperHoldOpen>
      {limits.map((slider, index) => (
        <HorizontalGraphSlider
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          label={labelSliders[index]}
          disableSliders={disabled}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={(...args) => handleOnAfterChange(...args, index)}
          key={header + index}
        />
      ))}
    </OptionsWrapperHoldOpen>
  </div>
);
