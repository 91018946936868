import React from 'react';
import { FormHelperText, Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormCheckbox = ({ id, label, control }) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <FormControl
        {...field}
        error={Boolean(fieldState.error)}
        component='fieldset'
        variant='standard'>
        <FormControlLabel label={label} control={<Checkbox id={id} checked={field.value} />} />
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    )}
  />
);

export default FormCheckbox;
