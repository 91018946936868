import styled from 'styled-components';

export const ErrorContainer = styled.div`
  padding-top: 35vh;
  text-align: center;

  p {
    font-size: 25px;

    & + p {
      margin-top: 10px;
    }
  }
`;

export const StyledLogo = styled.img`
  margin-bottom: 20px;
  width: 150px;
`;
