/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Toaster } from 'react-hot-toast';
import SidebarMinimal from 'components/organisms/SidebarMinimal/SidebarMinimal';
import UtilityBar from 'components/molecules/UtilityBar/UtilityBar';
import RemoteSessionBar from 'components/molecules/RemoteSessionBar/RemoteSessionBar';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  flex: 1;
  transform: translateZ(0);
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: '24px';
  flex-wrap: wrap;
  margin: 25px 0 0 25px;
`;

const InnerWrapper = styled.div`
  padding: 25px 25px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Main = styled.div`
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth};
  padding: ${({ padding }) => padding || '0px'};
  margin-top: 50px;
`;

const BarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth};
  gap: 16px;
  margin: 0 auto;
`;

interface MinimalLayoutProps {
  children: React.ReactNode;
  padding: string;
  maxWidth?: string;
  iconsSidebar?: null | React.ReactNode;
}

const MinimalLayout = ({
  children,
  padding,
  maxWidth = '1300px',
  iconsSidebar = null
}: MinimalLayoutProps) => {
  const deviceConnected = useDeviceInfoStore((state) => state.connected);

  return (
    <Wrapper>
      <SidebarWrapper style={{ marginLeft: '24px' }}>
        <SidebarMinimal />
        {iconsSidebar || null}
      </SidebarWrapper>
      <OuterWrapper id='layout'>
        <InnerWrapper>
          <BarsWrapper maxWidth={maxWidth}>
            <UtilityBar device={deviceConnected} />
            <RemoteSessionBar />
          </BarsWrapper>
          <Main id='minimal-main' padding={padding} maxWidth={maxWidth}>
            {children}
          </Main>
        </InnerWrapper>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              marginTop: '30px'
            }
          }}
        />
      </OuterWrapper>
    </Wrapper>
  );
};

export default MinimalLayout;
