/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Types } from 'ably';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { EMERGENCY_MODE_NOTIFICATION } from 'consts/notifications';
import { useConfigStore } from 'reducers/configStore';
import useModes from 'hooks/useModes';
import useMeeting from 'hooks/useMeeting';
import useRemoteSession from 'hooks/useRemoteSession';
import { ablyClient } from '../../../utils/LiveConfigurator/AblyClient';

const LiveConfigurator = ({ children, remoteSessionState }) => {
  const [mainChannel, setMainChannel] = useState<Types.RealtimeChannelCallbacks | null>();
  const { enabled, channel: connection, sessionApi, isUnsaved } = remoteSessionState;
  const { handleChangeMode } = useModes();
  const { config, slotSelected, getInitialConfigAPI } = useConfigStore((state) => ({
    config: state.config,
    slotSelected: state.slotSelected,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { handleCloseMeeting } = useMeeting();
  const { disconnect: disconnectRemoteSession } = useRemoteSession();

  useEffect(() => {
    if (enabled && sessionApi?.clinician_uuid && !mainChannel) {
      const main = ablyClient(sessionApi.clinician_uuid).channels.get(connection.name);

      setMainChannel(main);

      main?.presence.subscribe('leave', (member) => {
        toast('Patient has been disconnected from session', { icon: '⚠️' });
        disconnectRemoteSession(false);
      });

      main?.subscribe('emg', (message) => {
        if (message.data) {
          TelemetryController.parseTelemetry({ detail: [{ payload: message.data }] });
        }
      });

      main?.subscribe('restart_web', (message) => {
        getInitialConfigAPI();
      });

      main?.subscribe('close_meeting_web', (message) => {
        handleCloseMeeting(false);
      });

      main.attach((error) => {
        if (!error) main.presence.enter();
      });
    }
    return () => {
      if (mainChannel) {
        mainChannel.unsubscribe();
      }
    };
  }, [enabled, connection, sessionApi, mainChannel]);

  useEffect(() => {
    if (mainChannel) {
      mainChannel.subscribe('emergency_mode', () => {
        toast(EMERGENCY_MODE_NOTIFICATION.message, EMERGENCY_MODE_NOTIFICATION.options);
      });

      return () => {
        if (mainChannel) {
          mainChannel.unsubscribe('emergency_mode');
        }
      };
    }
  }, [mainChannel]);

  useEffect(() => {
    if (mainChannel) {
      mainChannel.subscribe('close_session_patient', () => {
        toast('Patient has closed the session', { icon: '⚠️' });
      });

      return () => {
        if (mainChannel) {
          mainChannel.unsubscribe('close_session_patient');
        }
      };
    }
  }, [isUnsaved, mainChannel]);

  useEffect(() => {
    if (mainChannel) {
      mainChannel?.subscribe('query_mode_web', () => {
        handleChangeMode(slotSelected ?? config.modes[0].slot);
      });

      return () => {
        if (mainChannel) {
          mainChannel.unsubscribe('query_mode_web');
        }
      };
    }
  }, [slotSelected, mainChannel, config.modes]);

  return <>{children}</>;
};

export default LiveConfigurator;
