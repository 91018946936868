import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import ServicePositions from 'components/molecules/ServicePositions/ServicePositions';
import { HeaderXL } from 'components/atoms/Typography/Typography';
import GripsTester from 'components/molecules/GripTester/GripTester';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GripsTesterWrapper = styled.div`
  max-width: 400px;
`;

const ServiceMenu = () => {
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const { push } = useHistory();

  useEffect(() => {
    if (!deviceConnected) {
      push('/choose-grips');
    }
  }, [deviceConnected]);

  return (
    <Wrapper>
      <ServicePositions />
      <GripsTesterWrapper>
        <GripsTester />
      </GripsTesterWrapper>
    </Wrapper>
  );
};

export default ServiceMenu;
