/* eslint-disable no-useless-escape */
import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface PasswordStrengthProps {
  password: string;
  minLength: number;
  specialChars?: boolean;
  upperCase?: boolean;
  numbers?: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
`;

const GenericBox = styled.div<{ inputColor?: string }>`
  background-color: ${(props) => props.inputColor};
  border-radius: 8px;
  height: 4px;
`;

const BoxesColors = ['green', 'green', 'green', 'green', 'green'];

const PasswordStrength = ({
  password,
  minLength,
  specialChars = false,
  upperCase = false,
  numbers = false
}: PasswordStrengthProps) => {
  let boxesNumber = 2;
  let step = 0;
  let string = 'The password should contain';

  if (specialChars) {
    boxesNumber += 1;
  }
  if (upperCase) {
    boxesNumber += 1;
  }
  if (numbers) {
    boxesNumber += 1;
  }

  if (password.length >= minLength) {
    step += 1;
  } else {
    string += `, at least ${minLength} characters`;
  }
  if (/[a-z]/.test(password)) {
    step += 1;
  } else {
    string += ', one lowercase letter';
  }
  if (numbers && /\d/.test(password)) {
    step += 1;
  } else {
    string += ', one number';
  }
  if (upperCase && /[A-Z]/.test(password)) {
    step += 1;
  } else {
    string += ', one uppercase letter';
  }
  if (specialChars && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
    step += 1;
  } else {
    string += ', one special character';
  }

  const boxes = Array.from(Array(boxesNumber).keys());

  return (
    <div>
      <Wrapper>
        {boxes.map((box) => (
          <GenericBox key={box} inputColor={step > box ? BoxesColors[box] : 'gray'} />
        ))}
      </Wrapper>
      <FormHelperText>{step < boxesNumber ? string : 'Password is strong!'}</FormHelperText>
    </div>
  );
};

export default PasswordStrength;
