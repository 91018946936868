/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  UserDetails,
  UserLoginPayload,
  UserMePayload,
  MfaRequire,
  MfaOptions
} from 'api/authentication/authentication.types';
import { fetchUserData } from '../api/authentication/authentication';

export interface AuthState {
  token: null | string;
  tokenTime: null | string;
  me: UserDetails | null;
  mfa: {
    required: boolean;
    channel?: MfaOptions | null;
    codeFromUrl: string | null | number;
  };
  registeredUser: boolean;
  setLoggedUser: (payload: UserMePayload) => void;
  loginSuccess: (payload: UserLoginPayload) => void;
  logoutSuccess: () => void;
  setItemAuth: <T extends keyof AuthState>(property: T, value: AuthState[T]) => void;
  fetchUserMe: () => Promise<void>;
  mfaCodeRequire: (payload: MfaRequire) => void;
}

const initialState = {
  token: null,
  tokenTime: null,
  me: null,
  mfa: {
    required: false,
    channel: null,
    codeFromUrl: null
  },
  registeredUser: false
};

const store = (set) => ({
  ...initialState,
  setLoggedUser: (payload: UserMePayload) => set({ me: payload }),
  loginSuccess: (payload: UserLoginPayload) =>
    set({ token: payload.access_token, tokenTime: payload.expires }),
  logoutSuccess: () => set({ token: initialState.token, tokenTime: initialState.tokenTime }),
  fetchUserMe: async () => {
    try {
      const payload = await fetchUserData();
      set({ me: payload });
    } catch (e) {
      console.log(e);
    }
  },
  mfaCodeRequire: (payload: MfaRequire) =>
    set((state) => ({
      ...state,
      mfa: {
        required: payload.require,
        channel: payload.channel,
        codeFromUrl: payload?.code || null
      }
    })),
  setItemAuth: <T extends keyof AuthState>(property: T, value: AuthState[T]) =>
    set({ [`${property}`]: value })
});

export const useAuthStore = create<AuthState>()(
  // @ts-ignore
  devtools(store, { name: 'Auth' })
);
