/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import { postFingerLimits, postInitialGripPositions } from 'bluetooth-handler/bluetoothFunctions';
import { arraysEqual } from 'utils/funcs';
import {
  SpeedControlStrategies,
  SingleElectrodeMode,
  GripSwitchingModes
} from 'bluetooth/Bluetooth/Control';
import { emgThresholdsEntry, DeviceConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';

export const compareGripsPositions = (apiGripsPositions, localGripsPositions) => {
  let positionsToSentApi = {};
  const localConfigCopy = { ...localGripsPositions };
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localGripsPositions) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      if (
        !arraysEqual(element?.initial, apiGripsPositions[key]?.initial) ||
        (!arraysEqual(element?.limit, apiGripsPositions[key]?.limit) && element)
      ) {
        positionsToSentApi = { ...positionsToSentApi, [key]: element };
      }
      delete localConfigCopy[key];
    }
  }
  positionsToSentApi = { ...positionsToSentApi, ...localConfigCopy };
  return positionsToSentApi;
};

export const compareConfigs = (apiConfig, localConfig) => {
  let configToSentApi = {};
  const localConfigCopy = { ...localConfig };
  delete localConfigCopy.gripsConfiguration;
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localConfig) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      const elementAPI = apiConfig?.[key];

      if (element && elementAPI) {
        if (!arraysEqual(element, elementAPI)) {
          configToSentApi = { ...configToSentApi, [key]: element };
        }
      }
      delete localConfigCopy[key];
    }
  }
  configToSentApi = { ...configToSentApi, ...localConfigCopy };
  if (apiConfig?.gripsPositions && localConfig?.gripsPositions) {
    const gripsCompared = compareGripsPositions(
      apiConfig.gripsPositions,
      localConfig.gripsPositions
    );
    if (!isEmpty(gripsCompared)) {
      return { ...configToSentApi, gripsPositions: gripsCompared };
    }
  }
  return { ...configToSentApi };
};

export const sendFingersConfigHelper = async (grip, valuesInitial, valuesLimit, bluetoothMode) => {
  await postInitialGripPositions(grip, valuesInitial, bluetoothMode);
  await postFingerLimits(grip, valuesLimit, bluetoothMode);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (gripsPositions, bluetoothMode) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(
        grip,
        gripPositions.initial,
        gripPositions.limit,
        bluetoothMode
      );
    }
  }
};

export const checkEmgValidity = (config: DeviceConfigTemplate, emgSettings: emgThresholdsEntry) => {
  let changeSignalOpen = emgSettings[0];
  let changeSignalClose = emgSettings[1];
  let speed1Opening = emgSettings[2];
  let speed2Opening = emgSettings[3];
  let speed3Opening = emgSettings[4];
  let speed1Closing = emgSettings[5];
  let speed2Closing = emgSettings[6];
  let speed3Closing = emgSettings[7];

  if (typeof config.speedControlStrategy[0] === 'boolean') return;

  const speedControlStrategy = config.speedControlStrategy[0];
  const gripSwitchingMode = config.gripSwitchingMode[0];
  const singleElectrodeMode = config?.singleElectrodeMode?.[0];
  const singleElectrodeModeSettings = config?.singleElectrodeModeSettings;
  const startPointSignalThresholds = singleElectrodeModeSettings?.[0];
  const freezeModeRelaxationClosing = config?.freezeModeEmg?.[0];
  const freezeModeRelaxationOpening = config?.freezeModeEmg?.[1];
  const freezeModeEmgStatus = config?.freezeModeEmg?.[2];

  if (
    singleElectrodeMode !== undefined &&
    singleElectrodeMode === SingleElectrodeMode.kFastRising &&
    gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching
  ) {
    let triggered = false;
    if (speed1Opening <= startPointSignalThresholds) {
      speed1Opening = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (changeSignalOpen <= startPointSignalThresholds) {
      changeSignalOpen = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (changeSignalClose <= startPointSignalThresholds) {
      changeSignalClose = startPointSignalThresholds + 1;
      triggered = true;
    }
    if (speed1Closing <= startPointSignalThresholds) {
      speed1Closing = startPointSignalThresholds + 1;
      triggered = true;
    }

    if (triggered)
      toast.error('Start point signal threshold must be lower than CS/activation open/close', {
        id: 'startPointWarning'
      });
  }

  if (freezeModeRelaxationClosing !== undefined && freezeModeEmgStatus !== 0) {
    let triggered = false;
    if (speed1Opening <= freezeModeRelaxationOpening) {
      speed1Opening = freezeModeRelaxationOpening === 100 ? 100 : freezeModeRelaxationOpening + 1;
      triggered = true;
    }
    if (speed1Closing <= freezeModeRelaxationClosing) {
      speed1Closing = freezeModeRelaxationClosing === 100 ? 100 : freezeModeRelaxationClosing + 1;
      triggered = true;
    }

    if (triggered)
      toast.error(
        'Freeze mode relaxation thresholds must be lower than EMG activation thresholds',
        {
          id: 'freezeModeEmgRelaxationWarning'
        }
      );
  }

  // Correct wrong values
  if (speed1Opening > speed2Opening) {
    speed2Opening = speed1Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings",
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1'
        }
      );
  }

  if (speed2Opening > speed3Opening) {
    speed3Opening = speed2Opening;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings",
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2'
        }
      );
  }
  if (speed1Closing > speed2Closing) {
    speed2Closing = speed1Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings",
        {
          id: 'speedControlStrategiesSpeed2BiggerThanSpeed1'
        }
      );
  }
  if (speed2Closing > speed3Closing) {
    speed3Closing = speed2Closing;
    if (speedControlStrategy === SpeedControlStrategies.kProportional)
      toast.error(
        "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings",
        {
          id: 'speedControlStrategiesSpeed3BiggerThanSpeed2'
        }
      );
  }

  const validatedEmg = [
    changeSignalOpen,
    changeSignalClose,
    speed1Opening,
    speed2Opening,
    speed3Opening,
    speed1Closing,
    speed2Closing,
    speed3Closing,
    emgSettings[8],
    emgSettings[9]
  ];
  return validatedEmg as emgThresholdsEntry;
};
