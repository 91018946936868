/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { DeviceEntry } from 'api/device/device.types';
import { Location } from 'api/locations/locations.types';

export enum ArchivedEnum {
  active = 0,
  archived = 1,
  all = 'any'
}

export enum RoleEnum {
  amputee = 'Amputee',
  clinician = 'Clinician',
  superAdmin = 'SuperAdmin',
  clinicAdmin = 'ClinicAdmin',
  clinicianSupport = 'ClinicianSupport'
}

export enum UserExtendOptions {
  location = 'location',
  locationCompany = 'location.company',
  countryLocation = 'location.company.country',
  creator = 'creator',
  permissions = 'permissions',
  devices = 'devices',
  roles = 'roles'
}

export enum UserDevicesExtendOptions {
  model = 'model'
}

export interface Role {
  id: number;
  name: string;
}

export interface UserCreatePayload {
  name: string;
  email: string;
  phone: string;
  location: string;
  role: string;
  image: File;
}

export interface UserUpdatePayload extends UserCreatePayload {
  id: number;
  image_delete: boolean;
}

export interface PermissionEntry {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
}

export interface UserEntry {
  id: number;
  name: string;
  email: string;
  phone: string;
  phone_verified_at: string;
  password?: string;
  image?: string;
  location_id?: number;
  created_by?: number;
  archived: ArchivedEnum;
  created_at: string;
  updated_at: string;
  location?: Location;
  roles?: Role[];
  devices?: DeviceEntry[];
  active: ArchivedEnum;
  permissions?: PermissionEntry[];
}

export interface UsersQueryParams {
  archived?: ArchivedEnum;
  company?: number;
  location?: number;
  clinician?: number;
  roles?: string;
  extend?: UserExtendOptions[];
  perpage?: number;
  page?: number;
}

export interface UserQueryParams {
  extend?: UserExtendOptions[];
}

export interface UserDevicesParams {
  perpage?: number;
  page?: number;
  extend?: UserDevicesExtendOptions[];
}
