/* eslint-disable no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { HeaderS } from '../Typography/Typography';

const Wrapper = styled.div`
  display: flex;
  ${HeaderS};
  cursor: pointer;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  text-align: center;
  height: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 50px;
  }
`;

const Togglable = styled.button`
  color: ${({ theme }) => theme.colorSecondary};
  transition: color 0.3s;
  background-color: #fff;
  border: none;
  height: 100%;
  cursor: pointer;
  padding: 0 20px;
  ${HeaderS};
  ${({ toggled }) =>
    toggled &&
    css`
      color: ${({ theme }) => theme.typography.colors.primary};
      background-color: #4fc1ff;
    `};

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ButtonToggle = ({
  modes,
  activeId,
  disabled = false,
  tip = !disabled ? null : "You don't have access",
  toggle = () => false,
  ...props
}) => (
  <Tooltip title={tip}>
    <div>
      <Wrapper toggled={activeId} modes={modes} {...props}>
        {modes.map((mode) => (
          <Togglable
            onClick={() => (!disabled ? toggle(mode.id) : null)}
            toggled={activeId === mode.id}
            key={mode.id}
            disabled={disabled}>
            {mode.name}
          </Togglable>
        ))}
      </Wrapper>
    </div>
  </Tooltip>
);

ButtonToggle.defaultProps = {
  toggled: false
};

ButtonToggle.propTypes = {
  toggled: PropTypes.bool
};

export default ButtonToggle;
