import { useDeviceInfoStore } from 'reducers/deviceInfoStore';

const useImportButton = (importDifferences) => {
  const { amputeeId, connected } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId,
    connected: state.connected
  }));

  const importTooltip = () => {
    if (!amputeeId && !connected) return 'Device not connected';
    if (!importDifferences) return 'No changes to import';
    return null;
  };

  const disableImportButton = () => {
    if (!amputeeId && !connected) return true;
    if (!importDifferences) return true;

    return false;
  };

  return { importTooltip, disableImportButton };
};

export default useImportButton;
