import React from 'react';
import toast, { ToastOptions } from 'react-hot-toast';

export const ApiErrorNotification = ({ errors = [] }: { errors: string[] }) => (
  <>
    <ul>
      {errors.map((item) => (
        <li key={`item-${item}`}>{item}</li>
      ))}
    </ul>
  </>
);
export function renderNotification() {
  return (title: string, description: string, options: ToastOptions, children: React.ReactNode) =>
    toast.error(
      <div>
        <div>{title}</div>
        <div>{description}</div>
        {children}
      </div>,
      options
    );
}
