import { AxiosResponse } from 'axios';
import api from '../utils/apiClient';
import {
  LiveSessionInitializePayload,
  P2PSession,
  SessionStatus,
  VideoMeetingEntry
} from './liveSession.types';

export const CREATE_P2P_SESSION = 'api/p2p/create';
export const UPDATE_P2P_SESSION = 'api/p2p/{id}/update';
export const VIEW_P2P_SESSION = 'api/p2p/{clinicianId}/{amputeeId}';
export const INIT_VIDEO_SESSION = 'api/sessions/video/{guestId}';
export const GET_VIDEO_SESSION = 'api/sessions/video/{guestId}';
export const CLOSE_VIDEO_SESSION = 'api/sessions/video/{sessionId}';
export const REFRESH_VIDEO_TOKEN = 'api/sessions/video/{sessionId}/refresh';

const withCredentials = {
  withCredentials: true
};

export const initializeP2PSession = (data: LiveSessionInitializePayload): Promise<any> =>
  api
    .post(
      CREATE_P2P_SESSION,
      {
        amputee_id: data.amputee_id,
        amputee_uuid: data.amputee_uuid,
        clinician_uuid: data.clinician_uuid
      },
      withCredentials
    )
    .then((res) => res.data);

export const updateP2PSession = ({
  id,
  status
}: {
  id: number;
  status: SessionStatus;
}): Promise<any> =>
  api
    .post(UPDATE_P2P_SESSION.replace('{id}', `${id}`), { status }, withCredentials)
    .then((res) => res.data);

export const getP2PSession = (amputeeId: number, clinicianId: number): Promise<any> =>
  api
    .get<P2PSession>(
      VIEW_P2P_SESSION.replace('{clinicianId}', String(clinicianId)).replace(
        '{amputeeId}',
        String(amputeeId)
      ),
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const getVideoSession = ({ amputeeId }: { amputeeId: number }): Promise<any> =>
  api
    .get<VideoMeetingEntry[]>(
      GET_VIDEO_SESSION.replace('{guestId}', String(amputeeId)),
      withCredentials
    )
    .then((res) => res.data);

export const initVideoSession = ({ amputeeId }: { amputeeId: number }): Promise<any> =>
  api
    .post<VideoMeetingEntry>(
      INIT_VIDEO_SESSION.replace('{guestId}', String(amputeeId)),
      {},
      withCredentials
    )
    .then((res) => res.data);

export const closeVideoSession = ({ sessionId }: { sessionId: number }): Promise<any> =>
  api
    .delete(CLOSE_VIDEO_SESSION.replace('{sessionId}', String(sessionId)), withCredentials)
    .then((res) => res.data);

export const refreshMeetingToken = ({ sessionId }: { sessionId: number }): Promise<any> =>
  api
    .post<VideoMeetingEntry>(
      REFRESH_VIDEO_TOKEN.replace('{sessionId}', String(sessionId)),
      {},
      withCredentials
    )
    .then((res) => res.data);
