import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import useDebounce from 'hooks/useDebounce';
import { refreshTokenApi } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { useAuthStore } from 'reducers/authStore';

const useRefreshToken = () => {
  const [delayedFlag, setDelayedFlag] = useState(false);

  const { fetchUserMe, loginSuccess, logoutSuccess } = useAuthStore((state) => ({
    loginSuccess: state.loginSuccess,
    fetchUserMe: state.fetchUserMe,
    logoutSuccess: state.logoutSuccess
  }));

  const { isLoading, mutateAsync: refreshToken } = useMutation(refreshTokenApi, {
    onError() {
      logoutSuccess();
    },
    onSuccess(data) {
      if (data) {
        setApiAuthToken(data.token);
        loginSuccess(data);
        fetchUserMe();
      }
    },
    onSettled() {}
  });

  const debounced = useDebounce(refreshToken, 500);

  const delayedRefresh = useCallback(() => {
    setDelayedFlag(true);
  }, []);

  useEffect(() => {
    if (delayedFlag) {
      setDelayedFlag(false);
      debounced();
    }
  }, [delayedFlag, refreshToken]);

  return {
    delayedRefresh,
    isLoading,
    refreshToken
  };
};

export default useRefreshToken;
