import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import './style.css';

const CustomVerificationCode = ({
  onChange,
  value,
  error = false
}: {
  onChange: (e: string) => void;
  value: undefined | string;
  error?: boolean;
}) => {
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setChanged(false);
  }, [error]);

  return (
    <VerificationInput
      value={value}
      length={6}
      validChars='A-Z0-9a-z'
      onChange={(e) => {
        onChange(e);
        if (!changed) setChanged(true);
      }}
      placeholder='-'
      classNames={{
        character: error && !changed ? 'character-error' : 'character',
        characterInactive: 'character-inactive',
        container: 'container'
      }}
      onFocus={() => {
        if (!changed) setChanged(true);
      }}
    />
  );
};

export default CustomVerificationCode;
