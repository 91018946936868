import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    padding: 12px 0;
    text-align: left;
  }

  td {
    border-top: 1px solid ${({ theme }) => theme.colorFill};
  }

  caption {
    background-color: ${({ theme }) => theme.colorFill};
    border-radius: 5px;
    padding: 10px 0;
    font-weight: bold;
  }
`;
