import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body, ul, li, nav {
        padding: 0;
        margin: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }

    h1,h2,h3,h4,h5  {
        padding: 0;
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
    }

    body {
        font-family: "Open Sans", sans-serif;
    }

    ::-ms-reveal {
      display: none
    }
`;

export default GlobalStyle;
