import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'components/atoms/Typography/Typography';
import ModalBase from './ModalBase';
import { StyledCheck } from '../../atoms/Icons/Icons';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 20px;
  margin-bottom: 40px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

const CheckIcon = styled(StyledCheck)`
  margin: 10px 0;
  width: 48px;
  height: 48px;
`;

const ContentWrapper = styled.div`
  text-align: center;
`;
type ModalProps = {
  handleClose: any;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => (
  <ModalBase handleClick={handleClose} header='Save as restore point'>
    <DialogContent>
      <ContentWrapper>
        <CheckIcon />
        <ParagraphBold>The restore point was successfully created.</ParagraphBold>
      </ContentWrapper>
      <ButtonsWrapper>
        <Button sx={{ width: 200 }} variant='outlined' onClick={handleClose}>
          Close
        </Button>
      </ButtonsWrapper>
    </DialogContent>
  </ModalBase>
);

export default ResetAllChangesModal;
