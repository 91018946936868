import {
  emgThresholdsEntry,
  DeviceConfigTemplate,
  freezeModeEmgEntry
} from 'consts/deviceConfig/deviceConfig.types';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import {
  ControlModes,
  GripSwitchingModes,
  InputDevices,
  InputSites
} from 'bluetooth/Bluetooth/Control';
import { aetherBatteryVoltageFunctions } from 'utils/definesLocal';
import { ConfigStoreState } from 'reducers/configStore';
import { checkEmgValidity } from './bluetoothHelpers';
import { getCurrentConfigSelector, getFwVersionSelector, getModeBySlotSelector } from './selectors';

export const freezeModeEmgModifierControlConfig = ({
  newInputSite,
  versions,
  firmware,
  oldFreezeModeEmg
}) => {
  let newFreezeModeEmg = oldFreezeModeEmg;
  const canApplyFreezeModeFix =
    Number(versions?.current?.[1]) >= 2 || Number(firmware?.name[0]) >= 2;

  if (
    canApplyFreezeModeFix &&
    newInputSite === InputSites.kSingleElectrode &&
    oldFreezeModeEmg[2] === 1
  ) {
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 3, 3];
  }

  if (newInputSite !== InputSites.kSingleElectrode && oldFreezeModeEmg[2] === 3)
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 1, 2];

  return newFreezeModeEmg;
};

export type freezeModeValue = [0 | 1, number, number];

export const freezeModeEmgModifier = (
  freezeModeEmgValue: freezeModeValue,
  currentConfig: DeviceConfigTemplate,
  firmware,
  versions,
  newConfig
) => {
  let value: freezeModeValue | freezeModeEmgEntry = freezeModeEmgValue;
  let triggered = false;
  const inputSite = currentConfig.inputSite?.[0];
  const emgThresholds = currentConfig.emgThresholds;
  const changeSignalOpen = emgThresholds[2];
  const changeSignalClose = emgThresholds[5];
  const [status, closing, opening] = value;
  value = [closing, opening, status ? 1 : 0, status ? 2 : 0] as freezeModeEmgEntry;

  const canApplyFreezeModeFix =
    (Number(versions?.current?.[1]) >= 2 || Number(firmware?.name[0]) >= 2) &&
    inputSite === InputSites.kSingleElectrode;

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  if (canApplyFreezeModeFix) {
    value = [closing, opening, status ? 3 : 0, status ? 3 : 0] as freezeModeEmgEntry;
  }

  if (closing >= changeSignalClose) {
    triggered = true;
    value = [changeSignalClose - 1, value[1], value[2], value[3]] as freezeModeEmgEntry;
  }

  if (opening >= changeSignalOpen) {
    triggered = true;
    value = [value[0], changeSignalOpen - 1, value[2], value[3]] as freezeModeEmgEntry;
  }

  return { adjustedConfig: { ...newConfig, freezeModeEmg: value }, triggered };
};

export const singleElectrodeModeModifier = (singleElectrodeMode, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const adjustedEmg = checkEmgValidity(
    { ...currentConfig, singleElectrodeMode },
    currentConfig.emgThresholds
  );

  adjustedConfig = {
    ...newConfig,
    singleElectrodeMode,
    emgThresholds: adjustedEmg
  };

  return adjustedConfig;
};

export const singleElectrodeModeSettingsModifier = (
  singleElectrodeModeSettingsValue,
  currentEmgThresholds,
  newConfig
) => {
  let [startPointSignalThresholds] = singleElectrodeModeSettingsValue as any;
  const csOpening = currentEmgThresholds[0];
  const csClosing = currentEmgThresholds[1];
  const thresholdOpening = currentEmgThresholds[2];
  const thresholdClosing = currentEmgThresholds[5];
  let adjustedConfig = {};
  let triggered = false;

  adjustedConfig = {
    ...newConfig,
    singleElectrodeModeSettings: singleElectrodeModeSettingsValue
  };

  const thresholdsMinValue = Math.min(thresholdOpening, thresholdClosing, csOpening, csClosing);

  if (startPointSignalThresholds >= thresholdsMinValue) {
    startPointSignalThresholds = thresholdsMinValue - 1;
    adjustedConfig = {
      ...newConfig,
      singleElectrodeModeSettings: [
        startPointSignalThresholds,
        singleElectrodeModeSettingsValue[1],
        singleElectrodeModeSettingsValue[2],
        singleElectrodeModeSettingsValue[3]
      ]
    };
    triggered = true;
  }

  return { adjustedConfig, triggered };
};

export const speedControlStrategyModifier = (speedControlStrategy, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const adjustedEmg = checkEmgValidity(
    { ...currentConfig, speedControlStrategy },
    currentConfig.emgThresholds
  );

  adjustedConfig = {
    ...newConfig,
    speedControlStrategy,
    emgThresholds: adjustedEmg
  };

  return adjustedConfig;
};

export const controlConfigModifier = ({
  newInputSite,
  newGripSwitchingMode,
  oldInputSite,
  prevState,
  versions,
  firmware,
  set,
  get
}) => {
  const deviceInfoState = useDeviceInfoStore.getState();
  let currentConfig = getCurrentConfigSelector(prevState);
  let applyGlobally = false;
  if (newInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kSingleGripSwitching;
    applyGlobally = true;
  } else if (oldInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kCoContraction;
    applyGlobally = true;
  }
  if (newGripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
    newInputSite = InputSites.kSingleElectrode;
    applyGlobally = true;
  }
  // Set modes configs

  let modesApplied;
  const localConfigSupported = getFwVersionSelector(deviceInfoState) < 220;

  if (!localConfigSupported) applyGlobally = false;

  if (applyGlobally) {
    modesApplied = prevState.config.modes;
  } else {
    modesApplied = [getModeBySlotSelector(prevState, prevState.slotSelected)];
  }

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  const oldFreezeModeEmg = prevState.config.modes[0].config?.freezeModeEmg;

  // Set common config
  const newCommon = {
    ...prevState.config.common,
    config: {
      ...prevState.config.common.config,
      ...(localConfigSupported && { inputSite: [newInputSite] })
    }
  };

  const newModes = prevState.config.modes.map((mode) => {
    if (modesApplied.find((_mode) => _mode.slot === mode.slot)) {
      return {
        ...mode,
        config: {
          ...mode.config,
          gripSwitchingMode: [newGripSwitchingMode],
          ...(!localConfigSupported && { inputSite: [newInputSite] }),
          ...(oldFreezeModeEmg && {
            freezeModeEmg: freezeModeEmgModifierControlConfig({
              newInputSite,
              versions,
              firmware,
              oldFreezeModeEmg: mode.config.freezeModeEmg
            })
          })
        }
      };
    }
    return mode;
  });
  set(
    {
      config: { common: newCommon, modes: newModes }
    },
    false,
    {
      type: 'setControlConfig',
      common: newCommon,
      modes: newModes,
      arguments: { newInputSite, newGripSwitchingMode }
    }
  );

  // Apply all emg modifiers for new state
  const newState = { ...get() };
  const newModesEmg = newState.config.modes.map((mode) => {
    currentConfig = getCurrentConfigSelector(newState, mode.slot);
    const newEmg = checkEmgValidity(
      currentConfig as DeviceConfigTemplate,
      currentConfig.emgThresholds as emgThresholdsEntry
    );
    return {
      ...mode,
      config: {
        ...mode.config,
        emgThresholds: newEmg
      }
    };
  });
  set(
    {
      config: { common: newCommon, modes: newModesEmg }
    },
    false,
    {
      type: 'checkEmgValidity',
      common: newCommon,
      modes: newModes
    }
  );
};

export const applyModifierModes = (
  modifierFunctions: Array<{ configName: keyof DeviceConfigTemplate; modifierFunction: any }>,
  prevState,
  type,
  modesApplied: [number] | null = null
) => {
  let conflict = false;

  const newConfigModesSettings = prevState.config.modes.map((mode) => {
    if (modesApplied && !modesApplied.includes(mode.slot)) return mode;
    const currentConfig = getCurrentConfigSelector(prevState, mode.slot);
    const configObject = {};
    modifierFunctions.forEach((element) => {
      const { adjustedSetting, triggered } = element.modifierFunction(
        currentConfig as DeviceConfigTemplate
      );
      configObject[`${element.configName}`] = adjustedSetting;
      if (!conflict && triggered) conflict = true;
    });
    return {
      ...mode,
      config: {
        ...mode.config,
        ...configObject
      }
    };
  });
  return { newConfigModesSettings, triggered: conflict };
};

export const inputDeviceModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState,
  currentMode: [number] | null
) => {
  const { inputDevice, inputSite } = currentConfig;
  const { newConfigModesSettings } = applyModifierModes(
    [
      {
        configName: 'gripSwitchingMode',
        modifierFunction: () => {
          if (inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return {
              adjustedSetting:
                inputSite?.[0] === InputSites.kSingleElectrode
                  ? [GripSwitchingModes.kSingleGripSwitching]
                  : [GripSwitchingModes.kCoContraction],
              triggered: false
            };
          return { adjustedSetting: [GripSwitchingModes.kCoapt], triggered: false };
        }
      },
      {
        configName: 'controlMode',
        modifierFunction: () => {
          if (inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return { adjustedSetting: [ControlModes.kGripPairs], triggered: false };
          return { adjustedSetting: [ControlModes.kCoapt], triggered: false };
        }
      }
    ],
    prevState,
    'inputDeviceAdjustment',
    currentMode
  );
  return newConfigModesSettings;
};

const checkBatteryBeepEmergency = (
  currentConfig: DeviceConfigTemplate,
  differenceAllowedPercentage = 5,
  batteryFunctionVoltageToPercent,
  batteryFunctionPercentToVoltage
) => {
  let triggered = false;
  let batteryBeep = currentConfig?.batteryBeep;
  const emergencyBatterySettings = currentConfig?.emergencyBatterySettings;
  const batteryBeepVoltage = batteryBeep?.[0];
  const batteryBeepStatus = batteryBeep?.[1];
  const emergencyVoltage = emergencyBatterySettings?.[2];

  const batteryPercentageBeep = batteryFunctionVoltageToPercent(batteryBeepVoltage);
  const batteryPercentageEmergency = batteryFunctionVoltageToPercent(emergencyVoltage);
  const batteryBeepDifferencePercentage = batteryPercentageBeep - batteryPercentageEmergency;

  if (
    batteryBeepDifferencePercentage < differenceAllowedPercentage &&
    batteryBeep &&
    batteryBeepStatus === 1
  ) {
    const newBatteryBeepVoltage = batteryFunctionPercentToVoltage(
      batteryPercentageEmergency + differenceAllowedPercentage
    );

    batteryBeep = [newBatteryBeepVoltage, batteryBeep[1]];
    triggered = true;
  }

  return { adjustedSetting: batteryBeep, triggered };
};

export const emergencyBatterySettingsModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState
) => {
  const { batteryBeep } = currentConfig;

  if (batteryBeep && batteryBeep?.[1] !== 0) {
    const { triggered, newConfigModesSettings } = applyModifierModes(
      [
        {
          configName: 'batteryBeep',
          modifierFunction: (currentConfig) =>
            checkBatteryBeepEmergency(
              currentConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            )
        }
      ],
      prevState,
      'checkBatteryBeepValidity'
    );

    if (triggered) return newConfigModesSettings;
  }
};

export const batteryBeepModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState
) => {
  const { emergencyBatterySettings } = currentConfig;

  if (emergencyBatterySettings && emergencyBatterySettings?.[0] !== 0) {
    const { triggered, newConfigModesSettings } = applyModifierModes(
      [
        {
          configName: 'batteryBeep',
          modifierFunction: (currentConfig) =>
            checkBatteryBeepEmergency(
              currentConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            )
        }
      ],
      prevState,
      'checkBatteryBeepValidity'
    );

    if (triggered) return newConfigModesSettings;
  }
};
