import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'components/atoms/Typography/Typography';
import ModalBase from './ModalBase';
import useRemoteSession from '../../../hooks/useRemoteSession';

const ParagraphBold = styled.p`
  ${TextS};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: any;
};

const ResetAllChangesModal = ({ handleClose }: ModalProps) => {
  const { consumeAllSessionChanges } = useRemoteSession();
  const accepted = async () => {
    consumeAllSessionChanges();
    handleClose();
  };

  return (
    <ModalBase handleClick={handleClose} header='Reset changes'>
      <DialogContent>
        <ParagraphBold>
          Are you sure want to reset all changes? This cannot be undone.
        </ParagraphBold>
        <ButtonsWrapper>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={accepted}>Reset all</Button>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default ResetAllChangesModal;
