import React from 'react';
import styled from 'styled-components';
import { TypesConfig } from 'types';
import Accordion from '../Accordion/Accordion';

const OptionsList = styled.ul`
  display: grid;
  grid-gap: 16px;
`;

const StatusWrapper = styled.span`
  margin-right: 16px;
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
`;

export const StatusIndicator = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, status }) =>
    !status ? theme.typography.colors.red : theme.typography.colors.green};
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
`;

const ConfigItem = ({ header, items, ...props }: any) => (
  <Accordion header={header} {...props}>
    <OptionsList>
      {items.map((item) =>
        item.type === TypesConfig.bool ? (
          <span>
            <StatusWrapper>{item.header}:</StatusWrapper> <StatusIndicator status={item.value} />
            {item.content}
          </span>
        ) : (
          <span>
            <StatusWrapper>{item.header}:</StatusWrapper>{' '}
            <ValueWrapper>{item.content}</ValueWrapper>
          </span>
        )
      )}
    </OptionsList>
  </Accordion>
);

export default ConfigItem;
