/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { Alert } from '@mui/material';
import Card from 'components/atoms/Card/Card';
import { gripsGroupsOptionsMap, gripsGroupsOptionsReversedMap } from 'utils/definesLocal';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import { gripsImagesMap } from 'utils/gripsImages';
import { postCurrentGrip, postJointTargetPosition } from 'bluetooth-handler/bluetoothFunctions';
import { GRIPS_POST_17, HISTORY_EVENTS } from 'consts/consts';
import DropdownImg from 'components/atoms/Dropdown/DropdownImg';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import { timeoutCommandCustom } from 'utils/funcs';
import { useReplayStore } from 'reducers/replayStore';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { getCurrentConfigSelector } from 'reducers/helpers/selectors';
import useWatch from 'hooks/useWatch';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { Grips } from '../../bluetooth/Bluetooth/Grips';
import {
  AllowText,
  CardsContainer,
  GripImage,
  GripWrapper,
  HandMovementCheckboxWrapper,
  StyledError,
  StyledPositionAdjuster,
  Viewer,
  ViewersWrapper
} from './styled';
import useCanAccess from '../../hooks/useCanAccess';

const sendFingers = async (value, index, { index: fingerIndex }, bluetoothMode) => {
  await postJointTargetPosition(fingerIndex, value, bluetoothMode);
};

const sendFingersThrottled = debounce(sendFingers, 100);

const GripsConfigurationComponent = () => {
  const { gripsPositions } = useConfigStore(getCurrentConfigSelector);
  const {
    handMovementAllowed,
    bluetoothMode,
    currentGrip,
    setItemConfigStore,
    resetGripPositions,
    setConfigProperty,
    addConfigHistory
  } = useConfigStore((state) => ({
    bluetoothMode: state.bluetoothMode,
    handMovementAllowed: state.handMovementAllowed,
    currentGrip: state.currentGrip,
    setItemConfigStore: state.setItemConfigStore,
    resetGripPositions: state.resetGripPositions,
    setConfigProperty: state.setConfigProperty,
    addConfigHistory: state.addConfigHistory
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const [grips, setGrips] = useState<any>(GRIPS_POST_17);
  const [disableInput, setDisableInput] = useState(true);
  const [selectedGrip, setSelectedGrip] = useState(Grips.kGripTypeUnknown);
  useTelemetry(true);
  useWatch(TelemetryController, ['prosthesisGrip', 'gripInTransition']);
  const [values, setValues] = useState(() => {
    const initial = {};
    for (const key in Grips) {
      if (Object.hasOwnProperty.call(Grips, key)) {
        initial[Grips[key]] = {
          initial: [0, 0, 0, 0, 0],
          limit: [0, 0, 0, 0, 0]
        };
      }
    }
    return initial;
  });
  const { canAccess } = useCanAccess({ action: 'gripsConfiguration', resource: 'grips' });
  const { enabled: replayIsEnabled } = useReplayStore();

  useEffect(() => {
    if (
      (TelemetryController.prosthesisGrip || TelemetryController.prosthesisGrip === 0) &&
      handMovementAllowed &&
      TelemetryController.prosthesisGrip !== 255
    ) {
      setItemConfigStore('currentGrip', TelemetryController.prosthesisGrip);
    }
  }, [TelemetryController.prosthesisGrip]);

  // Set grip and finger positions on view enter
  useEffect(() => {
    const setHandPosition = async () => {
      await setHandGripPositions(gripsPositions[currentGrip]);
    };
    if (handMovementAllowed) {
      setHandPosition();
    }
  }, []);

  // Update grips positions from store
  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setValues(gripsPositions);
      setGrips(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              !Number.isNaN(position)
          )
      );
    }
  }, [gripsPositions]);

  // Disable input if grip is undefined
  useEffect(() => {
    const setHandPosition = async () => {
      let breakLoop = false;
      const waitForTransitionEnd = async () => {
        while (TelemetryController.gripInTransition && !breakLoop) {
          await delay(100);
        }
        return true;
      };
      await postCurrentGrip(currentGrip, bluetoothMode);
      await delay(300);
      await timeoutCommandCustom(() => waitForTransitionEnd(), 5000);
      await setHandGripPositions(gripsPositions[currentGrip]);
      breakLoop = true;
    };

    if (currentGrip || currentGrip === 0) {
      if (currentGrip !== Grips.kGripTypeUnknown) {
        setDisableInput(false);
      }
      setSelectedGrip(currentGrip);
      if (handMovementAllowed) {
        setHandPosition();
      }
    }
  }, [currentGrip]);

  const setHandGripPositions = async (gripValues) => {
    if (gripValues?.initial && gripValues?.limit) {
      await postJointTargetPosition(0, gripValues.initial[0], bluetoothMode);
      await postJointTargetPosition(1, gripValues.initial[1], bluetoothMode);
      await postJointTargetPosition(2, gripValues.initial[2], bluetoothMode);
      await postJointTargetPosition(3, gripValues.initial[3], bluetoothMode);
      await postJointTargetPosition(4, gripValues.initial[4], bluetoothMode);
    }
  };

  const handleOptions = async (option) => {
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    setItemConfigStore('currentGrip', newGrip);
  };

  const handleSliderChange = async (value, index, { index: fingerIndex, sliderType, min, max }) => {
    let valueSanitized = value;
    if (typeof valueSanitized === 'string') {
      valueSanitized = parseInt(value, 10);
      if (Number.isNaN(valueSanitized)) valueSanitized = min;
    }
    if (value > max) {
      valueSanitized = max;
    }
    if (value < min) {
      valueSanitized = min;
    }
    let newInitial = values[selectedGrip].initial;
    let newLimit = values[selectedGrip].limit;
    if (sliderType === 'primary') {
      newInitial = newInitial.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newLimit = newLimit.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      initial: newInitial,
      limit: newLimit
    };
    setValues((prev) => ({
      ...prev,
      [selectedGrip]: newGripValues
    }));
  };

  const handleSliderChangeThrottled = debounce(handleSliderChange, 10);

  const handleOnAfterChange = async (...args) => {
    // eslint-disable-next-line no-unused-vars
    const [newValue, _, { index, sliderType }] = args;
    const initialValue = values[selectedGrip].initial[index];
    const limitValue = values[selectedGrip].limit[index];
    const errorMessage =
      "Beginning position is greater than the end position, finger won't be able to move back to beginning position";
    if (sliderType === 'primary') {
      if (newValue > limitValue) {
        toast.error(errorMessage, { id: 'gripsConfigurationPrimary' });
      }
    } else if (newValue < initialValue) {
      toast.error(errorMessage, { id: 'gripsConfigurationSecondary' });
    }
    addConfigHistory(
      HISTORY_EVENTS.gripsConfiguration,
      setConfigProperty('gripsPositions', values)
    );
  };

  return (
    <CardsContainer>
      <GripWrapper as={Card} padding='30px' data-tour-gripsConfig='wrapper'>
        <StyledPositionAdjuster
          values={{
            columnPrimary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].initial,
            columnSecondary: disableInput ? [0, 0, 0, 0, 0] : values[selectedGrip].limit
          }}
          handleSliderChange={(...args) => {
            // @ts-ignore
            handleSliderChangeThrottled(...args);
            if (handMovementAllowed) {
              // @ts-ignore
              sendFingersThrottled(...args, bluetoothMode);
            }
          }}
          handleInputChange={handleSliderChange}
          handleOnAfterChange={handleOnAfterChange}
          handleOnAfterChangeInput={(...args) => {
            handleOnAfterChange(...args);
            if (handMovementAllowed) {
              // @ts-ignore
              sendFingersThrottled(...args, bluetoothMode);
            }
          }}
          rows={['Thumb', 'Index', 'Middle', 'Ring', 'Pinky']}
          columns={['Beginning position', 'End position']}
          hideButton
          limits={[
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 },
            { min: 0, max: 1000 }
          ]}
          disableInput={
            disableInput || replayIsEnabled || !canAccess || TelemetryController.gripInTransition
          }
          buttonText='Restore default grips configuration'
          handleActionButton={() => resetGripPositions(currentGrip)}
        />
      </GripWrapper>
      <div>
        <ViewersWrapper as={Card} padding='30px'>
          <DropdownImg
            options={grips.map((grip) => ({
              value: gripsGroupsOptionsMap.get(grip),
              img: gripsImagesMap.get(grip)
            }))}
            disabled={TelemetryController.gripInTransition}
            selected={{
              value: gripsGroupsOptionsMap.get(selectedGrip),
              img: gripsImagesMap.get(selectedGrip)
            }}
            onChange={(option) => handleOptions(option)}
            showImg={false}
            label='Current grip'
          />
          <Viewer data-tour-gripsConfig='viewer'>
            <GripImage
              data-tour-gripsConfig='viewer'
              src={gripsImagesMap.get(selectedGrip)}
              alt='Graph viewer'
            />
          </Viewer>
          {TelemetryController.gripInTransition && (
            <Alert severity='info'> Grip in transition, please wait.</Alert>
          )}
          {deviceConnected && !disableInput && (
            <HandMovementCheckboxWrapper>
              <div data-tour-gripsConfig='movement'>
                <AllowText>Allow hand movement</AllowText>
                <Checkbox
                  onClick={() => setItemConfigStore('handMovementAllowed', !handMovementAllowed)}
                  id='movement'
                  name='movement'
                  checked={handMovementAllowed}
                />
              </div>
              <StyledError show={handMovementAllowed}>
                Make sure hand is in safe position!
              </StyledError>
            </HandMovementCheckboxWrapper>
          )}
        </ViewersWrapper>
      </div>
    </CardsContainer>
  );
};

export default GripsConfigurationComponent;
