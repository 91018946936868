/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import useCompatibilities from 'hooks/useCompatibilities';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { StyledCheck, StyledTimes } from '../FirmwareModal';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

type PcbReplacementFinishProps = {
  handleFinish: any;
  handleFirmware: any;
};

const PcbReplacementFinish = ({ handleFinish, handleFirmware }: PcbReplacementFinishProps) => {
  const versions = useDeviceInfoStore((state) => state.versions);
  const { availableFirmwares, isFirmwareUpdateAvailable, isFirmwareUpdateNeeded } =
    useCompatibilities();

  const firmwareVersionCurrentParsed = `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`;

  return (
    <DialogContent>
      <Wrapper>
        <InfoWrapper>
          <div>Device version: {firmwareVersionCurrentParsed}</div>
          <div>Newest version: {availableFirmwares?.[0]?.name}</div>
          {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
            <>
              (Outdated) <StyledTimes />
            </>
          ) : (
            <>
              <StyledCheck />
            </>
          )}
        </InfoWrapper>
        <ButtonsWrapper>
          {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
            <Button type='button' onClick={handleFirmware}>
              Update firmware
            </Button>
          ) : (
            <Button type='button' onClick={handleFinish}>
              Finish repair
            </Button>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </DialogContent>
  );
};

export default PcbReplacementFinish;
