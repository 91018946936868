import React, { Component, ReactNode } from 'react';

import { ErrorContainer } from './styled';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorContainer>
          <p>We are sorry.</p>
          <p>Something went wrong.</p>
        </ErrorContainer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
