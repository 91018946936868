import React from 'react';
import { DialogContent } from '@mui/material';
import ModalBase from './ModalBase';

const PreventInputModal = () => (
  <ModalBase handleClick={() => null} header='' basicModal allowBackDropClose={false}>
    <DialogContent>Please wait...</DialogContent>
  </ModalBase>
);

export default PreventInputModal;
