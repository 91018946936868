import { last } from 'lodash';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { SessionStatus } from 'api/liveSession/liveSession.types';
import { useConfigStore } from 'reducers/configStore';
import { REMOTE_SESSION_DEBUG } from 'consts/consts';
import { ablyClient } from '../utils/LiveConfigurator/AblyClient';
import { useLiveSession } from './api/useLiveSession';

interface ModeType {
  id: number;
  slot: number;
  name: string;
}

const useRemoteSession = () => {
  const {
    channel,
    enabled,
    sessionApi,
    sessionRestorePoints,
    setItemLiveConfigurator,
    setLiveSessionClosed
  } = useLiveConfiguratorStore((state) => ({
    channel: state.channel,
    enabled: state.enabled,
    sessionApi: state.sessionApi,
    sessionRestorePoints: state.sessionRestorePoints,
    setItemLiveConfigurator: state.setItemLiveConfigurator,
    setLiveSessionClosed: state.setLiveSessionClosed
  }));
  const { config, setConfigCopy, getInitialConfigAPI, clearConfigHistory, importConfig } =
    useConfigStore((state) => ({
      config: state.config,
      setConfigCopy: state.setConfigCopy,
      getInitialConfigAPI: state.getInitialConfigAPI,
      clearConfigHistory: state.clearConfigHistory,
      importConfig: state.importConfig
    }));
  const client = ablyClient(sessionApi?.clinician_uuid);
  const channelsAbly = client.channels.get(channel.name);
  const { updateSession } = useLiveSession();

  const sendConfig = async () => {
    if (!enabled) {
      return;
    }
    const modesPayload = config.modes?.map((mode) => ({
      id: mode.id,
      config: mode.config,
      slot: mode.slot
    }));
    const configSession = {
      common: config.common.config,
      modes: modesPayload
    };
    setConfigCopy();
    channelsAbly.publish('config', JSON.stringify(configSession));
    console.log(configSession, 'SESSION CONFIG');
  };

  const sendModeChange = async (mode: ModeType) => {
    if (!enabled) {
      return;
    }
    if (REMOTE_SESSION_DEBUG) console.log('CHANGE MODE SESSION', mode);
    channelsAbly.publish('current_mode', JSON.stringify(mode));
  };

  const sendTempEmg = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('emg', message);
  };

  const sendMeeting = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('meeting', message);
  };

  const sendCloseMeeting = async () => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('close_meeting_mobile', '');
  };

  const sendMeetingSummary = async (message) => {
    if (!enabled) {
      return;
    }
    channelsAbly.publish('summary', message);
  };

  const consumeAllSessionChanges = (refreshConfig = true) => {
    clearConfigHistory();

    if (refreshConfig) {
      getInitialConfigAPI();
    }
  };

  const rollbackToLastRestorePoint = () => {
    importConfig(last(sessionRestorePoints));
    consumeAllSessionChanges();
    sendConfig();
  };

  const enableLiveSession = (token: string, sessionId: number) => {
    setItemLiveConfigurator('enabled', true);
    setItemLiveConfigurator('channel', { name: token, connected: false, id: sessionId });
    consumeAllSessionChanges();
  };

  const disconnect = async (notifyMobile: boolean) => {
    setLiveSessionClosed();
    if (channel.id) {
      await updateSession({ id: channel.id, status: SessionStatus.closed });
    }
    consumeAllSessionChanges();
    channelsAbly.presence.unsubscribe();
    channelsAbly.unsubscribe();
    if (notifyMobile) channelsAbly.publish('close_session', 'close');
  };

  const configIsFetched = () => {
    setItemLiveConfigurator('configFetched', true);
  };

  return {
    rollbackToLastRestorePoint,
    sendConfig,
    disconnect,
    sendTempEmg,
    enableLiveSession,
    consumeAllSessionChanges,
    configIsFetched,
    sendMeeting,
    sendMeetingSummary,
    sendModeChange,
    sendCloseMeeting
  };
};

export default useRemoteSession;
