import { LoadingButton } from '@mui/lab';
import { Button, DialogContent } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ModalPortal from 'utils/Modal/ModalPortal';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 16px;
`;

type DeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  text: string;
  id: string;
  header: string;
  isLoading?: boolean;
};

const DeleteModal = ({
  handleClose,
  handleAccept,
  text,
  id,
  header,
  isLoading = false
}: DeleteModalProps) => (
  <ModalPortal>
    <ModalBase handleClick={handleClose} header={header}>
      <DialogContent>
        <div>
          <p>
            {text}
            <strong>{id}</strong>? This action cannot be undone.
          </p>
        </div>
        <ButtonsWrapper>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <LoadingButton color='error' onClick={handleAccept} loading={isLoading}>
            Delete
          </LoadingButton>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  </ModalPortal>
);

export default DeleteModal;
