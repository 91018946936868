import React, { useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Save } from 'assets/sign-in-alt-solid.svg';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Restore } from 'assets/restore.svg';
import useSidebar from 'hooks/useSidebar';

const IconsContainer = styled.div`
  width: 50px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 99;
  display: grid;
  grid-gap: 20px;
`;

const SavedContainer = styled.div`
  position: relative;
`;

const pulseAnimation = keyframes`
  to {
		color: #4DC1FF;
	}
`;

const StyledSaved = styled(Save)`
  ${({ unsaved }) =>
    unsaved &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    `}
`;

type IconsSidebarProps = {
  undoFunc?: Function | boolean;
  unsaved?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &&:hover {
    transform: scale(1.04, 1.04);
    transition: transform 0.2s;
  }
`;

const StyledIcon = styled.div`
  height: 27px;
  width: 27px;
  object-fit: fill;
  color: ${({ theme }) => theme.colorPrimary};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconDescribed = ({ children, description, ...props }) => (
  <Wrapper {...props}>
    <StyledIcon>{children}</StyledIcon>
  </Wrapper>
);

const IconsSidebarMinimal = ({ undoFunc = () => true, unsaved = false }: IconsSidebarProps) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const { handleSave, handleRestoreRecent, ticketCanBeSent, canShowSend } = useSidebar();

  return (
    <IconsContainer data-tour-general='icons-sidebar' ref={ref}>
      {canShowSend && ticketCanBeSent && (
        <>
          <ReactTooltip id='save' data-tour-general='save' place='right' type='info' effect='solid'>
            <span>{t('utils.send')}</span>
          </ReactTooltip>
          <SavedContainer data-tip data-for='save'>
            <IconDescribed description='Send to prosthesis' onClick={() => handleSave(unsaved)}>
              <StyledSaved unsaved={unsaved} />
            </IconDescribed>
          </SavedContainer>
        </>
      )}
      <>
        <ReactTooltip id='restore' place='right' type='info' effect='solid'>
          <span>Restore recent config</span>
        </ReactTooltip>
        <IconDescribed
          onClick={handleRestoreRecent}
          description='Restore recent config'
          data-tip
          data-for='restore'>
          <Restore />
        </IconDescribed>
      </>
      {/* {device.connected && (
        <>
          <ReactTooltip id='hard-restore' place='right' type='info' effect='solid'>
            <span>Hard restore from device</span>
          </ReactTooltip>
          <IconDescribed
            onClick={restoreConfigDevice}
            description='Hard restore from device'
            data-tip
            data-for='hard-restore'>
            <Restore />
          </IconDescribed>
        </>
      )} */}
      {undoFunc && (
        <>
          <ReactTooltip id='undo' place='right' type='info' effect='solid'>
            <span>{t('utils.undo')}</span>
          </ReactTooltip>
          <IconDescribed onClick={undoFunc} description='Undo' data-tip data-for='undo'>
            <Undo />
          </IconDescribed>
        </>
      )}
    </IconsContainer>
  );
};

export default IconsSidebarMinimal;
