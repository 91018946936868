import React from 'react';
import { useQueryClient } from 'react-query';
import { DialogContent } from '@mui/material';
import NotesList from 'components/organisms/NotesList/NotesList';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote
} from 'hooks/api/useDevice';
import { ConfigNotesSortOptions } from 'api/device/device.types';
import { SortDirs } from 'types';
import ModalBase from './ModalBase';

type ShowNotesModalProps = {
  handleClose: Function;
  deviceId: number;
  configId: number;
};

const ShowNotesModal = ({ handleClose, deviceId, configId }: ShowNotesModalProps) => {
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({ deviceId, configId: Number(configId), data: { note, type } });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId, configId: Number(configId), noteId });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  return (
    <ModalBase handleClick={handleClose} header='Notes' fullWidth>
      <DialogContent>
        <NotesList
          notes={notes}
          handleAdd={handleAddNote}
          handleDelete={handleDeleteNote}
          isLoadingAddNote={isLoadingCreateNote}
        />
      </DialogContent>
    </ModalBase>
  );
};

export default ShowNotesModal;
