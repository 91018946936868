import styled from 'styled-components';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { ReactComponent as StopIcon } from 'assets/stop-sm.svg';

export const SessionWrapper = styled.div`
  background-color: rgba(255, 193, 7, 0.16);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

export const SessionText = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #d48f27;
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 14px;
  height: 14px;
`;

export const StyledStopIcon = styled(StopIcon)`
  position: relative;
  margin-right: 5px;
  width: 14px;
  height: 14px;
`;

export const DropdownWrapper = styled.div`
  margin: 0 20px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-area: buttons;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-gap: 20px;
  }
`;
