import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import useSynchronizeBluetooth from 'hooks/bluetooth/useSynchronizeBluetooth';
import { useConfigStore } from 'reducers/configStore';
import { useUiStore } from 'reducers/uiStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { MODALS } from 'views/Modals';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import {
  useImportTemplate,
  useRestoreConfig,
  useSendTestConfig,
  useUpdateDeviceConfig
} from './useDevice';

export const useDeviceManager = () => {
  const { updateConfig, isLoading: isLoadingUpdateDeviceConfig } = useUpdateDeviceConfig();
  const { sendTest, isLoading: isLoadingSendTest } = useSendTestConfig();
  const { mutateAsync: restoreConfig, isLoading: isLoadingRestoreConfig } = useRestoreConfig();
  const { mutateAsync: importTemplate, isLoading: isLoadingImportTemplate } = useImportTemplate();
  const { deviceId, deviceConnected } = useDeviceInfoStore((state) => ({
    deviceId: Number(state.deviceId),
    deviceConnected: state.connected
  }));
  const { amputeeId } = useDeviceInfoStore((state) => ({ amputeeId: state.amputeeId }));
  const { getInitialConfig, config, getInitialConfigAPI } = useConfigStore((state) => ({
    config: state.config,
    getInitialConfig: state.getInitialConfig,
    getInitialConfigAPI: state.getInitialConfigAPI,
    importConfig: state.importConfig
  }));
  const { synchronizeConfig } = useSynchronizeBluetooth();
  const { openModal, setItemUiStore } = useUiStore((state) => ({
    openModal: state.openModal,
    setItemUiStore: state.setItemUiStore
  }));
  const { enabled: sessionEnabled, channel: sessionChannel } = useLiveConfiguratorStore(
    (state) => ({
      enabled: state.enabled,
      channel: state.channel
    })
  );

  const sendConfigToDevice = async () => {
    try {
      if (deviceId) {
        await synchronizeConfig(config, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const retrieveConfig = async () => {
    openModal(MODALS.restoreRecent);
  };

  const handleChangingConfigState = async (updatedConfig) => {
    try {
      setItemUiStore('shownGlobalModal', 'prevent');
      const configPayload = {
        deviceId,
        data: {
          common: JSON.stringify(updatedConfig.common),
          modes: updatedConfig.modes.map((mode) => ({
            id: mode.id,
            config: JSON.stringify(mode.config)
          }))
        }
      };
      const newConfigComplete = await updateConfig(configPayload);
      await getInitialConfigAPI();

      const { common, modes } = await getInitialConfig();
      const newConfig = {
        common: { config: common, configAPI: newConfigComplete?.common },
        modes
      };
      await synchronizeConfig(newConfig);
      setItemUiStore('shownGlobalModal', null);
    } catch (err) {
      console.log(err);
      setItemUiStore('shownGlobalModal', null);
    }
  };

  const restoreConfigHistory = async (configId: number) => {
    const updatedConfig = await restoreConfig({
      deviceId,
      configId
    });
    if (!deviceConnected) {
      toast('Config will be imported after patient accepts changes in the mobile application', {
        duration: 5000,
        icon: '❕'
      });
      return;
    }
    const withTicket = updatedConfig?.messages?.[0];
    const newConfig = withTicket
      ? JSON.parse(updatedConfig?.messages?.[0].attachments[1].attachment)
      : updatedConfig;
    handleChangingConfigState(newConfig);
  };

  const importTemplateConfig = async (modeId: number, templateId: number) => {
    const updatedConfigMessage = await importTemplate({ deviceId, modeId, templateId });
    if (!deviceConnected) {
      toast('Template will be imported after patient accepts changes in the mobile application', {
        duration: 5000,
        icon: '❕'
      });
      return;
    }
    const withTicket = updatedConfigMessage?.messages?.[0];
    const newConfig = withTicket
      ? JSON.parse(updatedConfigMessage?.messages?.[0].attachments[1].attachment)
      : updatedConfigMessage;
    handleChangingConfigState(newConfig);
  };

  const sendConfigOnlyToApi = async (description?) => {
    try {
      setItemUiStore('shownGlobalModal', 'prevent');
      const commonConfig = config.common.config;
      let sendTestConfigResponse;

      if (deviceId && !isEmpty(commonConfig)) {
        const modesPayload = config.modes.map((mode) => ({
          id: mode.id!,
          config: JSON.stringify(mode.config)
        }));

        const configPayload = {
          deviceId,
          data: {
            common: JSON.stringify(commonConfig),
            modes: modesPayload
          }
        };

        if (amputeeId) {
          sendTestConfigResponse = await sendTest({
            deviceId: configPayload.deviceId,
            data: {
              ...configPayload.data,
              description,
              p2p_session: sessionEnabled ? sessionChannel.id : null
            }
          });
        }

        if (deviceConnected) {
          await updateConfig(configPayload);
          await getInitialConfigAPI();
        }

        toast.success('Device configuration updated', {
          id: 'deviceConfigurationUpdatedToast'
        });
      }
      setItemUiStore('shownGlobalModal', null);
      return sendTestConfigResponse;
    } catch (error) {
      toast.error('Updating device failed', {
        id: 'deviceConfigurationUpdatedFailToast'
      });
      setItemUiStore('shownGlobalModal', null);
      console.log('error', error);
      return null;
    }
  };

  return {
    sendConfigToDevice,
    restoreConfigHistory,
    importTemplateConfig,
    sendConfigOnlyToApi,
    retrieveConfig,
    isLoadingDeviceManager:
      isLoadingUpdateDeviceConfig ||
      isLoadingRestoreConfig ||
      isLoadingImportTemplate ||
      isLoadingSendTest
  };
};
