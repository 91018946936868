/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Alert, Link, Typography } from '@mui/material';
import useLogout from 'hooks/api/useLogout';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FETCHING_STATES } from 'consts/consts';
import { errorApiNotification, errorApiNotificationParse } from 'utils/notifications';
import { ConnectionAnimation } from 'components/atoms/ConnectionAnimation/ConnectionAnimation';
import { ADD_DEVICE_ERRORS, CUSTOM_ERRORS } from '../utils';
import { AlertParagraph, AnimationWrapper } from '../styled';

const FooterWrapper = styled.div`
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const RegisterFooter = ({ token, loading }) => {
  const { logout } = useLogout();

  return (
    <FooterWrapper>
      {token && !loading && (
        <Link onClick={logout} sx={{ cursor: 'pointer' }}>
          Logout
        </Link>
      )}
      <Typography variant='body2'>{dayjs().format('YYYY')} © Aether Digital Platform</Typography>
    </FooterWrapper>
  );
};

export const ConnectionErrors = ({
  showConnectionError,
  deviceExistsInAdp,
  connectionState,
  checkSerialError
}) => {
  if (
    (showConnectionError || deviceExistsInAdp === false || checkSerialError) &&
    connectionState !== FETCHING_STATES.loading
  ) {
    const handleMessage = () => {
      if (checkSerialError) return errorApiNotificationParse(checkSerialError).message;
      if (showConnectionError) return CUSTOM_ERRORS.CONNECTION_ERROR;
      if (deviceExistsInAdp === false) return CUSTOM_ERRORS.DEVICE_NOT_EXIST;

      return 'Something went wrong';
    };

    return <ErrorMessage message={handleMessage()} errors={null} />;
  }

  return null;
};

export const ErrorMessage = ({
  message,
  errors = []
}: {
  message: string;
  errors?: string[] | null;
}) => (
  <Alert severity='error' sx={{ whiteSpace: 'pre-line' }}>
    <h3>
      <b>{message}</b>
    </h3>
    {errors?.map((error) => (
      <AlertParagraph>{error}</AlertParagraph>
    ))}
  </Alert>
);

const formatError = (err) => {
  if (err?.request?.status === 304) {
    return ADD_DEVICE_ERRORS.DEVICE_ASSIGNED_ANOTHER_CLINICIAN;
  }
  if (errorApiNotification(err) === ADD_DEVICE_ERRORS.DEVICE_ASSIGNED_ANOTHER_COMPANY) {
    return 'Activation code is no longer valid';
  }
  return errorApiNotification(err);
};

export const AddDeviceErrorComponent = ({ errorAddDevice }) => (
  <Alert severity='error'>
    <h3>
      <b>{formatError(errorAddDevice)}</b>
    </h3>
    {errorApiNotification(errorAddDevice) === ADD_DEVICE_ERRORS.ACTIVATION_CODE_INCORRECT && (
      <AlertParagraph>
        Please check it and enter it again or contact us at{' '}
        <Link href='mailto:support@aetherbiomedical.com'>support@aetherbiomedical.com</Link>.
      </AlertParagraph>
    )}
    {errorApiNotification(errorAddDevice) === ADD_DEVICE_ERRORS.DEVICE_ASSIGNED_ANOTHER_COMPANY && (
      <AlertParagraph>
        Please contact Aether Biomedical at{' '}
        <Link href='mailto:support@aetherbiomedical.com'>support@aetherbiomedical.com</Link>
      </AlertParagraph>
    )}
  </Alert>
);

export const ConnectionLoading = ({
  waitText = 'Please wait for the device to connect.',
  showConnectionText = true
}) => (
  <>
    <Typography variant='body2'>{waitText}</Typography>
    <AnimationWrapper>
      <ConnectionAnimation />
      {showConnectionText && <p>Connecting...</p>}
    </AnimationWrapper>
  </>
);
