import { useMutation, useQuery } from 'react-query';
import {
  checkMfaStatus,
  fetchUserData,
  loginUserSpa,
  registerUser,
  sendMfaCode,
  verifyMfaCode
} from 'api/authentication/authentication';
import { useAuthStore } from 'reducers/authStore';
import { useState } from 'react';
import { MfaOptions, MfaRequire } from 'api/authentication/authentication.types';
import { errorApiNotification, errorNotification } from 'utils/notifications';
import useRefreshToken from './useRefreshToken';

export const USER_QUERY_MFA = 'mfa';
export const useAuthentication = () => {
  const broadcast = new BroadcastChannel('login');
  const [is2FaPage, setIs2FaPage] = useState(false);
  const { refreshToken } = useRefreshToken();
  const { mfaCodeRequire, mfaData } = useAuthStore((state) => ({
    mfaCodeRequire: state.mfaCodeRequire,
    mfaData: state.mfa
  }));
  const { isLoading: isLoadingSendCode, mutate: sendCode } = useMutation(sendMfaCode);
  const data: MfaRequire = {
    require: false,
    channel: null
  };
  const {
    isLoading: isLoadingVerify,
    isError: isErrorVerify,
    mutate: verifyCode,
    error: errorVerifyCode
  } = useMutation(verifyMfaCode, {
    onSuccess: async () => {
      Promise.all([refreshToken, fetchUserData]).finally(() => {
        mfaCodeRequire(data);
        broadcast.postMessage({ typ: 'LOG_IN' });
      });
    },
    onError() {
      errorNotification(
        'We\'re sorry, but the MFA code you entered is no longer valid or invalid. To continue, please click on "Resend code" to generate a new MFA code that will be valid for 15 minutes.'
      );
    }
  });
  const {
    isLoading: isLoadingLogin,
    mutate: login,
    error: errorLogin
  } = useMutation(loginUserSpa, {
    onSuccess: async () => {
      try {
        refreshToken().finally(() => {
          broadcast.postMessage({ typ: 'LOG_IN' });
        });
        await fetchUserData();
      } catch (error: any) {
        if (error.isAxiosError) {
          if (error.response.status === 401 && error.response?.data?.method) {
            sendCode({
              channel: error.response.data.method
            });

            mfaCodeRequire({
              require: true,
              channel: error.response.data.method
            });
            setIs2FaPage(true);
          }
        }
      }
    },
    onError(error: any) {
      if (error.isAxiosError && error.response.status === 403) {
        errorNotification('This account is a temporary lock');
        return;
      }
      errorNotification('Given credentials not found');
    }
  });

  const reSendCode = (method = undefined) => {
    sendCode({
      channel: method ?? mfaData.channel ?? MfaOptions.email
    });
  };

  return {
    isLoading: isLoadingSendCode || isLoadingLogin || isLoadingVerify,
    reSendCode,
    is2FaPage,
    login,
    verifyCode,
    isErrorVerify,
    mfaData,
    errorLogin,
    errorVerifyCode
  };
};

export const useMfaMethod = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_QUERY_MFA],
    checkMfaStatus,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError() {
        errorNotification('Failed to fetch MFA status');
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useRegisterUser = () => {
  const { data, mutateAsync, isLoading, isError, error, isSuccess } = useMutation(registerUser, {
    onError: (err) => {
      errorApiNotification(err);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError,
    error,
    isSuccess
  };
};
