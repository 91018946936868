import { AxiosResponse } from 'axios';
import { CompatibilityEntry, CompatibilitiesQueryParams, PCBVersionEntry } from './versions.types';
import { encodeQueryData } from '../utils/encodeQuery';
import api from '../utils/apiClient';
import { PaginatedResponse } from '../../types';

export const GET_COMPATIBILITIES = 'api/versions/compatibility';
export const GET_PCB_VERSION = 'api/versions/pcb';

export const withCredentials = {
  withCredentials: true
};

export const withCredentialsAndMultipartForm = {
  ...withCredentials,
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export const getPCBVersionAPI = (): Promise<any> =>
  api.get<PCBVersionEntry>(`${GET_PCB_VERSION}`, withCredentials).then((res: any) => res.data);

export const getCompatibilities = ({
  params = {}
}: {
  params?: CompatibilitiesQueryParams;
}): Promise<any> =>
  api
    .get<PaginatedResponse<CompatibilityEntry>>(
      `${GET_COMPATIBILITIES}?${encodeQueryData({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);
