/* eslint-disable no-unused-vars */
import React from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

type ModalBaseProps = {
  handleClick?: Function;
  header: string;
  children?: any;
  basicModal?: boolean;
  hideCloseButton?: boolean;
  allowBackDropClose?: boolean;
  [key: string]: any;
};

const ModalBase = ({
  handleClick = () => false,
  header,
  children,
  basicModal = false,
  hideCloseButton = false,
  allowBackDropClose = true,
  ...props
}: ModalBaseProps) => (
  <Dialog open onClose={allowBackDropClose ? () => handleClick() : () => false} {...props}>
    {!basicModal && (
      <DialogTitle>
        {header}
        {hideCloseButton ? null : (
          <IconButton
            onClick={() => handleClick()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
    )}
    <span />
    {children}
  </Dialog>
);

export default ModalBase;
