import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';
import { HeaderM } from 'components/atoms/Typography/Typography';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { parseBluetoothFirmware } from 'utils/funcs';
import ModalBase from './ModalBase';

const HandDetailsWrapper = styled.div`
  width: 350px;
`;

const Header1 = styled.h3`
  ${HeaderM};
`;

const Regular = styled.span`
  font-weight: 400;
`;

const HandDetails = ({ handleClose }) => {
  const { serial, versions } = useDeviceInfoStore((state) => ({
    versions: state.versions,
    serial: state.serial
  }));

  return (
    <ModalBase handleClick={handleClose} header='Hand details'>
      <DialogContent>
        <HandDetailsWrapper>
          <Header1>
            Serial number: <Regular>{serial}</Regular>
          </Header1>
          <Header1>
            Firmware version: <Regular>{parseBluetoothFirmware(versions?.current)}</Regular>
          </Header1>
        </HandDetailsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

HandDetails.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default HandDetails;
