import axios from 'axios';
import api, { withCredentials } from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import { SupportTicketEntry, SupportTicketQueryParams } from './tickets.types';

export const GET_TICKET = 'api/ticket/{ticketId}';

export const getTicket = ({
  ticketId,
  params = {}
}: {
  ticketId: number;
  params: SupportTicketQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData(params);
  return api
    .get<SupportTicketEntry>(
      `${GET_TICKET.replace(`{ticketId}`, `${ticketId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const getTicketConfig = (configUrl: string): Promise<any> =>
  axios.get(configUrl, withCredentials);
