/* eslint-disable prefer-destructuring */
import React, { useMemo } from 'react';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { genericSwitchingMap, genericSwitchingReversedMap } from 'utils/definesLocal';
import { ConfigStoreState } from 'reducers/configStore';
import { freezeModeEmgEntry } from 'consts/deviceConfig/deviceConfig.types';

type FreezeModeEmgProps = {
  addHistoryProsthesis: any;
  freezeModeEmg: freezeModeEmgEntry;
  freezeModeSwitchPermission: boolean;
  replayIsEnabled: boolean;
  setConfigProperty: ConfigStoreState['setConfigProperty'];
};

const FreezeModeEMG = ({
  addHistoryProsthesis,
  freezeModeEmg,
  freezeModeSwitchPermission,
  replayIsEnabled,
  setConfigProperty
}: FreezeModeEmgProps) => {
  const handleFreezeModeEmgStatus = (type, value) => {
    addHistoryProsthesis(
      setConfigProperty('freezeModeEmg', [
        // @ts-ignore
        genericSwitchingReversedMap.get(value),
        freezeModeEmg[0],
        freezeModeEmg[1]
      ])
    );
  };

  const FreezeModeEMG = useMemo(
    () => (
      <InputOptions
        header='EMG freeze mode switch'
        options={['On', 'Off']}
        id='freezeModeEmg'
        onChange={handleFreezeModeEmgStatus}
        disable={replayIsEnabled || !freezeModeSwitchPermission}
        // @ts-ignore
        value={genericSwitchingMap.get(freezeModeEmg?.[2] ? 1 : 0)}
        description='Allow switching freeze mode using EMG signals'
      />
    ),
    [freezeModeEmg, freezeModeSwitchPermission]
  );

  return FreezeModeEMG;
};

export default FreezeModeEMG;
