/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceUpdate } from 'hooks/api/useDevice';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { PCBVersionEntry } from 'api/versions/versions.types';
import ErrorLabel from 'components/atoms/Error/ErrorLabel';
import Loader from 'components/atoms/Loader/Loader';
import { useUiStore } from 'reducers/uiStore';
import { FETCHING_STATES } from 'consts/consts';
import { StyledCheck, StyledTimes } from '../FirmwareModal';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  justify-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

type PcbReplacementConnectionProps = {
  handlePrevious: Function;
  handleNext: any;
  bluetoothId: string;
  pcb: PCBVersionEntry;
};

const PcbReplacementConnection = ({
  handlePrevious,
  handleNext,
  bluetoothId,
  pcb
}: PcbReplacementConnectionProps) => {
  const { bluetoothPcbCheck } = useBluetooth();
  const { deviceConnected, pcbVersion, deviceId, serial } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    pcbVersion: state.pcbVersion,
    deviceId: state.deviceId,
    serial: state.serial
  }));
  const { mutateAsync: updateDevice, isSuccess, isLoading } = useDeviceUpdate();
  const disconnectDevice = useConfigStore((state) => state.disconnectDevice);
  const connectionState = useUiStore((state) => state.connectionState);

  const versionsMatch = Number(pcb.name[0]) === Number(pcbVersion);

  const connect = async () => {
    const deviceData = await bluetoothPcbCheck(bluetoothId);
    if (!deviceData) return;
    const { connected, serialNumber, pcbVersion: pcbBluetooth } = deviceData;
    if (connected && Number(pcb.name[0]) === pcbBluetooth) {
      updateDevice({
        deviceId: Number(deviceId),
        data: {
          serial: serialNumber,
          pcb_version_id: pcb.id,
          bluetooth_id: bluetoothId
        }
      });
    }
  };

  const handleBack = async () => {
    if (deviceConnected) await disconnectDevice();
    handlePrevious();
  };

  const showError = (!isSuccess || !versionsMatch) && deviceConnected && !isLoading;

  return (
    <DialogContent>
      <Wrapper>
        <p>
          After replacing the PCB, connect to the device. If the hand does not show up on the list,
          go back to previous step, and change bluetooth ID.
        </p>
        {serial && (
          <InfoWrapper>
            <div>Serial number: {serial}</div>
          </InfoWrapper>
        )}
        {pcbVersion && (
          <InfoWrapper>
            <div>PCB version: v{pcbVersion}.X</div>
            <div>Selected PCB version: v{pcb.name}</div>
            {versionsMatch ? (
              <>
                (Match) <StyledCheck />
              </>
            ) : (
              <>
                (No match) <StyledTimes />
              </>
            )}
          </InfoWrapper>
        )}
        {showError && (
          <ErrorLabel>
            There was an error when updating device information. Check if the PCB versions match, if
            not go back to previous step to change it and reconnect.
          </ErrorLabel>
        )}
        {isLoading ? (
          <Loader style={{ margin: '0 auto' }} />
        ) : (
          <>
            {!isSuccess && (
              <LoadingButton
                type='button'
                onClick={deviceConnected ? disconnectDevice : connect}
                loading={connectionState === FETCHING_STATES.loading}
                sx={{ width: '150px' }}>
                <span> {deviceConnected ? 'Disconnect' : 'Connect'}</span>
              </LoadingButton>
            )}
            <ButtonsWrapper>
              {!isSuccess && (
                <Button type='button' onClick={handleBack} variant='outlined'>
                  Previous step
                </Button>
              )}
              {isSuccess && versionsMatch && deviceConnected && (
                <Button type='button' onClick={handleNext}>
                  Next step
                </Button>
              )}
            </ButtonsWrapper>
          </>
        )}
      </Wrapper>
    </DialogContent>
  );
};

export default PcbReplacementConnection;
