import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DefaultLayout from 'layouts/DefaultLayout';
import Divider from 'components/atoms/Divider/Divider';
import { ReactComponent as Arrow } from 'assets/arrow-left-solid.svg';
import { HeaderM, HeaderXL } from 'components/atoms/Typography/Typography';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useSettingsStore } from 'reducers/settingsStore';

const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  max-width: 800px;

  > * {
    padding-top: 20px;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Arrow)`
  width: 36px;
  height: 41px;
  color: ${({ theme }) => theme.colorSecondary};
  margin-right: 25px;
  cursor: pointer;
`;

const DisableControl = styled.div`
  ${({ disable }) => disable && DisableFunctionality};
`;

const ApplicationSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { bluetoothMode, setItemConfigStore, getInitialConfigAPI } = useConfigStore((state) => ({
    bluetoothMode: state.bluetoothMode,
    setItemConfigStore: state.setItemConfigStore,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const { demoMode, setItemSettingsStore } = useSettingsStore((state) => ({
    demoMode: state.demoMode,
    setItemSettingsStore: state.setItemSettingsStore
  }));

  const handleBluetoothChange = (id, value) => {
    setItemConfigStore('bluetoothMode', value.toLowerCase());
  };

  const handleDemoModeChange = (id, value) => {
    setItemSettingsStore('demoMode', value === 'On');
    if (value === 'On') getInitialConfigAPI();
  };

  return (
    <DefaultLayout>
      <InnerWrapper onClick={history.goBack}>
        <StyledIcon />
        <Header1>{t('settings.app_settings')}</Header1>
      </InnerWrapper>
      <Divider margin='40px' />
      <SettingsContainer>
        <DisableControl disable={deviceConnected}>
          <InputOptions
            header={t('settings.bluetooth_mode')}
            options={['Classic', 'BLE']}
            id='bluetooth'
            onChange={handleBluetoothChange}
            value={bluetoothMode === 'classic' ? 'Classic' : 'BLE'}
          />
        </DisableControl>
        <DisableControl disable={!deviceConnected}>
          <InputOptions
            header='Send changes immediately'
            options={['On', 'Off']}
            id='demoMode'
            onChange={handleDemoModeChange}
            value={demoMode ? 'On' : 'Off'}
          />
        </DisableControl>
        <div>
          <div>
            <ParagraphBold>
              Zeus Configurator ADP v.{process.env.REACT_APP_SOFTWARE_VERSION}
            </ParagraphBold>
            <Divider margin='20px' />
            <p>Copyright (C) {dayjs().format('YYYY')} Aether Biomedical Sp. z o.o.</p>
            <Divider margin='20px' />
            <p>
              The program is provided AS IS with NO WARRANTY OF ANY KIND, INCLUDING THE WARRANTY OF
              DESIGN, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </p>
          </div>
        </div>
      </SettingsContainer>
    </DefaultLayout>
  );
};

export default ApplicationSettings;
