export interface LiveSessionInitializePayload {
  amputee_id: number;
  amputee_uuid: string;
  clinician_uuid: string;
  status: string;
}

export interface P2PSession {
  id: number;
  amputee_id: number;
  amputee_uuid: string;
  clinician_id: number;
  clinician_uuid: string;
  token: string;
  is_closed: boolean;
}

export interface VideoMeetingEntry {
  id: number;
  moderator_id: number;
  guest_id: number;
  room_name: string;
  jwt_guest: string;
  jwt_moderator: string;
  expires: number;
  status: 'open';
  created_at: Date;
  updated_at: Date;
}

export enum SessionStatus {
  waitingForDecision = 'waiting_for_decision',
  inProgress = 'in_progress',
  closed = 'closed'
}
