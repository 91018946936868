/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { Button, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';
import Card from 'components/atoms/Card/Card';
import { transformAndApply } from 'utils/Config/transformConfig';
import { useUiStore } from 'reducers/uiStore';
import { MODALS } from 'views/Modals';
import { DeviceConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import ConfigComponent from 'components/organisms/ConfigComponent/ConfigComponent';
import { useConfigStore } from 'reducers/configStore';
import { Header1, HeaderWrapper } from '../styled';
import Divider from '../../../components/atoms/Divider/Divider';
import Accordion from '../../../components/atoms/Accordion/Accordion';
import { configSettingsNamesMap, gripsGroupsOptionsMap } from '../../../utils/definesLocal';
import useRemoteSession from '../../../hooks/useRemoteSession';
import { HistoryConfiguratorContainer } from './styled';
import { ConfigToNameFunctionMapping, ConfigToValueFunctionMapping } from '../ValueMappings';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ConfigWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 10px;
`;

const TableStyle = css`
  display: grid;
  grid-template-columns: 40% repeat(auto-fit, minmax(100px, 1fr));
  justify-items: start;
  align-items: center;
`;

const TableBody = styled.div`
  ${TableStyle};
`;

const TableBodyConfig = styled.div`
  ${TableStyle};
  &:first-child {
    padding: 0 0 20px 0;
  }
  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

export const HeaderMode = styled.h3`
  font-size: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colorPrimary};
`;

const ConfiguratorLimitWrapper = (initial: number[], limit: number[]) => (
  <HistoryConfiguratorContainer>
    <div>
      <span>Initial</span>
      <span>Limit</span>
    </div>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {initial.map((item, index) => (
      <div>
        <ValueWrapper>{item}</ValueWrapper>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <ValueWrapper>{limit[index]}</ValueWrapper>
      </div>
    ))}
  </HistoryConfiguratorContainer>
);

const gripName = (grip) =>
  `${configSettingsNamesMap.get('gripsPositions')} (${gripsGroupsOptionsMap.get(Number(grip))})`;

const GripHeaderWrapper = ({ grip }) => <div>{gripsGroupsOptionsMap.get(Number(grip))}</div>;

const HeaderItemWrapper = (name: string, value: any) =>
  value || value === 0 ? <div>{ConfigToNameFunctionMapping[`${name}`](value)}</div> : <div />;

const NameWrapper = (configName: keyof DeviceConfigTemplate) =>
  configSettingsNamesMap.get(configName) ?? 'Setting name';

const GripsPositionsWrapper = (value, grip) =>
  ConfiguratorLimitWrapper(value[grip].initial, value[grip].limit);

export const ConfigDifferencesItem = ({ name, configName, after, now }) => {
  const HistoricItemWrapper = (type: string, value: any, index, currentConfig = false) => {
    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
  }

  if (name === 'gripsPositions') {
    const grips = Object.keys(after);

    const GripDifference = ({ grip }) => (
      <Accordion header={gripName(grip)} style={{ marginTop: '24px' }}>
        <TableBodyConfig>
          <GripHeaderWrapper grip={grip} />
          {GripsPositionsWrapper(after, grip)}
          {GripsPositionsWrapper(now[`${name}`], grip)}
        </TableBodyConfig>
      </Accordion>
    );

    return (
      <>
        {grips.map((grip) => (
          <GripDifference grip={grip} />
        ))}
      </>
    );
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  return (
    <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
      {after.map((value, index) => {
        const mappingExists =
          value || value === 0 ? ConfigToValueFunctionMapping[`${name}`](value, index) : null;

        if (!mappingExists) return;

        return (
          <TableBodyConfig>
            {HeaderItemWrapper(name, index)}
            {HistoricItemWrapper(name, value, index)}
            {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
          </TableBodyConfig>
        );
      })}
    </Accordion>
  );
};

const transformHistory = (differences) => {
  let common: any = [];
  const modes: any = [];

  if (!isEmpty(differences.common.before)) {
    common = Object.keys(differences.common.before).map((key) => ({
      after: differences.common.after[`${key}`],
      before: differences.common.before[`${key}`],
      key
    }));
  }
  differences.modes.forEach((mode) => {
    if (!isEmpty(mode?.before) && mode?.before) {
      modes.push({
        changes: Object.keys(mode.before).map((key) => ({
          after: mode.after[`${key}`],
          before: mode.before[`${key}`],
          key
        })),
        slot: mode.slot,
        name: mode.name
      });
    }
  });

  return { common, modes };
};

export const SessionHistoryItem = ({ name, configName, after, before, now }) => {
  const HistoricItemWrapper = (type: string, value: any, index, currentConfig = false) => {
    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
  }

  if (name === 'gripsPositions') {
    const grips = Object.keys(after);

    const GripDifference = ({ grip }) => (
      <Accordion header={gripName(grip)}>
        <TableBodyConfig>
          <GripHeaderWrapper grip={grip} />
          {GripsPositionsWrapper(before, grip)}
          {GripsPositionsWrapper(after, grip)}
          {GripsPositionsWrapper(now[`${name}`], grip)}
        </TableBodyConfig>
      </Accordion>
    );

    return (
      <>
        {grips.map((grip) => (
          <GripDifference grip={grip} />
        ))}
      </>
    );
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion header={NameWrapper(configName)}>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {HistoricItemWrapper(name, before, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  return (
    <Accordion header={NameWrapper(configName)}>
      {after.map((value, index) => {
        const mappingExists =
          value || value === 0 ? ConfigToValueFunctionMapping[`${name}`](value, index) : null;

        if (!mappingExists) return;

        return (
          <TableBodyConfig>
            {HeaderItemWrapper(name, index)}
            {HistoricItemWrapper(name, before ? before[index] : null, index)}
            {HistoricItemWrapper(name, value, index)}
            {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
          </TableBodyConfig>
        );
      })}
    </Accordion>
  );
};

const SessionHistory = () => {
  const { config, configHistory, consumeHistory, importConfig } = useConfigStore((state) => ({
    config: state.config,
    configHistory: state.configHistory,
    consumeHistory: state.consumeHistory,
    importConfig: state.importConfig
  }));
  const sessionRestorePoints = useLiveConfiguratorStore((state) => state.sessionRestorePoints);
  const openModal = useUiStore((state) => state.openModal);
  const { consumeAllSessionChanges } = useRemoteSession();
  const [configRestorePoints, setConfigRestorePoints] = useState<any[]>([]);
  const [selectedEntry, setSelectedEntry] = useState(0);
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);

  const handleUndo = (historyChangeId: number) => {
    consumeHistory(historyChangeId);
  };

  useEffect(() => {
    if (sessionRestorePoints && sessionRestorePoints.length > 0) {
      setConfigRestorePoints(
        sessionRestorePoints.map((item, index) => ({
          name: `Restore point #${index + 1}`,
          ...item
        }))
      );
    } else {
      setConfigRestorePoints([]);
    }
  }, [sessionRestorePoints]);

  const restoreAllChanges = () => {
    openModal(MODALS.resetAllChanges);
  };

  const restorePoint = async (selectedItem) => {
    importConfig(configRestorePoints[selectedItem]);
    consumeAllSessionChanges(false);
    toast.success('Restore point imported');
  };

  const findRestorePoint = (id) =>
    configRestorePoints.find((restorePoint) => restorePoint.id === id);

  const completeConfigRestorePoint = {
    ...findRestorePoint(selectedEntry)?.common,
    ...findRestorePoint(selectedEntry)?.modes.find((mode) => mode.slot === selectedModeSlot).config
  };

  return (
    <>
      <HeaderWrapper>
        <Header1>Session history</Header1>
        <ButtonWrapper>
          <Button onClick={restoreAllChanges}>Reset all changes</Button>
        </ButtonWrapper>
      </HeaderWrapper>
      <Card>
        <TableBody>
          <TableHeader>Configuration</TableHeader>
          <TableHeader>Value before</TableHeader>
          <TableHeader>Value after</TableHeader>
          <TableHeader>Value now</TableHeader>
        </TableBody>
        {configHistory.length > 0 &&
          configHistory
            .slice()
            .reverse()
            .map((historyEntry, index) => (
              <div key={historyEntry.timestamp + Math.random()} style={{ marginTop: '24px' }}>
                <HeaderInnerWrapper>
                  <p style={{ fontWeight: 'bold' }}>Change {configHistory.length - index}</p>
                  {index === 0 && (
                    <Button
                      size='small'
                      onClick={() => handleUndo(historyEntry.id)}
                      color='secondary'>
                      Restore
                    </Button>
                  )}
                </HeaderInnerWrapper>
                {transformHistory(historyEntry.diffConfig).common.map((change) => (
                  <SessionHistoryItem
                    key={historyEntry.timestamp + Math.random()}
                    name={change.key}
                    configName={change.key}
                    before={change.before}
                    after={change.after}
                    now={config.common.config}
                  />
                ))}
                {transformHistory(historyEntry.diffConfig).modes.map((mode) => (
                  <>
                    <HeaderMode>Mode: {mode.name}</HeaderMode>
                    {mode.changes.map((change) => (
                      <SessionHistoryItem
                        key={historyEntry.timestamp + Math.random()}
                        name={change.key}
                        configName={change.key}
                        before={change.before}
                        after={change.after}
                        now={{
                          ...config.common.config,
                          ...config.modes.find((_mode) => _mode.slot === mode.slot)?.config
                        }}
                      />
                    ))}
                  </>
                ))}
              </div>
            ))}
      </Card>
      <Divider margin='40px' />
      {configRestorePoints && configRestorePoints.length > 0 && (
        <>
          <HeaderWrapper>
            <HeaderInnerWrapper>
              <Header1>Restore point</Header1>
              {config.modes && (
                <TextField
                  fullWidth
                  id='selected-mode'
                  label='Mode shown'
                  select
                  sx={{ width: '150px' }}
                  SelectProps={{
                    value: selectedModeSlot,
                    onChange: (e: any) => setSelectedModeSlot(e.target.value)
                  }}>
                  {config.modes.map((mode) => (
                    <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                      {mode.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button onClick={() => restorePoint(selectedEntry)} color='secondary'>
                Load
              </Button>
            </HeaderInnerWrapper>
            <HeaderInnerWrapper>
              <TextField
                id='selected-entry'
                select
                label='Entry name'
                SelectProps={{
                  onChange: (e) =>
                    setSelectedEntry(
                      configRestorePoints.find((item) => item?.id === e.target.value).id
                    ),
                  value: findRestorePoint(selectedEntry).id
                }}>
                {configRestorePoints.map((item) => (
                  <MenuItem key={`selected-entry_${item.name}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </HeaderInnerWrapper>
          </HeaderWrapper>
          <Card>
            <ConfigComponent
              config={completeConfigRestorePoint || null}
              transformedConfig={transformAndApply(completeConfigRestorePoint)}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default SessionHistory;
