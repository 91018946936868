import styled, { css } from 'styled-components';
import { HeaderM, HeaderXL, TextS, TextXS } from 'components/atoms/Typography/Typography';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const SettingsContainer = styled.div`
  display: grid;
  max-width: 800px;

  > div:last-child {
    padding-bottom: 10px;
    margin-bottom: 50px;
  }

  > * {
    padding: 20px 0 40px 0;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

const OptionsCSS = css`
  margin: 15px 10px 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 20px 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 15px 30px 0 30px;
  }
`;

export const OptionsWrapper = styled.div`
  display: grid;
  gap: 0 20px;
  grid-template-columns: auto 1fr;
  align-items: center;
  ${OptionsCSS}
`;

export const OptionsWrapperHoldOpen = styled.div`
  display: grid;
  gap: 20px;
  grid-template-rows: auto auto;
  ${OptionsCSS};
`;

export const OptionsList = styled.ul`
  display: grid;
  grid-gap: 10px;
  ${({ disable }) => (disable ? DisableFunctionality : true)}
`;

export const SoftGrip = styled.div`
  ${({ softGrip }) => (softGrip ? true : DisableFunctionality)}
`;

export const EMGIgnoreWrapper = styled.div`
  ${({ emgSpike }) => (emgSpike ? true : DisableFunctionality)}
`;

export const AutograspWrapper = styled.div`
  ${({ autoGrasp }) => (autoGrasp ? true : DisableFunctionality)}
`;

export const BatteryBeepWrapper = styled.div`
  ${({ batteryBeep }) => (batteryBeep ? true : DisableFunctionality)}
`;

export const ConfigurationSection = styled.div`
  ${({ disabled }) => (disabled ? DisableFunctionality : null)}
`;

export const HoldOpenWrapper = styled.div`
  ${({ holdOpen }) => (holdOpen ? true : DisableFunctionality)}
`;

export const OptionsSliderDescription = styled.p`
  ${TextXS};
  width: 80%;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  margin-top: 15px;
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 15px;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 15px;
    margin-left: 30px;
  }
`;

export const ProcedureReplyWrapper = styled.div`
  ${TextS};
  table {
    border-collapse: collapse;
    width: 100%;
  }
`;

export const ProcedureTableWrapper = styled.table`
  margin: 10px 0 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 0 10px 0;
  }
`;

export const ProcedureTable = styled.tbody`
  border: 2px solid #f2f2f2;
`;

export const ProcedureRow = styled.tr`
  text-align: center;

  td {
    padding: 0.5em;
    border: 2px solid #f2f2f2;
  }
`;

export const CalibrationWrapper = styled.div`
  margin: 10px 10px 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 20px 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 20px 30px 0 30px;
  }
`;

export const ProcedureStatusBox = styled.td`
  color: ${({ status }) => (status === 1 ? 'green' : 'red')};
`;

export const DoubleLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;
