import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { MODALS } from 'views/Modals';
import { useReplayStore } from 'reducers/replayStore';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useUiStore } from 'reducers/uiStore';
import useRemoteSession from '../../hooks/useRemoteSession';
import ConfirmationLoader from '../../layouts/ConfirmationLoader/ConfirmationLoader';

const DeviceInfo = () => {
  const query = useQuery();
  const bluetoothId = query.get('bluetoothId');
  const serial = query.get('serial');
  const deviceId = query.get('deviceId');
  const amputeeId = query.get('amputeeId');
  const liveConnectionToken = query.get('token');
  const sessionId = query.get('sessionId');
  const modeId = query.get('modeId');
  const repair = query.get('repair');
  const connect = query.get('connect');
  const { push } = useHistory();
  const { enableLiveSession } = useRemoteSession();
  const openModal = useUiStore((state) => state.openModal);
  const setDeviceInfo = useDeviceInfoStore((state) => state.setDeviceInfo);
  const resetToDefault = useReplayStore((state) => state.resetToDefault);
  const setItemConfigStore = useConfigStore((state) => state.setItemConfigStore);
  const { bluetoothConnect } = useBluetooth();

  useEffect(() => {
    if (deviceId) {
      setDeviceInfo({
        serial: serial ?? null,
        bluetoothId: bluetoothId ?? null,
        deviceId: Number(deviceId),
        amputeeId: Number(amputeeId) ?? null
      });
      resetToDefault();
    }
  }, [bluetoothId, serial, deviceId, amputeeId]);

  useEffect(() => {
    if (liveConnectionToken && sessionId) {
      enableLiveSession(liveConnectionToken, Number(sessionId));
      resetToDefault();
    }
  }, [liveConnectionToken, sessionId]);

  useEffect(() => {
    setItemConfigStore('slotSelected', Number(modeId));
    resetToDefault();
  }, [modeId]);

  useEffect(() => {
    if (repair) {
      openModal(MODALS.repair);
    }
  }, [repair]);

  useEffect(() => {
    const timer = setTimeout(() => {
      push('/choose-grips');
      if (connect) bluetoothConnect();
    }, 2000);
    return () => clearTimeout(timer);
  }, [connect]);

  return <ConfirmationLoader />;
};

export default DeviceInfo;
