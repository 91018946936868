import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HandRock } from 'assets/hand-rock-solid.svg';
import { ReactComponent as ChartLine } from 'assets/chart-line-solid.svg';
import { ReactComponent as Microchip } from 'assets/microchip-solid.svg';
import { ReactComponent as Wrench } from 'assets/wrench-solid.svg';
import { ReactComponent as HandPaper } from 'assets/hand-paper-regular.svg';
import { ReactComponent as Clipboard } from 'assets/clipboard-list-solid.svg';
import { ReactComponent as AngleDown } from 'assets/angle-down-solid.svg';
import { ReactComponent as Config } from 'assets/bars-progress-solid.svg';
import { ReactComponent as AngleUp } from 'assets/angle-up-solid.svg';
import { ReactComponent as Clock } from 'assets/clock-solid.svg';
import { ReactComponent as Cog } from 'assets/cog-solid.svg';
import { HeaderM, HeaderS } from 'components/atoms/Typography/Typography';
import { useReplayStore } from 'reducers/replayStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import useSidebar from 'hooks/useSidebar';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';

const Wrapper = styled.nav`
  width: 100%;

  a {
    text-decoration: none;
  }

  z-index: 1;
`;

const List = styled.ul`
  display: grid;
  grid-auto-flow: row;
`;

const InnerItem = styled.div`
  display: flex;
  margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 18px')};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 28px')};
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 7px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colorSecondary};
  }
`;

const StyledIcon = css`
  width: ${({ collapsed }) => (collapsed ? '20px' : '10px')};
  fill: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 20px;
  }
`;

const StyledHandPaper = styled(HandPaper)`
  ${StyledIcon}
`;

const StyledChartLine = styled(ChartLine)`
  ${StyledIcon}
`;

const StyledHandRock = styled(HandRock)`
  ${StyledIcon}
`;

const StyledMicrochip = styled(Microchip)`
  ${StyledIcon}
`;

const StyledWrench = styled(Wrench)`
  ${StyledIcon}
`;

const StyledClipboard = styled(Clipboard)`
  ${StyledIcon}
`;

const StyledConfig = styled(Config)`
  ${StyledIcon}
`;

const StyledClock = styled(Clock)`
  ${StyledIcon}
`;

const StyledAngleDown = styled(AngleDown)`
  ${StyledIcon};
  margin-right: 24px;
  cursor: pointer;
`;

const StyledAngleUp = styled(AngleUp)`
  ${StyledIcon};
  margin-right: 24px;
  cursor: pointer;
`;

const ItemText = styled.p`
  ${HeaderS};
  margin-left: 15px;
`;

const HeaderItem = styled(Item)`
  cursor: auto;
`;

const HeaderItemText = styled.p`
  ${HeaderM};
  color: #fff;
  cursor: auto;
`;

const ItemListableWrapper = styled.div`
  display: flex;
  color: #fff;
  padding: 5px 0;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 7px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 0;
  }

  background-color: ${({ active, theme }) => (active ? theme.colorSecondary : 'inherit')};
`;

const InnerItemListable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 18px')};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 28px')};
  }
`;

const LabelListable = styled.div`
  display: flex;
  align-items: center;
`;

const ListListable = styled.ul`
  list-style: inside;
  margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 22px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: ${({ collapsed }) => (collapsed ? '0 auto' : '0 0 0 42px')};
  }

  li {
    padding: 5px 0;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const ListLinkListable = styled.div`
  &.active {
    border-bottom: 1px solid #fff;
  }
`;

const StyledCog = styled(Cog)`
  ${StyledIcon}
`;

// eslint-disable-next-line no-unused-vars
const ItemListable = ({ label, icon, links }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <ItemListableWrapper active={links.map((link) => link.route).includes(pathname)}>
      <InnerItemListable onClick={() => setOpen((prev) => !prev)}>
        <LabelListable>
          {icon}
          <ItemText>{label}</ItemText>
        </LabelListable>
        {open ? <StyledAngleUp /> : <StyledAngleDown />}
      </InnerItemListable>
      {open && (
        <ListListable>
          {links.map((link) => (
            <li>
              <ListLinkListable as={NavLink} to={link.route} activeClassName='active'>
                {link.label}
              </ListLinkListable>
            </li>
          ))}
        </ListListable>
      )}
    </ItemListableWrapper>
  );
};

const Navbar = ({ collapsed }) => {
  const { t } = useTranslation();
  const { deviceConnected, serial } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    serial: state.serial
  }));
  const liveSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const { handleFirmwareNavbar, handleServiceMenuNavbar } = useSidebar();

  return (
    <Wrapper>
      <List>
        {!collapsed && (
          <HeaderItem>
            <InnerItem collapsed={collapsed}>
              {serial && <HeaderItemText>Serial: {serial}</HeaderItemText>}
            </InnerItem>
          </HeaderItem>
        )}
        <Item
          as={NavLink}
          to='/choose-grips'
          activeClassName='active'
          data-tour-general='sidebar-grips'>
          <InnerItem collapsed={collapsed}>
            <StyledHandPaper collapsed={collapsed} />
            {!collapsed && <ItemText>{t('navbar.choose_grips')}</ItemText>}
          </InnerItem>
        </Item>
        <Item as={NavLink} to='/graph' activeClassName='active' data-tour-general='sidebar-emg'>
          <InnerItem collapsed={collapsed}>
            <StyledChartLine collapsed={collapsed} />
            {!collapsed && (
              <ItemText>
                {replayIsEnabled ? t('navbar.emg_playback') : t('navbar.emg_settings')}
              </ItemText>
            )}
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to='/grips-configuration'
          activeClassName='active'
          data-tour-general='sidebar-grips-config'>
          <InnerItem collapsed={collapsed}>
            <StyledHandRock collapsed={collapsed} />
            {!collapsed && <ItemText>{t('navbar.grips_configuration')}</ItemText>}
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to='/prosthesis-settings'
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem collapsed={collapsed}>
            <StyledClipboard collapsed={collapsed} />
            {!collapsed && <ItemText>{t('navbar.prosthesis_settings')}</ItemText>}
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to='/device-history'
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem collapsed={collapsed}>
            <StyledClock collapsed={collapsed} />
            {!collapsed && <ItemText>History log</ItemText>}
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to='/config-templates'
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem collapsed={collapsed}>
            <StyledConfig collapsed={collapsed} />
            {!collapsed && <ItemText>Config templates</ItemText>}
          </InnerItem>
        </Item>
        <Item
          data-tour-general='sidebar-firmware'
          onClick={() => handleFirmwareNavbar(deviceConnected, liveSessionEnabled)}>
          <InnerItem collapsed={collapsed}>
            <StyledMicrochip collapsed={collapsed} />
            {!collapsed && <ItemText>{t('navbar.update_firmware')}</ItemText>}
          </InnerItem>
        </Item>
        {!liveSessionEnabled && (
          <Item
            as={NavLink}
            to='/service-menu'
            onClick={(e) => handleServiceMenuNavbar(deviceConnected, e)}
            activeClassName='active'>
            <InnerItem collapsed={collapsed}>
              <StyledWrench collapsed={collapsed} />
              {!collapsed && <ItemText>{t('navbar.service_menu')}</ItemText>}
            </InnerItem>
          </Item>
        )}
        <Item to='/application-settings' as={NavLink}>
          <InnerItem collapsed={collapsed}>
            <StyledCog collapsed={collapsed} />
            {!collapsed && <ItemText>{t('settings.app_settings')}</ItemText>}
          </InnerItem>
        </Item>
      </List>
    </Wrapper>
  );
};

Navbar.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default Navbar;
