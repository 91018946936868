import { useEffect, useState } from 'react';
import { useConfigStore } from 'reducers/configStore';
import { ProcedureTypes } from '../bluetooth/Bluetooth/Procedures';

type ProcedureCalibrationSoftGrip = {
  status?: number[];
  maxCurrents?: number[];
};

type ProcedureCalibrationAutoGrasp = {
  status?: number[];
  minimumClosing?: number[];
};

type ProcedureFingersSpeedProcedure = {
  status?: number[];
  speeds?: number[];
};

type Procedure =
  | ProcedureCalibrationAutoGrasp
  | ProcedureCalibrationSoftGrip
  | ProcedureFingersSpeedProcedure;
type ProcedureLocalTypes =
  | ProcedureTypes.calibrateAutoGrasp
  | ProcedureTypes.calibrateSoftGrip
  | ProcedureTypes.calibrateFingersSpeed;

type ObjectType<T> = T extends ProcedureTypes.calibrateAutoGrasp
  ? ProcedureCalibrationAutoGrasp
  : T extends ProcedureTypes.calibrateSoftGrip
  ? ProcedureCalibrationSoftGrip
  : T extends ProcedureTypes.calibrateFingersSpeed
  ? ProcedureFingersSpeedProcedure
  : never;

const handleSoftGripProcedure = (procedureReply) => {
  let procedureObject: ProcedureCalibrationSoftGrip | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.maxCurrents = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};
const handleAutoGraspProcedure = (procedureReply) => {
  let procedureObject: ProcedureCalibrationAutoGrasp | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.minimumClosing = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};
const handleFingersSpeedProcedure = (procedureReply) => {
  let procedureObject: ProcedureFingersSpeedProcedure | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.speeds = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};

const useProcedureReply = <T extends ProcedureLocalTypes>(type: T): ObjectType<T> => {
  const { procedureReply, procedureUsedType } = useConfigStore((state) => ({
    procedureReply: state.procedureReply,
    procedureUsedType: state.procedureUsedType
  }));
  const [procedure, setProcedure] = useState<Procedure | null>(null);

  useEffect(() => {
    if (procedureUsedType !== type) {
      return;
    }
    // eslint-disable-next-line default-case
    switch (procedureUsedType) {
      case ProcedureTypes.calibrateAutoGrasp:
        setProcedure(handleAutoGraspProcedure(procedureReply));
        break;
      case ProcedureTypes.calibrateSoftGrip:
        setProcedure(handleSoftGripProcedure(procedureReply));
        break;
      case ProcedureTypes.calibrateFingersSpeed:
        setProcedure(handleFingersSpeedProcedure(procedureReply));
        break;
    }
  }, [procedureReply]);

  return procedure as ObjectType<T>;
};

export default useProcedureReply;
