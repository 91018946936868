/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { Button } from '@mui/material';
import { ReactComponent as AngleDown } from 'assets/angle-down-solid.svg';
import { OpacityAnimation } from 'components/atoms/Animations/Animations';
import { MONTHS, YEARS } from 'consts/consts';
import Dropdown2 from 'components/atoms/Dropdown/Dropdown2';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
`;

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: space-between;
  min-height: 30px;
  width: auto;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colorFill};
  cursor: pointer;
  height: 44px;
  padding: 10px;
`;

const Choosen = styled.div`
  width: 100%;
`;

const Label = styled.div`
  font-size: 16px;
  width: 100%;
`;

const Select = styled.ul`
  position: absolute;
  display: ${({ open }) => (open ? 'grid' : 'none')};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  padding: 5px;
  right: auto;
  left: 0;
  top: 100%;
  list-style: none;
  z-index: 99999;
  background-color: ${({ theme }) => theme.colorFill};
  animation: ${OpacityAnimation} 0.3s linear;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 24px;
  cursor: default;
`;

const IconDown = styled(AngleDown)`
  color: ${({ theme }) => theme.colorPrimary};
  right: 25px;
  top: 9px;
  width: 18px;
`;

const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  gap: 16px;
`;

const CalendarTable = styled.table`
  table-layout: fixed;
  tr {
    th {
      min-width: 40px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  tr {
    td {
      width: 40px;
      height: 40px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background-color: ${({ theme }) => theme.colorSecondary};
      }
    }
  }
`;

const CalendarDay = styled.td`
  color: ${({ active }) => (active ? 'auto' : '#808080')};
  position: relative;
  background-color: ${({ selected, theme }) => (selected ? theme.colorSecondary : 'auto')};

  div {
    display: flex;
    justify-content: center;
  }
`;

const CalendarDayActive = styled.span`
  position: absolute;
  height: 4px;
  width: 4px;
  top: 80%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colorPrimary};
  border-radius: 50%;
  display: inline-block;
`;

const transformMonth = (month) => {
  const previousMonth = month.month(month.month() - 1);
  const nextMonth = month.month(month.month() + 1);

  let previousMonthDays: any = [];
  if (month.day() - 1 > 0) {
    previousMonthDays = [...Array(month.day() - 1).keys()].map((previousDay) => ({
      day: previousMonth.daysInMonth() - previousDay,
      month: previousMonth.month(),
      year: previousMonth.year()
    }));
  }

  const coreMonthDays = [...Array(month.daysInMonth()).keys()].map((coreDay) => ({
    day: coreDay + 1,
    month: month.month(),
    year: month.year()
  }));

  const nextMonthDays = [...Array(43 - (month.day() + month.daysInMonth())).keys()].map(
    (nextDay) => ({
      day: nextDay + 1,
      month: nextMonth.month(),
      year: nextMonth.year()
    })
  );
  const calendarDays = [...previousMonthDays, ...coreMonthDays, ...nextMonthDays];
  return calendarDays;
};

const CalendarDayItem = ({ active, day, events, selected, ...props }) => {
  return (
    <CalendarDay
      data-tip
      data-for={events ? `configs${events[0].id}` : null}
      active={active}
      selected={selected}
      {...props}>
      {events && (
        <ReactTooltip id={`configs${events[0].id}`} type='light' effect='solid'>
          <span>{events.length} configs</span>
        </ReactTooltip>
      )}
      <div>{day.day}</div>
      {events && <CalendarDayActive />}
    </CalendarDay>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 16px;

  button {
    max-width: 125px;
  }
`;

type DropdownProps = {
  events: any;
  handleApply: any;
  handleCancel: any;
  selected: any;
  label?: string;
  responsive?: boolean;
  setOpenParent?: Function;
  style?: any;
};

const CalendarPicker = ({
  label = '',
  selected,
  responsive = false,
  setOpenParent = () => false,
  style,
  events,
  handleApply,
  handleCancel,
  ...props
}: DropdownProps) => {
  const dateSelectedParsed = dayjs(Number(selected) * 1000);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selected ? dateSelectedParsed : dayjs());
  const [selectedMonth, setSelectedMonth] = useState(
    selected ? dateSelectedParsed.month() : dayjs().month()
  );
  const [selectedYear, setSelectedYear] = useState(
    selected ? dateSelectedParsed.year() : dayjs().year()
  );
  const calendarDays = transformMonth(dayjs().year(selectedYear).month(selectedMonth).date(1));

  const updateOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOnCancel = () => {
    handleCancel();
    updateOpen();
  };

  const handleOnApply = () => {
    handleApply(selectedDate);
    updateOpen();
  };

  return (
    <Wrapper open={open} responsive={responsive ? 1 : 0} {...props}>
      <Label>{label}</Label>
      <Box responsive={responsive ? 1 : 0} style={style}>
        <Choosen onClick={updateOpen}>{selectedDate.format('MM/DD/YYYY')}</Choosen>
        <IconDown responsive={responsive ? 1 : 0} onClick={updateOpen} />
        <Select open={open}>
          <div>
            <CalendarHeaderWrapper>
              <Dropdown2
                style={{ width: '150px' }}
                options={MONTHS}
                label=''
                selected={selectedMonth}
                onChange={(id) => setSelectedMonth(id)}
              />
              <Dropdown2
                style={{ width: '150px' }}
                options={YEARS}
                label=''
                selected={selectedYear}
                onChange={(id) => setSelectedYear(id)}
              />
            </CalendarHeaderWrapper>
            <div>
              <CalendarTable>
                <tr>
                  <th>Mo</th>
                  <th>Tu</th>
                  <th>We</th>
                  <th>Th</th>
                  <th>Fr</th>
                  <th>Sa</th>
                  <th>Su</th>
                </tr>
                {[...Array(6).keys()].map((week) => (
                  <tr>
                    {[...Array(7).keys()].map((dayOfWeek) => {
                      const currentDate = calendarDays[7 * week + dayOfWeek];
                      const currentDateParsed = dayjs()
                        .year(currentDate.year)
                        .month(currentDate.month)
                        .date(currentDate.day);
                      let groupIndex: null | number = null;
                      for (let index = 0; index < events.length; index += 1) {
                        const eventGroupDate = dayjs(events[index][0].created_at);
                        if (eventGroupDate.isSame(currentDateParsed, 'day')) {
                          groupIndex = index;
                          break;
                        }
                      }
                      return (
                        <CalendarDayItem
                          onClick={() => setSelectedDate(currentDateParsed)}
                          active={
                            currentDate.month ===
                            dayjs().year(selectedYear).month(selectedMonth).date(1).month()
                          }
                          events={groupIndex !== null ? events[groupIndex] : groupIndex}
                          day={currentDate}
                          selected={selectedDate.isSame(currentDateParsed, 'day')}
                        />
                      );
                    })}
                  </tr>
                ))}
              </CalendarTable>
            </div>
          </div>
          <ButtonsWrapper>
            <Button onClick={handleOnCancel} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={handleOnApply}>Apply</Button>
          </ButtonsWrapper>
        </Select>
      </Box>
    </Wrapper>
  );
};

export default CalendarPicker;
