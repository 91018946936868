import React from 'react';
import { InputLabel, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { commonFormStyles } from './commonStyles';

const CustomTextField = ({ id, label, control }) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <div>
        <InputLabel
          shrink={false}
          htmlFor={id}
          sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <Typography>{label}</Typography>
        </InputLabel>
        <TextField
          {...field}
          id={id}
          size='small'
          variant='outlined'
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
        />
      </div>
    )}
  />
);

export default CustomTextField;
