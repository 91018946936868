import { useEffect } from 'react';

export const useGraph = (
  graphContainer,
  updateThresholdsOptions,
  updateTimeOptions,
  updateThresholdsDeps,
  updateTimeDeps
) => {
  // Update data imperatively
  const updateData = (updateDataOptions) => {
    graphContainer.current?.updateData([...updateDataOptions]);
  };

  // Update thresholds imperatively
  useEffect(() => {
    graphContainer.current?.updateThresholds(
      {
        time: updateThresholdsOptions.time,
        interval: updateThresholdsOptions.interval
      },
      [...updateThresholdsOptions.thresholds],
      updateThresholdsOptions?.showThreshold
    );
  }, [...updateThresholdsDeps]);

  // Update time imperatively
  useEffect(() => {
    graphContainer.current?.updateTime(updateTimeOptions);
    graphContainer.current?.updateThresholds(
      {
        time: updateThresholdsOptions.time,
        interval: updateThresholdsOptions.interval
      },
      [...updateThresholdsOptions.thresholds],
      updateThresholdsOptions?.showThreshold
    );
  }, [...updateTimeDeps]);

  return { updateData };
};
