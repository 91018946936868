import { logoutAPI } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAuthStore } from 'reducers/authStore';

export default function useLogout() {
  const queryClient = useQueryClient();
  const broadcast = new BroadcastChannel('logouts');
  const { logoutSuccess } = useAuthStore((state) => ({ logoutSuccess: state.logoutSuccess }));

  const { isLoading, mutate: logout } = useMutation(logoutAPI, {
    onSuccess() {
      setApiAuthToken(null);
      queryClient.resetQueries();
      queryClient.clear();
      logoutSuccess();
      window.location.replace(`${process.env.REACT_APP_ADMIN_PANEL_URL}`);
    }
  });

  const { isLoading: mfaLogoutIsLoading, mutate: mfaLogout } = useMutation(logoutAPI, {
    onSuccess() {
      window.location.replace(`${process.env.REACT_APP_ADMIN_PANEL_URL}`);
    }
  });

  const offlineLogout = () => {
    setApiAuthToken(null);
    logoutSuccess();
    setTimeout(() => {
      queryClient.resetQueries();
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      offlineLogout();
    };
  }, []);

  return {
    logout: () => logout(),
    mfaLogout,
    isLoading: isLoading || mfaLogoutIsLoading,
    offlineLogout
  };
}
