import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Card from 'components/atoms/Card/Card';
import DropdownImg from 'components/atoms/Dropdown/DropdownImg';
import { GRIPS_POST_17 } from 'consts/consts';
import { gripsImagesMap } from 'utils/gripsImages';
import { gripsGroupsOptionsMap, gripsGroupsOptionsReversedMap } from 'utils/definesLocal';
import { Grips } from 'bluetooth/Bluetooth/Grips';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import { postCurrentGrip, postGripSpeed } from 'bluetooth-handler/bluetoothFunctions';
import TelemetryController from 'bluetooth-handler/telemetryController';
import useWatch from 'hooks/useWatch';
import { useConfigStore } from 'reducers/configStore';

export const Viewer = styled.div`
  width: 100%;
  padding: 20px 10px 0 10px;
  margin: 0 auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const GripsTester = () => {
  const [selectedGrip, setSelectedGrip] = useState<any>(Grips.kGripPower);
  useTelemetry(true);
  useWatch(TelemetryController, ['prosthesisGrip']);
  const { bluetoothMode } = useConfigStore();

  const handleOptions = (option) => {
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    postCurrentGrip(newGrip, bluetoothMode);
  };

  useEffect(() => {
    if (TelemetryController.prosthesisGrip !== undefined) {
      setSelectedGrip(TelemetryController.prosthesisGrip);
    }
  }, [TelemetryController.prosthesisGrip]);

  return (
    <Card>
      <DropdownImg
        options={GRIPS_POST_17.map((grip) => ({
          value: gripsGroupsOptionsMap.get(grip),
          img: gripsImagesMap.get(grip)
        }))}
        selected={{
          value: gripsGroupsOptionsMap.get(selectedGrip),
          img: gripsImagesMap.get(selectedGrip)
        }}
        onChange={(option) => handleOptions(option)}
        showImg
        label='Current grip'
      />
      <ButtonsWrapper>
        <Button fullWidth onClick={() => postGripSpeed(-100, bluetoothMode)}>
          Open
        </Button>
        <Button fullWidth onClick={() => postGripSpeed(100, bluetoothMode)}>
          Close
        </Button>
      </ButtonsWrapper>
    </Card>
  );
};

export default GripsTester;
