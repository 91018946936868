import React, { useEffect, useState } from 'react';
import { Types } from 'ably';
import toast from 'react-hot-toast';
import useRemoteSession from 'hooks/useRemoteSession';
import useUnsaved from 'hooks/useUnsaved';
import useSynchronizeBluetooth from 'hooks/bluetooth/useSynchronizeBluetooth';
import { ablyClient } from 'utils/LiveConfigurator/AblyClient';
import useCompatibilities from 'hooks/useCompatibilities';
import useSynchronizeConfigProperties from 'hooks/bluetooth/useSynchronizeConfigProperties';
import { useConfigStore } from 'reducers/configStore';
import { useReplayStore } from 'reducers/replayStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useAuthStore } from 'reducers/authStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { REMOTE_SESSION_DEBUG } from 'consts/consts';
import useMeeting from 'hooks/useMeeting';

const BaseLogic = ({ children }) => {
  const me = useAuthStore((state) => state.me);
  const { configIsFetched } = useRemoteSession();
  const {
    enabled: liveConfiguratorEnabled,
    configFetched,
    getLiveSessionApi
  } = useLiveConfiguratorStore((state) => ({
    enabled: state.enabled,
    configFetched: state.configFetched,
    getLiveSessionApi: state.getLiveSessionApi
  }));
  const { config, configConflict, firstConnection, getInitialConfigAPI, clearConfigHistory } =
    useConfigStore((state) => ({
      config: state.config,
      configConflict: state.configConflict,
      firstConnection: state.firstConnection,
      slotSelected: state.slotSelected,
      getInitialConfigAPI: state.getInitialConfigAPI,
      clearConfigHistory: state.clearConfigHistory
    }));
  const { amputeeId, deviceId, firmware, deviceConnected } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId,
    deviceId: state.deviceId,
    firmware: state.firmware,
    deviceConnected: state.connected
  }));
  const [notificationsChannel, setNotificationsChannel] =
    useState<Types.RealtimeChannelCallbacks | null>();
  const {
    isFirmwareUpdateNeeded,
    isPcbUpgradeNeeded,
    availableFirmwares,
    isFirmwareUpdateAvailable
  } = useCompatibilities();
  const { lastConfigChanged, sendDifferencesDemo } = useUnsaved();
  const { sendConfig } = useRemoteSession();
  useSynchronizeConfigProperties();
  const { synchronizeConfig } = useSynchronizeBluetooth();
  const { handleOpenMeeting } = useMeeting();

  function handleLeave(event) {
    event.returnValue = 'Session is in progress. Sure you want to leave?';
  }

  // Send changes during demo mode and session

  useEffect(() => {
    if (lastConfigChanged) {
      sendDifferencesDemo();
      sendConfig();
    }
  }, [lastConfigChanged]);

  // Update config after receiving event from mobile

  useEffect(() => {
    if (amputeeId && me?.id && !notificationsChannel) {
      const channel = `notificationsConfig:${amputeeId}`;
      const notifications = ablyClient(`${me?.id}`).channels.get(channel);

      setNotificationsChannel(notifications);

      notifications?.subscribe('updateConfig', async () => {
        await getInitialConfigAPI();
        clearConfigHistory();
        toast.success('Configuration was changed by patient. Recent one was retrieved.', {
          duration: 10000
        });
      });
    }

    return () => {
      if (notificationsChannel) {
        notificationsChannel.unsubscribe();
      }
    };
  }, [amputeeId, me?.id, notificationsChannel]);

  // Show warning before leaving in session

  useEffect(() => {
    window.removeEventListener('beforeunload', handleLeave);
    if (liveConfiguratorEnabled) {
      window.addEventListener('beforeunload', handleLeave);
    }

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [liveConfiguratorEnabled]);

  // Fetch device config from API at startup

  useEffect(() => {
    if (!liveConfiguratorEnabled && deviceId) {
      getInitialConfigAPI().catch(console.error);
    }
  }, [deviceId]);

  // Handle session startup

  useEffect(() => {
    const prepareSession = async () => {
      await getInitialConfigAPI();
      if (!REMOTE_SESSION_DEBUG) await getLiveSessionApi();
      configIsFetched();
    };
    if (liveConfiguratorEnabled && !configFetched && me?.id && deviceId && amputeeId) {
      prepareSession();
      handleOpenMeeting(Number(amputeeId));
    }
  }, [deviceId, configFetched, me?.id, liveConfiguratorEnabled, amputeeId]);

  // Synchronize device config with config from API

  useEffect(() => {
    if (configConflict && deviceConnected) {
      synchronizeConfig(config);
    }
  }, [configConflict]);

  useEffect(() => {
    if (isPcbUpgradeNeeded) {
      toast(
        `This device is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please contact support to upgrade it`,
        { duration: 60000, icon: '⚠️', id: 'isPcbUpgradeNeeded' }
      );
    }
  }, [isPcbUpgradeNeeded]);

  useEffect(() => {
    if (isFirmwareUpdateNeeded) {
      toast(
        `Current device firmware ${firmware?.name} is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please update to latest firmware ${availableFirmwares?.[0]?.name}`,
        { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateNeeded' }
      );
    }
  }, [isFirmwareUpdateNeeded]);

  useEffect(() => {
    console.log(isFirmwareUpdateAvailable, 'IS NEEDED');
    if (isFirmwareUpdateAvailable) {
      toast(
        `There is a new firmware available, please update to latest version ${availableFirmwares?.[0]?.name}`,
        { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateAvailable' }
      );
    }
  }, [isFirmwareUpdateAvailable]);

  if (firstConnection === null) {
    return null;
  }

  return children;
};

const ReplayLogic = ({ children }) => {
  const configUrl = useReplayStore((state) => state.configUrl);
  const getTicketConfigApi = useConfigStore((state) => state.geTicketConfigApi);

  useEffect(() => {
    const fetchTicket = async () => {
      await getTicketConfigApi();
    };
    if (configUrl) {
      fetchTicket().catch(console.error);
    }
  }, [configUrl]);

  return children;
};

const MainViews = ({ children }) => {
  const { enabled: isRecordReplay } = useReplayStore();
  return isRecordReplay ? <ReplayLogic>{children}</ReplayLogic> : <BaseLogic>{children}</BaseLogic>;
};

export default MainViews;
