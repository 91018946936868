import React, { useEffect, useMemo, useState } from 'react';
import { initialState } from 'reducers/helpers/initialState';
import {
  aetherBatteryVoltageFunctions,
  genericSwitchingMap,
  genericSwitchingReversedMap
} from 'utils/definesLocal';
import { emergencyBatterySettingsEntry } from 'consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'reducers/configStore';
import { OptionsWithSlider } from '../Sliders';

const sliderLimits = {
  min: aetherBatteryVoltageFunctions.percentToVoltage(1),
  max: aetherBatteryVoltageFunctions.percentToVoltage(10)
};

const initialEmergencyBatterySettingsVoltage = initialState.config.emergencyBatterySettings[2];
const EmergencyBatteryBeep = ({
  addHistoryProsthesis,
  emergencyBatterySettings,
  replayIsEnabled,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  emergencyBatterySettings: emergencyBatterySettingsEntry;
  replayIsEnabled: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [emergencyBatterySliderLocal, setEmergencyBatterySliderLocal] = useState<number>(
    initialEmergencyBatterySettingsVoltage
  );

  const emergencyBatteryStatus = emergencyBatterySettings[0];

  const handleEmergencyBatterySlider = (emergencyBatteryVoltage) => {
    addHistoryProsthesis(
      setConfigProperty('emergencyBatterySettings', [
        emergencyBatterySettings[0],
        emergencyBatterySettings[1],
        emergencyBatteryVoltage
      ])
    );
  };

  const handleEmergencyBatteryStatus = (emergencyBatteryStatus) => {
    addHistoryProsthesis(
      setConfigProperty('emergencyBatterySettings', [
        emergencyBatteryStatus,
        emergencyBatterySettings[1],
        emergencyBatterySettings[2]
      ])
    );
  };

  useEffect(() => {
    if (emergencyBatterySettings) {
      const emergencyBatterySettingsVoltage = emergencyBatterySettings?.[2];
      setEmergencyBatterySliderLocal(emergencyBatterySettingsVoltage);
    }
  }, [emergencyBatterySettings]);

  const EmergencyBatteryBeep = useMemo(
    () => (
      <OptionsWithSlider
        header='Emergency mode'
        toggleOptions={['On', 'Off']}
        disabled={replayIsEnabled}
        disableSlider={Boolean(!emergencyBatteryStatus)}
        toggleValue={genericSwitchingMap.get(emergencyBatteryStatus)}
        sliderValue={emergencyBatterySliderLocal}
        handleSliderChange={(e, value) => setEmergencyBatterySliderLocal(value)}
        sliderLimits={sliderLimits}
        sliderLabel={`Level: ${aetherBatteryVoltageFunctions.voltageToPercent(
          emergencyBatterySliderLocal
        )} %`}
        handleToggleChange={(value) =>
          handleEmergencyBatteryStatus(genericSwitchingReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args) => handleEmergencyBatterySlider(args[1])}
        description='Turning this off might lead to a situation where the patient cannot open the hand while holding an object when the battery was fully depleted.'
      />
    ),
    [emergencyBatterySettings, emergencyBatterySliderLocal]
  );

  return EmergencyBatteryBeep;
};

export default EmergencyBatteryBeep;
