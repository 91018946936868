import { RoleEnum } from '../api/users/users.types';

export const userCanVisit = (requiredRoles?: RoleEnum[], userRoles?: any) => {
  if (!requiredRoles || !userRoles) {
    return null;
  }
  return requiredRoles.find((item) => userRoles[item] !== undefined);
};

export const userHasPermissions = (requiredRoles?: RoleEnum[], userRoles?: any) =>
  !!userCanVisit(requiredRoles, userRoles);

export const rolesByName = (roles: any) =>
  Array.isArray(roles)
    ? roles.reduce(
        (acc: any, current: { name: any }) => ({
          ...acc,
          [current.name]: current
        }),
        {}
      )
    : {};
