type DevicesSize = {
  xsmall: number;
  small: number;
  tablet: number;
  medium: number;
  large: number;
  xlarge: number;
  desktop: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 450,
  small: 576,
  tablet: 720,
  medium: 768,
  large: 992,
  xlarge: 1200,
  desktop: 1360,
  xxlarge: 1440
};

type Devices = {
  xsmall: string;
  small: string;
  tablet: string;
  medium: string;
  large: string;
  xlarge: string;
  desktop: string;
  xxlarge: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  tablet: `(min-width: ${deviceSize.tablet}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  desktop: `(min-width: ${deviceSize.desktop}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`
};

type Dimensions = {
  contentMaxWidthBig: number;
  contentMaxWidthMedium: number;
  contentMaxWidthSmall: number;
  spacing: number;
  formWidth: number;
  spacingHeader: number;
};

const dimensions = {
  contentMaxWidthBig: 1080,
  contentMaxWidthMedium: 912,
  contentMaxWidthSmall: 768,
  spacing: 16,
  formWidth: 400,
  spacingHeader: 12
};

type FontSize = {
  labelSmall: string;
  label: string;
  labelBig: string;
  copy: string;
  base: string;
  titleMobile: string;
  title: string;
  pageTitleMobile: string;
  pageTitle: string;
  headerTitle: string;
  authTitle: string;
};

const fontSize = {
  labelSmall: '10px',
  label: '12px',
  labelBig: '14px',
  copy: '15px',
  base: '16px',
  titleMobile: '18px',
  title: '19px',
  pageTitleMobile: '24px',
  headerTitle: '28px',
  pageTitle: '40px',
  authTitle: '36px'
};

type FontWeight = {
  light: number;
  medium: number;
  heavy: number;
};

const fontWeight = {
  light: 300,
  medium: 500,
  heavy: 700
};

type Palette = {
  primary: string;
  primaryLight: string;
  secondary: string;
  background: string;
  headerTitle: string;
  neutral: string;
  border: string;
  error: string;
  error2: string;
  success: string;
  success2: string;
  link: string;
  link2: string;
  subtleText: string;
  linkClicked: string;
  separator: string;
  orange: string;
  green: string;
  red: string;
  configFill: string;
  info: string;
  infoText: string;
};

const palette = {
  primary: '#2973f2',
  primaryLight: '#fff',
  secondary: '#aaa',
  background: '#f7f9fe',
  headerTitle: '#1d2b4f',
  neutral: '#6c757d',
  border: '#ced4da',
  error: '#d04a57',
  error2: '#dc3534',
  success: '#20c997',
  success2: '#28a745',
  subtleText: '#626c84',
  configFill: '#e9ecef',
  link: '#626c84',
  link2: '#0e63f4',
  linkClicked: '#6F42C1',
  separator: '#dee2e6',
  orange: '#eea537',
  green: '#54bd8d',
  red: '#eea537',
  info: '#e8f0fc',
  infoText: '#2f538e'
};

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  fontWeight: FontWeight;
  palette: Palette;
  fontSize: FontSize;
  dimensions: Dimensions;
}

const adpTheme = {
  deviceSize,
  devices,
  fontWeight,
  palette,
  fontSize,
  dimensions
};

export { adpTheme };
