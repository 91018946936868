import { Location } from 'api/locations/locations.types';
import { DeviceEntry } from 'api/device/device.types';

export interface UserLoginPayload {
  access_token: string | null;
  expires: string | null;
}

export interface UserMePayload {
  user: UserDetails | null;
}

export interface UserDetails {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
}

export interface Role {
  id: number;
  name: string;
}

export interface RegisterEntry {
  id: number;
  name: string;
  email: string;
  mrn: string;
  phone: string;
  phone_verified_at: string;
  image: string;
  location_id: number;
  created_by: number;
  created_at: string;
  updated_at: string;
  location?: Location;
  roles?: Role[];
  devices_as_clinician?: DeviceEntry[];
  address1?: string;
  address2?: string;
  notifications_timezone?: string;
}

export interface LoginParams {
  email: string;
  password: string;
}

export interface RegisterPayload {
  email: string;
  password: string;
  company_name: string;
  device_serial: string;
  device_code: string;
  name: string;
}

export enum MfaOptions {
  email = 'email',
  sms = 'sms'
}

export interface MfaRequire {
  require: boolean;
  channel: MfaOptions | null;
  code?: number | string | null;
}

export interface SendMfaCodePayload {
  channel: MfaOptions;
}

export interface VerifyMfaCodePayload {
  channel: MfaOptions;
  code: number;
  remember_mfa_session: boolean;
}

export interface CheckMfaPayload {
  enabled: 0 | 1;
  method: MfaOptions;
  phone: 0 | 1;
  otp: 0 | 1;
}
