/* eslint-disable no-await-in-loop */
import { isEmpty } from 'lodash';
import { postCommunicateMode } from 'bluetooth-handler/bluetoothFunctions';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { FETCHING_STATES } from 'consts/consts';
import { compareConfigs } from 'reducers/helpers/bluetoothHelpers';
import { useConfigStore } from 'reducers/configStore';
import { useUiStore } from 'reducers/uiStore';

const useSynchronizeBluetooth = () => {
  const {
    bluetoothMode,
    slotSelected,
    setItemConfigStore,
    sendWholeConfigDevice,
    getInitialConfigAPI,
    setConfigCopy
  } = useConfigStore((state) => ({
    bluetoothMode: state.bluetoothMode,
    slotSelected: state.slotSelected,
    setItemConfigStore: state.setItemConfigStore,
    importConfig: state.importConfig,
    sendWholeConfigDevice: state.sendWholeConfigDevice,
    getInitialConfigAPI: state.getInitialConfigAPI,
    setConfigCopy: state.setConfigCopy
  }));
  const setItemUiStore = useUiStore((state) => state.setItemUiStore);

  const sendAllConfig = async (
    config: { commonDifference: any; modes: { difference: any; slot: number }[] },
    permanently = true
  ) => {
    console.log(config, 'SENDING');
    await sendWholeConfigDevice({
      configToSend: config.commonDifference,
      sendPermanently: permanently
    });

    for (let index = 0; index < config.modes.length; index += 1) {
      const element = config.modes[index];
      await postCommunicateMode(element.slot, bluetoothMode);
      await delay(100);
      await sendWholeConfigDevice({
        configToSend: element.difference,
        sendPermanently: permanently
      });
    }
  };

  // Sends apiConfig settings based on differences between apiConfig and deviceConfig, by default sends API config to hand

  const synchronizeConfig = async (config, reverse = false) => {
    setItemUiStore('synchronizingState', FETCHING_STATES.loading);
    try {
      await delay(100);
      const configDifferences = reverse
        ? compareConfigs(config.common.configAPI, config.common.config)
        : compareConfigs(config.common.config, config.common.configAPI);

      const modesDifferences: any = [];

      config.modes.forEach((mode) => {
        const difference = reverse
          ? compareConfigs(mode.configAPI, mode.config)
          : compareConfigs(mode.config, mode.configAPI);
        if (!isEmpty(difference)) modesDifferences.push({ ...mode, difference });
      });

      await sendAllConfig({ commonDifference: configDifferences, modes: modesDifferences });

      // Switch to previously selected mode
      await postCommunicateMode(slotSelected, bluetoothMode);

      // Apply new config to store
      if (!reverse) {
        await getInitialConfigAPI();
      }
      setConfigCopy();
      setItemConfigStore('configConflict', false);
      setItemUiStore('synchronizingState', FETCHING_STATES.successful);
    } catch (e) {
      console.log(e);
      setItemUiStore('synchronizingState', FETCHING_STATES.failed);
    }
  };

  return { synchronizeConfig, sendAllConfig };
};

export default useSynchronizeBluetooth;
