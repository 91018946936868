import { AxiosResponse } from 'axios';
import { UserEntry, UserQueryParams, UsersQueryParams } from './users.types';
import { encodeQueryData } from '../utils/encodeQuery';
import api from '../utils/apiClient';
import { PaginatedResponse } from '../../types';

export const GET_USERS = 'api/users';
export const GET_USER = 'api/user';

export const withCredentials = {
  withCredentials: true
};

export const withCredentialsAndMultipartForm = {
  ...withCredentials,
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export const  getUser = (
  id: number | string,
  { params = {} }: { params?: UserQueryParams }
): Promise<any> => api
  .get<UserEntry>(
    `${GET_USER}/${id}?${encodeQueryData({
      ...params,
      extend: params.extend?.toString()
    })}`,
    withCredentialsAndMultipartForm
  )
  .then((res: AxiosResponse) => res.data);

export const getUsers = ({ params = {} }: { params?: UsersQueryParams }): Promise<any> => api
    .get<PaginatedResponse<UserEntry>>(
      `${GET_USERS}?${encodeQueryData({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);