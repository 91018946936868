import { useState } from 'react';
import { PermissionEntry, RoleEnum } from '../api/users/users.types';
import authProvider from '../providers/authProvider';
import { rolesByName, userHasPermissions } from '../utils/permissionUtils';

interface Props {
  action?: string | null;
  resource: string;
  record?: any;
}

const provider = authProvider;

interface PartElement {
  action: string | null;
  resource: string | null;
  record: string | null;
}

const transformPermission = (items: PermissionEntry[]): PartElement[] => {
  const parts: PartElement[] = [];
  Array.from(items).forEach((permission: PermissionEntry) => {
    const permissionParts = permission.name.split('.');
    parts.push({
      action: permissionParts[3] ?? null,
      resource: permissionParts[2] ?? null,
      record: permissionParts[1] ?? null
    });
  });

  return parts;
};

const useCanAccess = ({
  action = null,
  resource
}: Props): { readonly isLoading: boolean; readonly canAccess: boolean } => {
  const [isLoadingPermission, setIsLoadingPermission] = useState<boolean>(true);
  const [isCanAccess, setIsCanAccess] = useState<boolean>(false);

  provider.getPermissions().then((result: any) => {
    const availablePermissions = transformPermission(result.permissions ?? []);
    const { roles } = result;
    setIsLoadingPermission(false);
    if (
      userHasPermissions(
        [RoleEnum.superAdmin, RoleEnum.clinician, RoleEnum.clinicAdmin],
        rolesByName(roles)
      )
    ) {
      setIsCanAccess(true);
      return true;
    }

    const resourcePermissionsPart = availablePermissions.filter(
      (part: PartElement) => part.resource === resource
    );

    if (resourcePermissionsPart === undefined) {
      setIsCanAccess(false);
      return false;
    }

    if (action === null || action === '*') {
      setIsCanAccess(true);
      return true;
    }

    const resourceActionPermission = resourcePermissionsPart.find(
      (part: PartElement) => part.action && part.action === action
    );

    return resourceActionPermission !== undefined ? setIsCanAccess(true) : setIsCanAccess(false);
  });

  return {
    isLoading: isLoadingPermission,
    canAccess: isCanAccess
  };
};

export default useCanAccess;
