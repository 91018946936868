import React, { useEffect } from 'react';
import styled from 'styled-components';
import { HeaderStandard } from 'components/atoms/Typography/Typography';
import AetherLogo from '../assets/aether_new_black.png';

const Header1 = styled(HeaderStandard)`
  ${HeaderStandard};
  color: #1d2b4f;
  font-weight: 500;
`;

const Logo = styled.img`
  height: 60px;
  margin-bottom: 40px;
`;

const WelcomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WelcomePage = () => {
  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_ADMIN_PANEL_URL}`);
  }, []);

  return (
    <WelcomeContainer>
      <Logo src={AetherLogo} alt='Aether Biomedical logo' />
      <Header1>Redirecting...</Header1>
    </WelcomeContainer>
  );
};

export default WelcomePage;
