import {
  consumeBytesToValueLSB,
  convertValueToUInt8Bytes,
  countCRC32ForDataBytes
} from './Utilities';
import { crc32Table } from './CRCTable';
import kDataSizesFromCommands, { Commands } from './Defines';
import { ProcedureTypes } from './Procedures';

const kFirstBeginningByte = 0xa5;
const kSecondBeginningByte = 0x5a;
const kCrc32BytesAmount = 4;

const AddCrc32ToFrame = (frame, crc32_bytes) => {
  let result = false;
  let crc32Frame = [];

  if (crc32_bytes.length === kCrc32BytesAmount) {
    const crc32Array = [...frame, ...crc32_bytes];
    crc32Frame = crc32Array;
    result = true;
  }

  return [result, crc32Frame];
};

const AddBeginningBytesToFrame = (frame, firstBeginningByte, secondBeginningByte) => {
  const appendedFrame = [firstBeginningByte, secondBeginningByte, ...frame];

  return appendedFrame;
};

const AddCommandDataToFrame = (frame, payload) => {
  const payloadFrame = [...frame, ...payload];
  return payloadFrame;
};

export const transformSent = (command, command_data) => {
  const transformFrameBytesBasedOnSchemaSend = (command_data, schema) => {
    const commandFiltered = command_data.map((value, i) => {
      if (schema[i] === 32) {
        value = convertValueToUInt8Bytes(value, 4);
      } else if (schema[i] === 16) {
        value = convertValueToUInt8Bytes(value, 2);
      }
      return value;
    });
    return commandFiltered.flat();
  };

  let commandDataTransformed = [];

  switch (command) {
    case Commands.kFrameTypeFingerCurrentThreshold: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [8, 16]);
      break;
    }
    case Commands.kInitialGripPositions: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 16, 16, 16, 16, 16]
      );
      break;
    }
    case Commands.kSetFingerLimits: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 16, 16, 16, 16, 16]
      );
      break;
    }
    case Commands.kSetEMGSpikesCancellingSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [8, 32]);
      break;
    }
    case Commands.kAutoGraspSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [8, 16]);
      break;
    }
    case Commands.kSetIntervalBetweenCocontractionPulses: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16]);
      break;
    }
    case Commands.kSetHoldOpenGripSwitchingSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [32, 32]);
      break;
    }
    case Commands.kSetJointTargetPosition: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [8, 32]);
      break;
    }
    case Commands.kPartOfFWImage: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [
          16, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,
          8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,
          8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,
          8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8
        ]
      );
      break;
    }
    case Commands.kPulseTimings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16, 16, 16, 16]);
      break;
    }
    case Commands.kCoContractionTimings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16, 16]);
      break;
    }
    case Commands.kSetFingersFollowingDelay: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [32]);
      break;
    }
    case Commands.kSetSingleElectrodeSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [16, 16, 16, 16, 32, 32, 32]
      );
      break;
    }
    case Commands.kSetGripSpeed: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16]);
      break;
    }
    case Commands.kSetLowBatterySettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16, 8]);
      break;
    }
    case Commands.kSingleElectrodeModeFastOpenSlowCloseSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16, 16, 32, 32]);
      break;
    }
    case Commands.kThumbDelayTime: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [8, 16]);
      break;
    }
    case Commands.kFastRisingSlopeSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(command_data, [16, 16, 16]);
      break;
    }
    // Last 6 parts are unused
    case Commands.kLongDoubleStageHoldSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 8, 32, 32, 32, 32, 32, 32, 32, 16]
      );
      break;
    }
    // Last 7 parts are unused
    case Commands.kFreezeSignalDetectorSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 8, 8, 8, 32, 32, 32, 32, 32, 32, 32]
      );
      break;
    }
    case Commands.kBuzzingVolumeSettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 8, 8, 8, 32, 32, 32, 32, 32, 32, 32]
      );
      break;
    }
    case Commands.kLowBatteryEmergencySettings: {
      commandDataTransformed = transformFrameBytesBasedOnSchemaSend(
        command_data,
        [8, 8, 16, 32, 32, 32, 32, 32, 32, 32]
      );
      break;
    }
    default:
      commandDataTransformed = command_data;
      break;
  }
  return commandDataTransformed;
};

export const CreateFrame = (command, command_data = []) => {
  let frame = [];
  const appendedFrame = AddBeginningBytesToFrame(frame, kFirstBeginningByte, kSecondBeginningByte);
  const payloadCommand = command;
  let commandDataToSend;
  commandDataToSend = transformSent(command, command_data);
  const payload = [payloadCommand, ...commandDataToSend];
  const crc32 = countCRC32ForDataBytes(payload);
  const crc32_bytes = convertValueToUInt8Bytes(crc32, 4);
  const crc32Frame = AddCrc32ToFrame(appendedFrame, crc32_bytes)[1];
  const finalFrame = AddCommandDataToFrame(crc32Frame, payload);
  return finalFrame;
};

// Used on frame that was already parsed by receiveDataFromSerialPort
export const transformReceived = (commandData) => {
  const transformFrameBytesBasedOnSchema = (command, commandPayload, schema) => {
    const dataSizeForCommand = kDataSizesFromCommands.get(command);
    const buffer = new ArrayBuffer(dataSizeForCommand);
    const dv = new DataView(buffer);
    for (let i = 0; i < dataSizeForCommand; i++) {
      dv.setUint8(i, commandPayload[i]);
    }
    let outsideIterator = 0;
    let payload = [];
    for (let i = 0; i < schema.length; i++) {
      if (schema[i] === 32) {
        payload[i] = dv.getUint32(outsideIterator);
        outsideIterator += 4;
      } else if (schema[i] === 16) {
        payload[i] = dv.getUint16(outsideIterator);
        outsideIterator += 2;
      } else if (schema[i] === 8) {
        payload[i] = dv.getUint8(outsideIterator);
        outsideIterator += 1;
      } else if (schema[i] === 'int32') {
        payload[i] = dv.getInt32(outsideIterator);
        outsideIterator += 4;
      } else if (schema[i] === 'int16') {
        payload[i] = dv.getInt16(outsideIterator);
        outsideIterator += 2;
      } else if (schema[i] === 'int8') {
        payload[i] = dv.getInt8(outsideIterator);
        outsideIterator += 1;
      }
    }
    const commandDataTransformed = {
      command,
      payload
    };
    return commandDataTransformed;
  };
  let commandDataTransformed = [];

  commandData.forEach((element) => {
    switch (element.command) {
      case Commands.kTelemetryData: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [
            32, 16, 16, 16, 32, 16, 16, 16, 32, 16, 16, 16, 32, 16, 16, 16, 32, 16, 16, 16, 16, 16,
            8, 8, 16
          ]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kInitialGripPositions: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [8, 16, 16, 16, 16, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetFingerLimits: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [8, 16, 16, 16, 16, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kFrameTypeFingerCurrentThreshold: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 16]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetEMGSpikesCancellingSettings: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 32]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kAutoGraspSettings: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 16]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetIntervalBetweenCocontractionPulses: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [16]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetHoldOpenGripSwitchingSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [32, 32]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kFrameTypeProcedureReply: {
        console.log(element);
        let newData = {};
        const procedureType = element.payload[0];
        switch (procedureType) {
          default:
            newData = transformFrameBytesBasedOnSchema(
              element.command,
              element.payload,
              // prettier-ignore
              [8,8,8,8,8,8, 'int16', 'int16', 'int16', 'int16', 'int16', 
               8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8,8,8,8,8,8
              ,8,8,8,8,8]
            );
            break;
        }
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kPulseTimings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [16, 16, 16, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kCoContractionTimings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [16, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetFingersFollowingDelay: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [32]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetSingleElectrodeSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [16, 16, 16, 16, 32, 32, 32]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetGripSpeed: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [
          'int16'
        ]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSetLowBatterySettings: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [16, 8]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kFwPartStatus: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 16]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kSingleElectrodeModeFastOpenSlowCloseSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [16, 16, 32, 32]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kThumbDelayTime: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 16]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kFastRisingSlopeSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [16, 16, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kLongDoubleStageHoldSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [8, 8, 32, 32]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kFreezeSignalDetectorSettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [8, 8, 8, 8]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kBuzzingVolumeSettings: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8]);
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kLowBatteryEmergencySettings: {
        const newData = transformFrameBytesBasedOnSchema(
          element.command,
          element.payload,
          [8, 8, 16]
        );
        commandDataTransformed.push(newData);
        break;
      }
      case Commands.kLowBatteryEmergencyState: {
        const newData = transformFrameBytesBasedOnSchema(element.command, element.payload, [8, 16]);
        commandDataTransformed.push(newData);
        break;
      }
      default:
        commandDataTransformed.push(element);
        break;
    }
  });
  return commandDataTransformed;
};

export class BluetoothReader {
  constructor() {
    this.mIncomingBuffer = [];
    this.mCRC = [];
    this.frameIterator = 2;
  }

  findBeginingBytes(start = 0) {
    let beginIndex = this.mIncomingBuffer.indexOf(kFirstBeginningByte, start);
    if (beginIndex != -1) {
      if (this.mIncomingBuffer[beginIndex + 1] == kSecondBeginningByte) {
        beginIndex += 1;
        return beginIndex + 1;
      } else {
        beginIndex += 1;
        return this.findBeginingBytes(beginIndex);
      }
    } else {
      return beginIndex;
    }
  }

  receiveDataFromSerialPort(data) {
    this.mIncomingBuffer = [...this.mIncomingBuffer, ...data];
    let beginIndex = this.findBeginingBytes();
    let payloads = [];
    while (beginIndex !== -1 && payloads.length === 0) {
      if (beginIndex > 2) {
        this.mIncomingBuffer = this.mIncomingBuffer.slice(beginIndex - 2);
        return;
      }
      this.frameIterator = 2;
      // Remove header from buffer
      const kFrameHeaderLength = 7;
      if (this.mIncomingBuffer.length < kFrameHeaderLength) {
        console.log('Frame too short');
        return;
      }

      const receivedCRC = consumeBytesToValueLSB(this.mIncomingBuffer, this.frameIterator);
      const receivedCRCValue = receivedCRC[0];
      this.frameIterator = receivedCRC[1];

      this.resetCRC();

      const command = this.consumeByte(this.frameIterator);
      const commandValue = this.mIncomingBuffer[this.frameIterator];
      this.frameIterator = command[1];

      const payloadSize = this.getPayloadSize(commandValue);
      if (
        this.mIncomingBuffer.length < kFrameHeaderLength + payloadSize ||
        payloadSize === undefined
      ) {
        if (!payloadSize) {
          console.log('UKNOWN');
          this.mIncomingBuffer = this.mIncomingBuffer.slice(this.frameIterator);
        }
        return;
      }
      let payload = [];
      let tempIterator = this.frameIterator;
      for (let i = tempIterator; i < payloadSize + tempIterator; i++) {
        const consumedPayload = this.consumeByte(this.frameIterator);
        payload.push(this.mIncomingBuffer[i]);
        this.frameIterator = consumedPayload[1];
      }
      const calculatedCRC = this.getCRC();

      this.mIncomingBuffer = this.mIncomingBuffer.slice(this.frameIterator);
      beginIndex = this.findBeginingBytes();

      if (receivedCRCValue !== calculatedCRC) {
        console.error('Wrong crc');
      } else {
        payloads.push({ command: commandValue, payload });
      }
    }
    if (payloads.length === 0) {
      return;
    } else {
      return transformReceived(payloads);
    }
  }

  resetCRC() {
    this.mCRC = 0 ^ -1;
  }

  consumeByte(it) {
    let byte = this.mIncomingBuffer[it];
    it++;
    this.mCRC = (this.mCRC >>> 8) ^ crc32Table[(this.mCRC ^ byte) & 0xff];

    return [byte, it];
  }

  getPayloadSize(command) {
    return kDataSizesFromCommands.get(command);
  }

  getCRC() {
    return (this.mCRC ^ -1) >>> 0;
  }
}
