import React, { useState } from 'react';
import { Button, MenuItem, TextField, DialogContent } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import ModalPortal from 'utils/Modal/ModalPortal';
import { TextS } from 'components/atoms/Typography/Typography';
import { TableBody, TableHeader } from 'views/DeviceConfig/DeviceConfig/DeviceConfigComponent';
import { ConfigDifferencesItem } from 'views/DeviceConfig/SessionHistory/SessionHistory';
import { transformCompared } from 'utils/Config/transformConfig';
import { useConfigStore } from 'reducers/configStore';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const ChangesWrapper = styled.div`
  overflow-y: scroll;
  max-height: 400px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

type ConfirmAbortModeProps = {
  handleClose: any;
  handleAccept: any;
  entry: any;
  isLoading: boolean;
};

const RestoreConfigHistoryModal = ({
  handleClose,
  handleAccept,
  entry,
  isLoading
}: ConfirmAbortModeProps) => {
  const { common, modes } = useConfigStore((state) => ({
    common: state.config.common,
    modes: state.config.modes,
    slotSelected: state.slotSelected
  }));
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);

  const configNow = {
    ...common.configAPI,
    ...modes.find((mode) => mode.slot === selectedModeSlot)?.configAPI
  };

  const compared = transformCompared(configNow, {
    ...entry.common,
    ...entry.modes.find((mode) => mode.slot === selectedModeSlot).config
  });

  return (
    <ModalPortal>
      <ModalBase handleClick={handleClose} header='Save configuration'>
        <DialogContent>
          <ParagraphBold>
            Do you want to restore this configuration, and send it to patient?
          </ParagraphBold>
          {modes && (
            <TextField
              fullWidth
              id='selected-mode'
              label='Difference for mode'
              select
              sx={{ marginBottom: '20px' }}
              SelectProps={{
                value: selectedModeSlot,
                onChange: (e: any) => setSelectedModeSlot(e.target.value)
              }}>
              {modes.map((mode) => (
                <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                  {mode.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {compared?.length > 0 && (
            <ChangesWrapper>
              <TableBody sticky>
                <TableHeader>Configuration</TableHeader>
                <TableHeader>Being sent</TableHeader>
                <TableHeader>Current</TableHeader>
              </TableBody>
              {compared.map((entry) => (
                <ConfigDifferencesItem
                  name={entry.key}
                  configName={entry.key}
                  after={entry.value}
                  now={configNow}
                />
              ))}
            </ChangesWrapper>
          )}
          <ButtonsWrapper>
            <Button onClick={handleClose} variant='outlined' type='button'>
              Cancel
            </Button>
            <LoadingButton type='button' onClick={handleAccept} loading={isLoading}>
              <span>Yes, send</span>
            </LoadingButton>
          </ButtonsWrapper>
        </DialogContent>
      </ModalBase>
    </ModalPortal>
  );
};

export default RestoreConfigHistoryModal;
